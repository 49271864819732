export default function($el, messageObj, opts, callbacks, curFile) {
  $el.addClass('dz-singlefile dz-unit');

  var $message = $(JST['templates/dropzone-generic']({
    title: 'Drop '+ messageObj.name + ' here',
    optional: messageObj.optional,
    maxFilesize: messageObj.maxFilesize,
    acceptedFiles: messageObj.acceptedFiles,
    dropFilesTitle: Kadenze.I18n.t('drop_file_editor.drop_file_title'),
    dragAndDrop: Kadenze.I18n.t('drop_file_editor.drag_and_drop'),
    browseFiles: Kadenze.I18n.t('drop_file_editor.browse_files'),
    dimentionLabel: Kadenze.I18n.t('drop_file_editor.dimensions_label'),
    maxFilesizeLabel: Kadenze.I18n.t('drop_file_editor.max_file_size'),
    fileTypesLabel: Kadenze.I18n.t('drop_file_editor.file_types'),
    optionalLable: Kadenze.I18n.t('drop_file_editor.optional'),
  }));


  $el.append($message);

  var $btn = $('.js-dz-btn', $message);

  // set up dropzone options
  var dzOptions = _.extend({}, Kadenze.DropzoneDefaults, {
    url: Kadenze.Routes.attachments_path(),
    addRemoveLinks: true,
    maxFiles: 1,
    autoProcessQueue: true,
    acceptedFiles: messageObj.fileTypes,
    headers: {
      'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content')
    },
    paramName: 'attachment[file]',
    //clickable: "#" + $button.attr('id'),
    useKilobytes: true, // custom option added to dropzone. Contacting dev to see if they'll add this feature

    init: function() {
      console.log('dropzone init');

      if (callbacks && typeof callbacks.init === 'function') {
        callbacks.init.apply(this, arguments);
      }

      this.on('addedfile', function(file) {
        console.log(this, 'file added');
        $el.addClass('this-onserver');
        var iconType = Kadenze.UIUtil.getFileTypeIcon(this.options.acceptedFiles);
        var $spanIcon = $('<span class="icon"/>');
        $spanIcon.addClass(iconType);
        $el.find('.this-details').append($spanIcon);

        if (callbacks && typeof callbacks.addedfile === 'function') {
          callbacks.addedfile.apply(this, arguments);
        }
      });

      this.on('sending', function(file, xhr, formData) {
        // //Add CSRF token to request
        // $.rails.CSRFProtection(xhr);
        if (callbacks && typeof callbacks.sending === 'function') {
          callbacks.sending.apply(this, arguments);
        }
      });

      this.on('success', function(file, response) {
        console.log(this, 'file success');
        if (callbacks && typeof callbacks.success === 'function') {
          callbacks.success.apply(this, arguments);
        }

      });

      this.on('removedfile', function(file) {
        console.log(this, 'file removed');
        $el.removeClass('this-onserver');

        //TODO: Send ajax to tell server to remove file
        if (callbacks && typeof callbacks.removedfile === 'function') {
          callbacks.removedfile.apply(this, arguments);
        }
      });

      /*this.on("maxfilesexceeded", function(file){
        this.removeAllFiles();
        this.addFile(file);
      });*/
    }
  });

  // create the dropzone object
  this.dropzone = new Dropzone('div#' + $el.attr('id'), _.extend({}, dzOptions, opts));

  // create fake object is file lives on the server
  if (curFile) {
    var dzfieldObj = {
      name: curFile.name,
      title: curFile.title,
      size: curFile.size,
      path: curFile.url || curFile.path,
      id: curFile.id
    };

    this.dropzone.emit('addedfile', dzfieldObj);
  }
}
