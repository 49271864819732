/*
 * Kadenze.Tooltip
 *
 * Initializes a help tooltip, can be done in pure js or triggered via
 * the 'js-tooltip' css class and some data attributes.
 *
 * JS Usage:
 *
 *      new Kadenze.Tooltip({
 *        target: "#my-element",
 *        title: "Hey There!",
 *        content: "Let us try and help you!!",
 *        position: "topCenter"
 *      });
 *
 * Markup Usage:
 *      JS Initialization:
 *        %a#.js-tooltip{ :data => { :tooltip_title => "Hey!", :tooltip_content => "I WILL HELP YOU!!", :tooltip_position => "bottomCenter" }}
 *      KO Initialization:
 *        %a{ data: { bind: "{ tooltip: { title: 'Hey!', content: 'I WILL HELP YOU!!', position: 'topCenter' } }" }
 *
 * @params: Object
 *  arrow: Boolean, should tooltop have an arrow? Arrows only should appear on on-boarding interactive tooltips
 *  disabled: Boolean, is this tooltip disabled?
 *  showDelay: Integer, what should be the amount of delay before the tooltip is shown? (in seconds). NOTE: This is NOT compatible with interactive tooltips.
 *  template: String, path to the JST template, template must implement certian KO observables, see 'js/templates/tooltip' for an example
 *  templateParams: Object, any params that the above specified template might need
 *  offsetVertical: Integer, how far the tooltip is offset vertically from the target
 *  eventIn: String, event to trigger the tooltip to show, default is mouseover
 *  eventOut: String, event to trigger the tooltip to hide, default is mouseout
 *  events:
 *    onShow: Called when the tooltip is shown
 *    onHide: Called when the tooltip is hidden
 *  position: String, can be any of the following, note that tooltips will re position if the chosen position causes them to not be in view
 *     "above"
 *     "left"
 *     "right"
 *     "below"
 *  interactive:
 *    context: String, unique context in which this tooltip should be displayed. A page could have multiple contexts. Avoid changing once deployed to prod.
 *    order: Integer, in what order should this tooltip be shown? (starts with 1)
 *    end: Boolean, is this the last tooltip for this context?
 *    scroll: Boolean, should the view scroll to the tooltip (useful when the next tooltip could be out of view for the user)
 *    overlay: Boolean, should be used if the tooltip needs to point out a relevant section of the page. NOTE: additional styles are needed, set position: relative; on parent & position: inherit; & z-index on windowed object
 *    events:
 *      onShow: Called when the interactive tooltip is shown
 *      onHide: Called when the interactive tooltip is hidden
 * @returns:
 *  Kadenze.Tooltip instance, the instance can also be referenced from the
 *  target elements' data('tooltip') attribute
*/


let Tooltip = function(params) {
  var self = this;

  self.defaults = {
    template: 'templates/tooltip',
    templateParams: {},
    offsetVertical: 5,
    eventIn: 'mouseover',
    eventOut: 'mouseout',
    disableOnMobile: true,
    disabled: false,
    showDelay: .2,
    events: {
      onShow: null,
      onHide: null
    },
    interactive: {
      context: null,
      order: null,
      end: false,
      scroll: false,
      overlay: false,
      events: {
        onShow: null,
        onHide: null,
        onSkip: null
      }
    }
  };

  //TODO: Defaults are not being applied to config, it needs to be _.extend({}, params, self.defaults) to work as intended
  self.config = _.extend({}, self.defaults, params);
  self.title = ko.observable();
  self.label = ko.observable();
  self.content = ko.observable();
  self.position = ko.observable();
  self.arrow = ko.observable();
  self.activeState = ko.observable(false); //Is this an interactive tooltip and should it be active/visible?
  self.interactiveEnd = ko.observable(); //Is this the last interactive tooltip?
  self.UUID = ko.computed(function() {
    return _.uniqueId('tooltip_');
  });
  self.template = $(JST[self.config.template](self.config.templateParams))[0];
  self.link = ko.mapping.fromJS(self.config.link);

  self.events = {
    // Adding keydown, focus and blur events for ADA compliance,
    // tooltips should become visible via keyboard interaction
    // NOTE: Interactive tooltips should NOT respond to these events,
    // their visibility is governed by User State
    eventIn: !_.isEmpty(self.config.interactive.context) ?
      null : (
        Modernizr.touch ? 'touchstart' : (
          self.config.eventIn ? (self.config.eventIn + ' focus') : null
        )
      ),

    eventOut: !_.isEmpty(self.config.interactive.context) ?
      null : (
        Modernizr.touch ? 'touchend' : (
          self.config.eventOut ? (self.config.eventOut + ' blur') : null
        )
      ),

    bodyOut: 'click.tooltip'
  };

  self.init = function() {
    self.config.target = $(self.unwrapJquery(self.config.target));

    // Remove orphaned tooltips when the target is removed from the DOM
    self.config.target.on('remove', function () {
      var tooltipId = self.config.target.attr('aria-describedby');
      $('#' + tooltipId).remove();
    });

    self.config.target.addClass('kadenze-tooltip-target');

    // Tells screen reader to look at this tooltip for alternate / help text
    self.config.target.attr('aria-describedby', self.UUID());

    if(!self.config.title)
      self.extractAttr('tooltip-title', 'title', self.config.target);

    if(!self.config.label)
      self.extractAttr('tooltip-label', 'label', self.config.target);

    if(!self.config.content)
      self.extractAttr('tooltip-content', 'content', self.config.target);

    if(!self.config.position)
      self.extractAttr('tooltip-position', 'position', self.config.target);

    if(!self.config.arrow)
      self.extractAttr('tooltip-arrow', 'arrow', self.config.target);

    // this remaps the old positions and position names.
    // this eventually can be deprecated.
    self.config.position = self.positionConverter(self.config.position);
    $(self.config.target).data('tooltip-position', self.config.position);

    _.each(_.keys(self.config.interactive), function(key) {
      self.config.interactive[key] =
        self.config.target.data('tooltip-interactive-' + key) ||
          self.config.interactive[key];
    });

    self.setObservables();
    self.delegateEvents(self.config.target);

    var refreshState = function(eventType) {
      if(self.isActive()) {
        self.show();
        self.watchResizing();
      }
    };

    Kadenze.onModuleLoaded('UserState', () => {
      Kadenze.UserState.get(() => refreshState('ready'));
      Kadenze.UserState.onReload(refreshState.bind(self, 'reload'));
    });
  };

  self.delegateEvents = function($target) {
    if(!$target.data('tooltip')) {
      if(self.events.eventIn == 'click') {
        self.attachClickEvents($target);
      } else {
        self.attachMouseEvents($target);
      }

      $target.data('tooltip', self);
    }
  };

  self.attachClickEvents = function($target) {
    $target.off(self.events.eventIn).on(self.events.eventIn, function(e) {
      e.preventDefault();
      self.show();
    });

    $('body').off(self.events.bodyOut).on(self.events.bodyOut, function(e) {
      if(!self.actionInsideTooltip(e) && !self.actionInsideTarget(e)) {
        self.hide();
      }
    });
  };

  self.attachMouseEvents = function($target) {
    $target.off(self.events.eventIn).on(self.events.eventIn, function(e) {
      e.preventDefault();
      self.show();
    });

    $target.off(self.events.eventOut).on(self.events.eventOut, function(e) {
      if (!self.isActive()) {
        e.preventDefault();
        (self.config.template == 'templates/tooltip') ? self.hide() : setTimeout(self.hide, 300);
      }
    });
  };

  self.watchResizing = function() {
    var throttled = _.throttle(function(e) {
      self.positionTooltip();
    }, 1000);

    $(window).on('resize scroll', throttled);
  };

  self.setObservables = function() {
    self.title(self.config.title);
    self.label(self.config.label);
    self.content(self.config.content);
    self.interactiveEnd(self.config.interactive.end);

    ko.cleanNode(self.template);
    ko.applyBindings(self, self.template);
  };

  self.render = function() {
    if($(self.template).closest(document.documentElement).length <= 0) {
      var $tooltip = $(self.template).prop('class', 'kadenze-tooltip');

      if(self.config.interactive.overlay)
        $('body').append('<div class="kadenze-tooltip-overlay"></div>');

      $('body').append($tooltip);
    }
  };

  self.show = function() {
    if (self.config.disabled)
      return;

    if(self.config.target.hasClass('is-disabled'))
      return;

    if (typeof Kadenze.Util.objFromString(self.config, 'interactive.events.onShow', null) == 'function')
      self.config.interactive.events.onShow(self);

    // make sure tooltip is attached to the dom
    self.render();
    self.positionTooltip();

    if (typeof Kadenze.Util.objFromString(self.config, 'events.onShow', null) == 'function')
      self.config.events.onShow(self);

    if (self.isActive() && self.config.interactive.scroll)
      Kadenze.UIUtil.scrollTo($(self.config.target), 0.7);

    if(self.config.interactive.overlay) {
      self.config.target.addClass('js-tooltip-active');
      self.animate($('.kadenze-tooltip-overlay'), self.config.showDelay, { autoAlpha:1 });

      $(document).off('pjax:start').on('pjax:start', function () {
        $('.kadenze-tooltip-overlay').remove();
      });
    }

    if(!$(self.template).is(':visible')) {
      self.animate(
        self.template,
        self.config.showDelay,
        {
          delay: self.config.showDelay,
          autoAlpha: 1,
          display: 'block',
          transformOrign: '50% 50%'
        }
      );
    }
  };

  self.hide = function() {
    // Wait to hide until the mouse is over the template. Unless it's using a
    // standard template without links in which case there is no reason to
    // persist on hover.

    if (self.config.template == 'templates/tooltip' || (!$(self.config.target).is(':hover') && !$(self.template).is(':hover'))) {
      self.animate(self.template, .2, {autoAlpha:0, transformOrign:'50% 50%', display: 'none', onComplete: function() {
        $(self.template).removeAttr('style');
        if(self.config.interactive.overlay) {
          self.config.target.removeClass('js-tooltip-active');
        }
        if (typeof Kadenze.Util.objFromString(self.config, 'events.onHide', null) == 'function') {
          self.config.events.onHide(self);
        }
      }});
    } else {
      setTimeout(self.hide, 300);
    }
  };

  self.destroy = function () {
    self.config.disabled = true;
    $(self.template).removeAttr('style').remove();
  };

  // Interactive Tooltips

  self.interactiveHide = function () {
    $(self.template).hide();

    if(self.config.interactive.overlay) {
      self.config.target.removeClass('js-tooltip-active');
      self.animate($('.kadenze-tooltip-overlay'), .2, {autoAlpha:0});
    }

    if (typeof Kadenze.Util.objFromString(self.config, 'interactive.events.onHide', null) == 'function')
      self.config.interactive.events.onHide(self);
  };

  self.isActive = function() {
    self.activeState(
      !_.isEmpty(self.config.interactive.context) &&
        _.isNumber(self.config.interactive.order) &&
          (self.getCurrentPos() == self.config.interactive.order)
    );
    return self.activeState();
  };

  self.getCurrentPos = async () => {
    let pos

    await Kadenze.UserState.get(userState => {
      pos = Kadenze.Util.objFromString(
        userState,
        'tooltips.' + self.config.interactive.context,
        0
      );
    })

    return pos + 1;
  };

  self.saveCurrentPos = () => {
    Kadenze.UserState.set({
      tooltips: {
        [this.config.interactive.context]: this.config.interactive.order
      }
    })
  };

  self.savePrevPos = () => {
    Kadenze.UserState.set({
      tooltips: {
        [this.config.interactive.context]: this.config.interactive.order - 2
      }
    })
  };

  self.saveLastPos = function() {
    Kadenze.UserState.set({
      tooltips: {
        [this.config.interactive.context]: 9999
      }
    })
  };

  self.interactivePrev = function () {
    //Hide this tooltip and mark it as seen in the user state
    self.interactiveHide();
    self.savePrevPos();
  };

  self.interactiveNext = function() {
    //Hide this tooltip and mark it as seen in the user state
    self.interactiveHide();
    self.saveCurrentPos();
  };

  self.interactiveSkip = function() {
    //Hide this tooltip and skip showing any following interactive tooltips
    self.interactiveHide();
    self.saveLastPos();

    if (typeof Kadenze.Util.objFromString(self.config, 'interactive.events.onSkip', null) == 'function') {
      self.config.interactive.events.onSkip(self);
    }
  };

  // Helpers

  self.positionTooltip = function() {
    var numRepositionTrys = 5;
    var wasVisible = $(self.template).is(':visible'),
      $tooltip = $(self.template),
      $target = self.config.target,
      matrix = self.getPositionMatrix($target,$tooltip);

    if (matrix.top === 0 && matrix.left === 0 && matrix.height === 0) {
      $tooltip.css('visibility', 'hidden');
      setTimeout(self.positionTooltip, 5);
      return;
    }


    self.config.location = _.clone(self.positionMap[self.config.position]);
    // clear any previously added styles
    $tooltip.removeAttr('style');
    // get position coordinates
    var tooltipPos = _.extend({},self.formulae[self.config.location.y](matrix), self.formulae[self.config.location.x](matrix));
    TweenLite.set($tooltip, {display: 'block', autoAlpha: 1, transformOrign:'50% 50%'});
    $tooltip.css(self.originMap());
    tooltipPos.width = $tooltip[0].getBoundingClientRect().width;
    // set position
    $tooltip
      .prop('class', 'kadenze-tooltip')
      .css(tooltipPos);
    // set arrow if required
    if(self.config.arrow) {
      self.setArrow(self.config.location);
    }
    // check if tooltip is not completely visible, reposition if not
    if( !Kadenze.UIUtil.isInFullView(self.template) ) {
      // limits the number of reposition attempts
      while(--numRepositionTrys >= 0) {
        if( self.rePosition() ) {
          break;
        }
      }
    }
    if(!wasVisible) {
      TweenLite.set($tooltip,{ display:'none', autoAlpha:0, transformOrign:'50% 50%'} );
    }
  };

  self.setArrow = function(location) {
    var $arrow = $(self.template).find('.js-tt-arrow');
    $arrow.attr('class','kadenze-tooltip__arrow js-tt-arrow');
    $arrow.addClass(self.arrowMap[location.x]);
    $arrow.addClass(self.arrowMap[location.y]);
  };


  self.animate = function(el, time, animeProps) {
    var defaultAnimeProps = { ease: 'Power1.easeOut' };
    var props = _.extend({}, defaultAnimeProps, animeProps);
    if(self.tween && !$(el).hasClass('kadenze-tooltip-overlay'))
      self.tween.kill();
    self.tween = TweenLite.to(el, time, props);
  };

  self.getPositionMatrix = function($target,$tooltip){
    var matrix = {
      top: $target.offset().top,
      left: $target.offset().left,
      width: $target.outerWidth(),
      height: $target.outerHeight(),
      templateWidth: $tooltip.outerWidth(),
      templateHeight: $tooltip.outerHeight()
    };
    return matrix;
  };

  self.rePosition = function() {
    // determine which sides are visible
    var visibleRect = Kadenze.UIUtil.getVisibleSides(self.template);
    var yDetection = _.pick(visibleRect, ['top','bottom']);
    var xDetection = _.pick(visibleRect, ['left','right']);
    // based on which sides aren't visible
    if(yDetection.top && !yDetection.bottom && !self.isActive()) {
      self.config.location.y = self.collisionMap['bottom'].y[self.config.location.y];
    }
    if(!yDetection.top && yDetection.bottom) {
      self.config.location.y = self.collisionMap['top'].y[self.config.location.y];
    }
    if(!xDetection.left && xDetection.right) {
      self.config.location.x = self.collisionMap['left'].x[self.config.location.x];
    }
    if(xDetection.left && !xDetection.right) {
      self.config.location.x = self.collisionMap['right'].x[self.config.location.x];
    }
    var $tooltip = $(self.template),
      $target = self.config.target,
      matrix = self.getPositionMatrix($target,$tooltip);

    var yPos = _.isFunction(self.formulae[self.config.location.y]) ? self.formulae[self.config.location.y](matrix) : null;
    var xPos = _.isFunction(self.formulae[self.config.location.x]) ? self.formulae[self.config.location.x](matrix) : null;
    var tooltipPos = _.extend({}, yPos, xPos, self.originMap());

    $tooltip.css(tooltipPos);
    // set arrow if required
    if(self.config.arrow) {
      self.setArrow(self.config.location);
    }
    return Kadenze.UIUtil.isInFullView(self.template);
  };

  self.extractAttr = function(attr, property, $target) {
    self.config[property] = $target.data(attr) || $target.prop(property);
  };

  self.unwrapJquery = function(object) {
    return object instanceof jQuery ? object[0] : object;
  };

  self.actionInsideTooltip = function(event) {
    return $(event.target).parents('.kadenze-tooltip').length ? true : false;
  };

  self.actionInsideTarget = function(event) {
    return $(event.target).parents('.kadenze-tooltip-target').length ? true : false;
  };

  self.positionConverter = function($str) {
    switch($str) {
    case 'leftTop':
    case 'leftCenter':
    case 'leftRight':
    case 'left':
      return 'left';
    case 'rightTop':
    case 'rightCenter':
    case 'rightBottom':
    case 'right':
      return 'right';
    case 'bottomLeft':
    case 'bottomCenter':
    case 'bottomRight':
    case 'bottom':
    case 'below':
      return 'below';
    default :
      return 'above';
    }
  };

  // Position mapping
  self.positionMap = {
    'above':{
      x:'center',
      y:'above'
    },
    'right':{
      x:'right',
      y:'middle'
    },
    'below':{
      x:'center',
      y:'below'
    },
    'left':{
      x:'left',
      y:'middle'
    }
  };

  self.arrowMap = {
    'above': 'is-bottom',
    'right': 'is-left',
    'below': 'is-top',
    'left': 'is-right',
    'start': 'is-start',
    'center': 'is-center',
    'end': 'is-end',
    'top': 'is-top',
    'middle': 'is-middle',
    'bottom': 'is-bottom'

  };

  // Collision position mapping
  self.collisionMap = {
    top: {
      y: {
        'above':'below',
        'middle':'top',
        'bottom':'middle'
      }
    },
    right: {
      x: {
        'right':'left',
        'center':'end',
        'start':'center',
        'end':'left'
      }
    },
    bottom: {
      y: {
        'below':'above',
        'middle':'bottom',
        'top':'middle'
      }
    },
    left: {
      x: {
        'left':'right',
        'center':'start',
        'end':'center'
      }
    }
  };
  self.originMap = function(){
    var yOrigin, xOrigin;
    switch(self.config.location.x) {
    case 'left':
    case 'start':
      xOrigin='right';
      break;
    case 'center':
      xOrigin='center';
      break;
    case 'end':
    case 'right':
      xOrigin='left';
      break;
    }
    switch(self.config.location.y) {
    case 'above':
    case 'top':
      yOrigin='below';
      break;
    case 'middle':
      yOrigin='center';
      break;
    case 'bottom':
    case 'below':
      yOrigin='top';
      break;
    }
    var tOrigin = {'transform-origin': yOrigin+' '+xOrigin+' 0px'};
    return tOrigin;
  };

  self.formulae = {
    above: function(props){
      return { top: props.top - props.templateHeight - self.config.offsetVertical };
    },
    top: function(props){
      return { top: props.top };
    },
    middle: function(props){
      return { top: props.top + (props.height / 2) - (props.templateHeight / 2) };
    },
    bottom: function(props){
      return { top: (props.top + props.height) - props.templateHeight };
    },
    below: function(props){
      return { top: (props.top + props.height) + self.config.offsetVertical };
    },
    left: function(props){
      return { left: props.left - props.templateWidth - self.config.offsetVertical };
    },
    start: function(props){
      return { left: props.left };
    },
    center: function(props){
      return { left: props.left - (props.templateWidth / 2) + (props.width / 2) };
    },
    end: function(props){
      return { left: (props.left + props.width) - props.templateWidth };
    },
    right: function(props){
      return { left: (props.left + props.width) + self.config.offsetVertical };
    }
  };

  self.init();

  return self;
};

Tooltip.prototype = {
  resetCurrentPos: (context, order) => {
    if (_.isEmpty(context))
      return;

    Kadenze.UserState.set({
      tooltips: {
        [context]: order || 0,
      }
    })
  }
};

export default Tooltip;
