export default {
  factoryOptions: {
    autoShow: true,
    prefixTemplateName: true,
  },

  templateRootPath: 'templates/modals',

  for(templateName, userOptions = {}) {
    this.templateName = templateName
    this.userOptions = userOptions
    this.setDefaultOptions()
    this.createModal()
    return this.$modal
  },

  appendModalToContainer() {
    this.$modal.appendTo(this.getModalContainer())
  },

  getModalContainer() {
    return $('#main-pjax-container')
  },

  handleFactoryOptions() {
    if (this.options.autoShow)
      this.$modal.modal('show')
  },

  setDefaultOptions() {
    this.options = this.transformTemplateOptionsKeys(
      Object.assign({}, this.factoryOptions, this.userOptions)
    )
  },

  createModal() {
    this.$modal = $(this.getTemplate())
    this.appendModalToContainer()
    this.handleFactoryOptions()
  },

  getTemplate() {
    return JST[this.fullTemplatePath()](this.options)
  },

  fullTemplatePath() {
    return this.templateName.match(/\//) ?
      this.templateName :
      this.defaultTemplatePath()
  },

  defaultTemplatePath() {
    if (this.options.prefixTemplateName) {
      return `${this.templateRootPath}/modal-${this.templateName}`;
    } else {
      return `${this.templateRootPath}/${this.templateName}`;
    }
  },

  transformTemplateOptionsKeys(options) {
    const transformedOptions = {}

    Object.entries(options).forEach(([k, v]) => {
      if (this.isTemplateOption(k)) {
        transformedOptions[this.transformKeyToSnakeCase(k)] = v
      } else {
        transformedOptions[k] = v
      }
    })

    return transformedOptions
  },

  isTemplateOption(key) {
    //
    // User can mix in template locals with Modal factory options
    //
    return !Object.keys(this.factoryOptions).includes(key)
  },

  transformKeyToSnakeCase(key) {
    //
    // Legacy JST templates expect snake_case locals
    // developers calling this Modal factory should prefer camelCase
    //
    return key.replace(/[A-Z]/g, letter => `_${letter.toLowerCase()}`)
  }
}
