import Modal from './../components/modal';

// Shared utils to be used between dropzones, hopefully to DRY things up
export default {
  // Sets up event handlers to show a modal with the uploaded image preview
  // the class .js-dz-file-preview is required on all elements needed
  // to trigger the modal
  initImagePreview (dz, file, path, title) {
    const $el = $(dz.element)

    const modalTitle = title ?
      title + ` ${I18n.t('file_preview.preview')}` :
      I18n.t('dict.image_preview')

    const $modal = Modal.for('img-preview', {
      title: modalTitle,
      image: path,
      fileName: file.name,
      btnText: I18n.t('dict.close'),
      autoShow: false,
    });

    $el.off('click.preview').on('click.preview', '.js-dz-file-preview', () => {
      $modal.modal('show');
    });

    $el.find('.js-dz-file-preview').show();
  },

  // Sets up event handlers to play / pause uploaded audio files
  // The class 'js-dz-audio-preview' is required on all elements that are
  // to trigger the audio playback
  initAudioPreview: function(dz, file, response, title) {
    var playing = false,
      $el = $(dz.element),
      $playBtn = $el.find('.js-dz-audio-preview'),
      soundManager = Kadenze.AudioPlayer.createSoundManager();

    if(soundManager.canPlayURL(response.url)) {
      $playBtn.show();

      var sound = soundManager.createSound({
        id: Kadenze.Util.UUID(),
        url: response.url,
        autoLoad: true,
        autoPlay: false
      });

      $playBtn.on('click', function() {
        if(!playing) {
          soundManager.play(sound.id);
          $playBtn.find('i').toggleClass('kdnze-play kdnze-pause');
          playing = true;
        } else {
          soundManager.stopAll();
          $playBtn.find('i').toggleClass('kdnze-pause kdnze-play');
          playing = false;
        }
      });
    }

  },

  initCropper: function(dz, cropts) {
    var self = this;
    var $el = $(dz.element);
    // ignore files which were already cropped and re-rendered
    // to prevent infinite loop

    $el.off('click.crop').on('click.crop', '.js-dz-file-crop', function() {
      self.showCropper(dz, cropts);
    });
  },

  showCropper: function(dz, cropts) {
    var file =  dz.files[0];

    if(!file) return;

    // cache filename to re-assign it to cropped file
    var cachedFilename = file.name;

    Kadenze.UIUtil.showCropper(file, function (cropper) {
      // get cropped image data
      var cropped = cropper.getCroppedCanvas().toDataURL();
      // transform it to Blob object
      var newFile = Kadenze.Util.dataURItoBlob(cropped);
      // set 'cropped to true' (so that we don't get to that listener again)
      newFile.cropped = true;
      // assign original filename
      newFile.name = cachedFilename;

      // Update filesize to allow for larger sized data blobs than the original image
      var oldsize = dz.options.maxFilesize;
      dz.options.maxFilesize = 100;

      // add cropped file to dropzone
      dz.removeFile(file);
      dz.addFile(newFile);
      // upload cropped file with dropzone
      dz.processQueue();
      dz.options.maxFilesize = oldsize;
    }, cropts);
  }
};
