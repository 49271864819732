export default {
  DEFAULT_VALIDATION_OPTIONS: {
    insertMessages: true,
    errorsAsTitle: true,
    decorateInputElement: true,
    parseInputAttributes: true,
    messagesOnModified: true,
    errorElementClass: 'error',
    errorMessageClass: 'error',
    decorateElementOnModified: true,
    decorateElement: true
  },

  initObservable: function(obj, name) {
    if (!ko.isWriteableObservable(obj[name])) {
      obj[name] = ko.observable();
    }
  }
};

/******************************************
* CUSTOM VALIDATIONS
******************************************/

// based on
ko.validation.rules['url'] = {
  validator: function(val) {
    if (!val || val.length === 0) {
      return true;
    }
    val = val.replace(/^\s+|\s+$/, ''); //Strip whitespace
    //Regex by Diego Perini from: http://mathiasbynens.be/demo/url-regex
    return val.match(/^(?:(?:https?|ftp):\/\/)(?:\S+(?::\S*)?@)?(?:(?!10(?:\.\d{1,3}){3})(?!127(?:\.‌​\d{1,3}){3})(?!169\.254(?:\.\d{1,3}){2})(?!192\.168(?:\.\d{1,3}){2})(?!172\.(?:1[‌​6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1‌​,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00‌​a1-\uffff0-9]+-?)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]+-?)*[a-z\u‌​00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/[^\s]*)?$/i);
  },
  message: I18n.t('validation.url.invalid')
};

ko.validation.rules['embedded_video_url'] = {
  validator: function (val){
    if (!val || val.length === 0)
      return true;

    return Kadenze.IframeMediaPlayer.isValidUrl(val);
  }
};

ko.validation.rules['nullableInt'] = {
  validator: function (val, validate) {
    return val === null || val === '' || (validate && !isNaN(val) && parseInt(Number(val)) == val && !isNaN(parseInt(val, 10)));
  },
  message: 'Must be empty or an integer value'
};

ko.validation.rules['dateBefore'] = {
  validator: function (val, date) {
    return val === null || val === '' || (date && Kadenze.Util.getMomentDateFromObservable(ko.observable(val)).isBefore(date));
  },
  message: 'This date must be before {0}'
};

ko.validation.rules['dateAfter'] = {
  validator: function (val, date) {
    return val === null || val === '' || (date && Kadenze.Util.getMomentDateFromObservable(ko.observable(val)).isAfter(date));
  },
  message: 'This date must be after {0}'
};

/* Validates that all values in an array are unique.
 To initialize the simple validator provide an array to compare against.
 By default this will simply compare do an exactly equal (===) operation against each element in the supplied array.
 For a little more control, initialize the validator with an object instead. The object should contain two properties: array and predicate.
 The predicate option enables you to provide a function to define equality. The array option can be observable.
 Note: This is similar to the 'arrayItemsPropertyValueUnique' rule but I find it to be more flexible/functional.

 SIMPLE EXAMPLE::
 model.thisProp.extend({ isUnique: model.thoseProps });

 COMPLEX EXAMPLE::
 model.selectedOption.extend({ isUnique: {
 params: {
    array: model.options,
    predicate: function (opt, selectedVal) {
      return ko.utils.unwrapObservable(opt.id) === selectedVal;
    }
  }
 }});
 */
ko.validation.rules['isUnique'] = {
  validator: function (newVal, options) {
    if (options.predicate && typeof options.predicate !== 'function')
      throw new Error('Invalid option for isUnique validator. The \'predicate\' option must be a function.');

    var array = options.array || options;
    var count = 0;
    ko.utils.arrayMap(ko.utils.unwrapObservable(array), function(existingVal) {
      if (equalityDelegate()(existingVal, newVal)) count++;
    });
    return count < 2;

    function equalityDelegate() {
      return options.predicate ? options.predicate : function(v1, v2) { return v1 === v2; };
    }
  },
  message: 'This value is a duplicate'
};

ko.validation.registerExtenders();

/******************************************
 * CUSTOM TEMPLATE SOURCE
 ******************************************/

//Define a template source that simply treats the template name as its content
//Reference: http://www.knockmeout.net/2011/10/ko-13-preview-part-3-template-sources.html
ko.templateSources.stringTemplate = function(template, templates) {
  this.templateName = template;
  this.templates = templates;
};

ko.utils.extend(ko.templateSources.stringTemplate.prototype, {
  data: function(key, value) {
    this.templates._data = this.templates._data || {};
    this.templates._data[this.templateName] = this.templates._data[this.templateName] || {};

    if (arguments.length === 1) {
      return this.templates._data[this.templateName][key];
    }

    this.templates._data[this.templateName][key] = value;
  },
  text: function(value) {
    if (arguments.length === 0) {
      return this.templates[this.templateName];
    }
    this.templates[this.templateName] = value;
  }
});

/******************************************
* CUSTOM BINDING HANDLERS
******************************************/

ko.bindingHandlers.debug = {
  init(element, valueAccessor) {
    console.log( 'Knockoutbinding:' );
    console.log( element );
    console.log( ko.toJS(valueAccessor()) );
  }
};

ko.bindingHandlers.fadeText = {
  update: function(element, valueAccessor) {
    var value = ko.utils.unwrapObservable(valueAccessor());
    $(element).fadeOut(250, function() {
      // set the text of the element,
      // value needs to be defined outside of the fadeOut callback to work
      ko.utils.setTextContent(element, value);
      $(element).fadeIn(250, function() {
        $(element).css('display', '');
      });
    });
  }
};

ko.bindingHandlers.dateDisplay = {
  update: function(element, valueAccessor, allBindingsAccessor) {
    var observable = valueAccessor();
    var dateFormat = allBindingsAccessor().dateFormat || 'js_default_time';
    var dateSuffix = allBindingsAccessor().dateSuffix || '';
    var showRelative = ko.utils.unwrapObservable(allBindingsAccessor().showRelative) || false;
    var val = allBindingsAccessor().defaultValue || '—';
    var updateTimeout = null;
    if (!_.isEmpty(observable())) {
      var res = Kadenze.Util.getMomentDateFromObservable(observable);
      if (res && res.isValid()) {
        if (showRelative) {
          val = res.fromNow();
          //Update the relative date every minute to keep it from becoming stale
          updateTimeout = setTimeout(function () {
            observable.valueHasMutated();
          }, 60000);
        } else {
          val = res.format(I18n.t('date.formats.' + dateFormat )) + dateSuffix;
        }
      }
    }
    ko.utils.domNodeDisposal.addDisposeCallback(element, function() {
      if (updateTimeout)
        clearTimeout(updateTimeout);
    });
    ko.utils.setTextContent(element, val);
  }
};

ko.bindingHandlers.fadeHtml = {
  update: function(element, valueAccessor) {
    var value = ko.utils.unwrapObservable(valueAccessor());
    $(element).fadeOut(250, function() {
      // set the text of the element,
      // value needs to be defined outside of the fadeOut callback to work
      ko.utils.setHtml(element, value);
      $(element).fadeIn(350, function() {
        $(element).css('display', '');
      });
    });
  }
};

ko.bindingHandlers.tooltip = {
  init: function(element, valueAccessor, allBindingsAccessor) {
    var unwrapObservables = ko.bindingHandlers.tooltip.getUnwrapObsValue(valueAccessor);
    var params = _.extend({}, { target: element }, unwrapObservables ? ko.toJS(valueAccessor()) : valueAccessor());
    if(!Kadenze.UIUtil.isMobileOrTablet() && !params.disableOnMobile) {
      new Kadenze.Tooltip(params);

      // Handle disposal, if KO removes the element
      ko.utils.domNodeDisposal.addDisposeCallback(element, function() {
        var tooltip = $(element).data('tooltip');
        $(element).data('tooltip', null);
        if (tooltip)
          tooltip.destroy();
      });
    }
  },
  update: function(element, valueAccessor) {
    var unwrapObservables = ko.bindingHandlers.tooltip.getUnwrapObsValue(valueAccessor);
    var accessor = unwrapObservables ? ko.toJS(valueAccessor()) : valueAccessor();
    if (accessor.rerenderOnUpdate) {
      var tooltip = $(element).data('tooltip');
      $(element).data('tooltip', null);
      if (tooltip)
        tooltip.destroy();
      var params = _.extend({}, { target: element }, accessor);
      if(!Kadenze.UIUtil.isMobileOrTablet() && !params.disableOnMobile) {
        new Kadenze.Tooltip(params);
      }
    }
  },
  getUnwrapObsValue: function(valueAccessor){
    if(!_.isUndefined(valueAccessor().unwrapObs)) {
      return valueAccessor().unwrapObs;
    } else {
      return true;
    }
  }
};

ko.bindingHandlers.tabKeyFocus = {
  init: function(element, valueAccessor, allBindingsAccessor) {
    Kadenze.UIUtil.initTabKeyFocus(element);
  },
  update: function(element, valueAccessor) {
    Kadenze.UIUtil.initTabKeyFocus(element);
  }
};

ko.bindingHandlers.setFocus = {
  setup: function (element, valueAccessor, allBindingsAccessor, viewModel, bindingContext) {
    var ms = allBindingsAccessor().setFocusDelay || 0;
    var value = ko.unwrap(valueAccessor());
    var $element = $(element);
    if(value){
      setTimeout(function(){
        $element.focus();
      }, ms);
    }
  },
  init: function (element, valueAccessor, allBindingsAccessor, viewModel, bindingContext) {
    ko.bindingHandlers.setFocus.setup(element, valueAccessor, allBindingsAccessor, viewModel, bindingContext);
  },
  update: function (element, valueAccessor, allBindingsAccessor) {
    ko.bindingHandlers.setFocus.setup(element, valueAccessor, allBindingsAccessor);
  }
};

ko.bindingHandlers.setAriaDropDownEvents = {
  setup: function(element, valueAccessor, allBindingsAccessor) {
    var value = ko.unwrap(valueAccessor());
    var $element = $(element);
    var $dropDown = $element.find('a.dropdown-toggle');
    var $dropDownUl = $element.find('ul');
    var $dropDownLink = $element.find('ul li a');
    if (value){
      /* set aria-expanded to true/false appropriately */
      $element.on('show.bs.dropdown', function(){
        $dropDown.attr('aria-expanded', 'true');
      });
      $element.on('hide.bs.dropdown', function(){
        $dropDown.attr('aria-expanded', 'false');
      });
      /* close the dropdown on esc keypress from menu items */
      $dropDownLink.off('keyup.closeDropDownOnEsc').on('keyup.closeDropDownOnEsc', function(e){
        if(e.which == 27){
          $dropDownUl.dropdown('toggle');
        }
      });
    }
  },
  init: function(element, valueAccessor, allBindingsAccessor) {
    ko.bindingHandlers.setAriaDropDownEvents.setup(element, valueAccessor, allBindingsAccessor);
  },
  update: function(element, valueAccessor, allBindingsAccessor) {
    ko.bindingHandlers.setAriaDropDownEvents.setup(element, valueAccessor, allBindingsAccessor);
  }
};

ko.bindingHandlers.sortableTh = {
  init: function(element, valueAccessor, allBindingsAccessor, viewModel, bindingContext) {
    var column = _.extend({}, { direction: ko.observable('asc') }, ko.toJS(valueAccessor()));
    var $title = $(element).find('.display-table__head_col_label');

    // Showing the default column by which it is sorted by
    if(column.default){
      $title.prepend('<i aria-label=\'Sorted by this column\' class=\'kf kf-back-arrow up\'></i>');
    } else {
      $title.prepend('<i aria-label=\'Sorted by this column\' class=\'kf kf-back-arrow hidden down\'></i>');
    }

    viewModel.th_columns().push(column);
    if(column.dragOverride){
      viewModel.override_column(column);
    }

    $title.css('cursor', 'pointer');
    $title.on('click', function (evt) {
      viewModel.sortClick(column, $(element));
    });
  }
};

//Binding to make elements shown/hidden via jQuery's fadeIn()/fadeOut() methods
ko.bindingHandlers.fadeVisible = {
  init: function(element, valueAccessor) {
    // Initially set the element to be instantly visible/hidden depending on the value
    var value = valueAccessor();
    $(element).toggle(ko.unwrap(value)); // Use "unwrapObservable" so we can handle values that may or may not be observable
  },
  update: function(element, valueAccessor) {
    // Whenever the value subsequently changes, slowly fade the element in or out
    var value = valueAccessor();
    ko.unwrap(value) ? $(element).fadeIn() : $(element).fadeOut();
  }
};

ko.bindingHandlers.hidden = (function() {
  function setVisibility(element, valueAccessor) {
    var hidden = ko.unwrap(valueAccessor());
    $(element).css('visibility', hidden ? 'hidden' : '');
  }
  return { init: setVisibility, update: setVisibility };
})();

ko.bindingHandlers.animate = {
  update: function(element, valueAccessor, allBindings, viewModel, bindingContext) {
    TweenLite.to(element, 0.5, valueAccessor());
  }
};

ko.bindingHandlers.slider = {
  setStyle: function (element, options) {
    if (typeof options.color != 'undefined') {
      var $sliderRange = $(element).find('.ui-slider-range');
      $sliderRange.css('backgroundColor', options.color);

      var $sliderHandle = $(element).find('.ui-slider-handle');
      $sliderHandle.css('background', options.color);
    }
  },
  init: function (element, valueAccessor, allBindingsAccessor) {
    var options = allBindingsAccessor().sliderOptions || {};
    $(element).slider(options);
    ko.bindingHandlers.slider.setStyle(element, options);
    ko.utils.registerEventHandler(element, 'slidechange', function (event, ui) {
      var observable = valueAccessor();
      if (typeof options.change === 'function') {
        if (options.change(event, ui.value, ko.dataFor(element))) {
          observable(ui.value);
        }
      }
      else {
        observable(ui.value);
      }
      event.stopPropagation();
      event.preventDefault();
    });
    ko.utils.domNodeDisposal.addDisposeCallback(element, function () {
      $(element).slider('destroy');
    });
    ko.utils.registerEventHandler(element, 'slide', function (event, ui) {
      var observable = valueAccessor();
      if (typeof options.slide === 'function') {
        if (options.slide(event, ui, ko.dataFor(element))) {
          observable(ui.value);
        }
      }
      else {
        observable(ui.value);
      }
      event.stopPropagation();
      event.preventDefault();
    });
  },
  update: function (element, valueAccessor, allBindingsAccessor) {
    var value = ko.utils.unwrapObservable(valueAccessor());
    var options = allBindingsAccessor().sliderOptions || {};
    ko.bindingHandlers.slider.setStyle(element, options);
    if (isNaN(value)) value = 0;
    $(element).slider('value', value);
    $(element).slider('option', options);
  }
};

ko.bindingHandlers.textPreview = {
  init: function (element, valueAccessor, allBindingsAccessor) {
    var obj = ko.utils.unwrapObservable(valueAccessor());
    // fetch our conetn
    var url = obj.href;

    var request = new Kadenze.AjaxRequest({
      url: url,
      type: 'GET',
      dataAsJSON: false,
      contentType: 'text/plain',
      dataType: 'text'
    });

    request.fail(function(xhr, responseText, textStatus) {
      new Kadenze.Notice.error({
        title: 'Error Loading File',
        message: I18n.t('notice.unexpected_error'),
        parentObject: element
      });
    });

    request.done(function(data) {
      var content = $('<pre><code>' + data + '</code></pre>');
      if (obj.type === 'code') {
        if (obj.h_lang) {
          content.addClass('language-' + obj.h_lang);
          Prism.highlightElement(content[0]);
        }
      }

      $(element).html(content).fadeIn(500);
    });
  },
  update: function (element, valueAccessor, allBindingsAccessor) {

  }

};

ko.bindingHandlers.datepicker = {
  init: function(element, valueAccessor, allBindingsAccessor) {
    //initialize datepicker with some optional options
    var options = ko.utils.unwrapObservable(allBindingsAccessor().datepickerOptions || {}),
      $el = $(element);

    var $dateEl = $el;
    if (!$el.hasClass('datetimepicker')) {
      $dateEl = $el.parents('.datetimepicker');
    }

    options = _.isEmpty(options) ? $dateEl.data() : options;

    $dateEl.data('DateTimePicker') && $dateEl.data('DateTimePicker').destroy();
    $dateEl.datetimepicker(options);
    var format = options['format'];

    $dateEl.off('change').on('change', function(e) {
      var observable = valueAccessor();
      if ($dateEl.find('input').val() !== '') {
        var date = Kadenze.Util.getMomentDate($dateEl.find('input').val());
        observable(Kadenze.Util.formatTime(date, format));
      } else {
        observable('');
      }
      //Manually refresh input since `change` event isn't triggered right away in certain cases
      Kadenze.KdForms().updateField($dateEl.find('input')[0]);
    });

    // Set the default date unless we have a date specified in the observable already
    var curDate = ko.utils.unwrapObservable(valueAccessor());
    if (options.setDate && curDate === null) {
      var picker = $dateEl.data('DateTimePicker');
      picker.setDate(Kadenze.Util.getMomentDate(options.setDate));

      // Trying to address this weird double parens syntax when valueaccessor
      // is a dependent observable
      if(typeof valueAccessor() === 'function') {
        valueAccessor()(Kadenze.Util.formatTime(options.setDate));
      } else if(typeof valueAccessor === 'function' ) {
        valueAccessor(Kadenze.Util.formatTime(options.setDate));
      }
    }

    // adjusts datetimepicker position when scrolling
    var t ;
    $( document ).on(
      'DOMMouseScroll mousewheel scroll',
      function(){
        var picker = $dateEl.data('DateTimePicker');
        if (picker) {
          window.clearTimeout( t );
          t = window.setTimeout( function(){
            picker.place();
          }, 10 );
        }
      }
    );

  },
  update: function(element, valueAccessor, allBindingsAccessor) {
    var value = ko.utils.unwrapObservable(valueAccessor()),
      $el = $(element),
      obs = ko.utils.unwrapObservable(allBindingsAccessor()).datepicker;

    if(obs && typeof(obs.isModified) != 'undefined') {
      var decorateElement = obs.isModified() && !obs.isValid();
      var $input = $el.parents('.datetimepicker').find('input');
      if ($input.length > 0) {
        if (decorateElement) {
          $input.addClass('error');
        } else {
          $input.removeClass('error');
        }
      }
    }

    var $dateEl = $el;
    if (!$el.hasClass('datetimepicker')) {
      $dateEl = $el.parents('.datetimepicker');
    }

    var date = moment(value);
    //handle date data coming via json
    var current = $dateEl.data('DateTimePicker').getDate();

    $dateEl.data('DateTimePicker').setDate(date);
  }
};

ko.bindingHandlers.datepicker3 = {
  init: function (element, valueAccessor, allBindingsAccessor) {
    //initialize datepicker with some optional options
    var options = ko.utils.unwrapObservable(allBindingsAccessor().datepickerOptions || {}),
      $el = $(element);

    var $dateEl = $el;
    if (!$el.hasClass('date')) {
      $dateEl = $el.parents('.date');
    }

    options = _.isEmpty(options) ? $dateEl.data() : options;

    $dateEl.datepicker(options);

    //When a user changes the date, update the view model
    ko.utils.registerEventHandler($dateEl, 'changeDate', function (event) {
      var observable = valueAccessor();
      if (ko.isObservable(observable)) {
        if (event.date !== '') {
          var date = moment(event.date);
          var format = options['format'];
          observable(Kadenze.Util.formatTime(date, format.toUpperCase()));
        } else {
          observable('');
        }
      }
    });

    //Set the date if it's specified in the observable
    var curDate = ko.utils.unwrapObservable(valueAccessor());
    if (!_.isEmpty(curDate)) {
      var widget = $dateEl.data('datepicker');
      widget.setDate(new Date(curDate));
    }
  },
  update: function (element, valueAccessor) {
    var value = ko.utils.unwrapObservable(valueAccessor()),
      $el = $(element);

    var $dateEl = $el;
    if (!$el.hasClass('date')) {
      $dateEl = $el.parents('.date');
    }

    var widget = $dateEl.data('datepicker');
    //When the view model is updated, update the widget
    if (widget) {
      widget.date = value;

      if (!widget.date) {
        return;
      }

      if (_.isString(widget.date)) {
        widget.date = new Date(widget.date);
      }

      widget.setValue();
    }
  }
};

ko.bindingHandlers.selectPicker = {
  init: function (element, valueAccessor, allBindingsAccessor, viewModel, bindingContext) {
    var $element = $(element);

    $element.addClass('selectpicker').selectpicker();

    $element.on('rendered.bs.select', function() {
      $element.parent().find('button').attr('aria-haspopup', 'true');
      $element.parent().find('button').attr('aria-expanded', 'false');
    });

    $element.on('shown.bs.select', function() {
      $element.parent().find('button').attr('aria-expanded', 'true');
    });

    $element.on('hidden.bs.select', function() {
      $element.parent().find('button').attr('aria-expanded', 'false');
    });

    var doRefresh = function() {
        $element.selectpicker('refresh');
      },

      subscriptions = [];

    // KO 3 requires subscriptions instead of relying on this binding's update
    // function firing when any other binding on the element is updated.

    // Add them to a subscription array so we can remove them when KO
    // tears down the element.  Otherwise you will have a resource leak.
    var addSubscription = function(bindingKey) {
      var targetObs = allBindingsAccessor.get(bindingKey);

      if ( targetObs && ko.isObservable(targetObs )) {
        subscriptions.push( targetObs.subscribe(doRefresh) );
      }
    };

    addSubscription('options');
    addSubscription('value');           // Single
    addSubscription('selectedOptions'); // Multiple
    addSubscription('selectedIndex');
    addSubscription('optionsCaption');

    ko.utils.domNodeDisposal.addDisposeCallback(element, function() {
      while( subscriptions.length ) {
        subscriptions.pop().dispose();
      }
    });

    // Fix for intermittent bug when KO tries to bind a selectpicker inside a selectpicker
    if($element.parent().parent().hasClass('bootstrap-select', 'btn-group')) {
      $element.parent().parent().find('button').first().remove();
    }

    doRefresh();
  },

  update: function (element, valueAccessor, allBindingsAccessor) {
    var obs = ko.utils.unwrapObservable(allBindingsAccessor()).value;
    if(obs && typeof(obs.isModified) != 'undefined') {
      var decorateElement = obs.isModified() && !obs.isValid();
      if (decorateElement) {
        $(element).parent().addClass('selectpicker error');
      } else {
        $(element).parent().removeClass('selectpicker error');
      }
    }
  }
};

ko.bindingHandlers.filebox = {
  init: function (element, valueAccessor, allBindingsAccessor) {
    var $element = $(element);
    var opts = {};
    var data = ko.utils.unwrapObservable(valueAccessor()) || {};
    data = ko.mapping.toJS(data);
    if (allBindingsAccessor.has('attr')) {
      opts['template'] = allBindingsAccessor.get('attr').template;
      opts['templatePath'] = allBindingsAccessor.get('attr').templatePath;
    }

    if (data.id != undefined) {
      var fileObj = new Kadenze.GenericFileViewModel(data, $element, opts);
      fileObj.render();
    }
  },
  update: function (element, valueAccessor, allBindingsAccessor) {

  }
};

// Embedd inline svg in KO, depends on snap.js to do the loading
// In order to use this safely, you must provide the image_path version
// of the url, meaning your KO viewModel must process as erb first
ko.bindingHandlers.embeddedSvg = {
  init: function(element, valueAccessor, allBindingsAccessor) {
    var url = ko.utils.unwrapObservable(valueAccessor()),
      $element = $(element);

    Snap.load(url, function(response) {
      $element.append(response.node);
    });
  }
};

//intended to work with attachment data objects as 'values'.
//TODO this could be generalized to take a url and a display method (video/embed)
//without needing to be dependent on the format of the attachment data object.
ko.bindingHandlers.mediaPlayer = {
  init: function (element, valueAccessor, allBindingsAccessor) {

    var $element = $(element);
    var data = ko.utils.unwrapObservable(valueAccessor()) || {};
    data = ko.mapping.toJS(data);
    var autoplay = allBindingsAccessor.get('autoplay') || false;
    var opts = allBindingsAccessor.get('mediaPlayerOpts') || null;

    if(data.meta.type)
    {
      if(data.meta.type === 'video' && data.media_urls)
      {
        var mediaUrls = {
          h264_360_url: data.media_urls['h264_360_url'],
          h264_720_url: data.media_urls['h264_720_url']
        };

        var html5Player = new Kadenze.VideoPlayer($element, mediaUrls, 'vjs-student-skin', null, null, opts);
        html5Player.show().play(autoplay);
      }
      else if(data.meta.type === 'url' && data.url)
      {
        var iframePlayer = new Kadenze.IframeMediaPlayer($element, data.url);
        iframePlayer.show().play(autoplay);
      }
    }
  },
  update: function (element, valueAccessor, allBindingsAccessor) {}
};

// For initializing observables dynamically from the markup (for dynamic schema purposes)
ko.bindingHandlers.initObservable = {
  init: function(element, valueAccessor, allBindingsAccessor, data) {
    var name = element.getAttribute('data-bind').match(/initObservable:\s*([\w0-9\-_]+),?.*$/)[1];
    Kadenze.Knockout.initObservable(data, name);
  }
};

ko.bindingHandlers.valueWithInit = {
  init: function(element, valueAccessor, allBindings, data, context) {
    var name = element.getAttribute('data-bind').match(/valueWithInit:\s*([\w]+)/)[1];
    Kadenze.Knockout.initObservable(data, name);
    ko.applyBindingsToNode(element, { value: valueAccessor() }, context);
  }
};

// see: http://stackoverflow.com/questions/9679191/use-knockoutjs-virtual-element-to-create-html-part-on-the-fly/15348139#15348139
(function() {
  var overridden = ko.bindingHandlers['html'].update;

  ko.bindingHandlers['html'].update = function (element, valueAccessor) {
    if (element.nodeType === 8) {
      var html = ko.utils.unwrapObservable(valueAccessor());

      ko.virtualElements.emptyNode(element);
      if ((html !== null) && (html !== undefined)) {
        if (typeof html !== 'string') {
          html = html.toString();
        }

        var parsedNodes = ko.utils.parseHtmlFragment(html);
        if (parsedNodes) {
          var endCommentNode = element.nextSibling;
          for (var i = 0, j = parsedNodes.length; i < j; i++)
            endCommentNode.parentNode.insertBefore(parsedNodes[i], endCommentNode);
        }
      }
    } else { // plain node
      overridden(element, valueAccessor);
    }
    Kadenze.WebApp.initWysiwygLinks($(element.parentNode));
  };
})();
ko.virtualElements.allowedBindings['html'] = true;

ko.bindingHandlers.afterHtmlRender = {
  update: function(element, valueAccessor, allBindings){
    // check if element has 'html' binding
    if (!allBindings().html) return;
    // get bound callback (don't care about context, it's ready-to-use ref to function)
    var callback = valueAccessor();
    // fire callback with new value of an observable bound via 'html' binding
    callback(allBindings().html);
  }
};

ko.bindingHandlers.selectedIndex = {
  init: function(element, valueAccessor, allBindings, data, context) {
    ko.utils.registerEventHandler(element, 'change', function() {
      var value = valueAccessor();
      if (ko.isWriteableObservable(value)) {
        value(element.selectedIndex);
      }
    });
  }
};

ko.bindingHandlers.animateNumber = {
  init: function(element, valueAccessor) {
    var value = ko.utils.unwrapObservable(valueAccessor());
    $(element).animateNumber({
      number: value,
      numberStep: $.animateNumber.numberStepFactories.separator(','),
      eaising: 'easeInOutQuint'
    });
  },

  update: function(element, valueAccessor) {
    var value = ko.utils.unwrapObservable(valueAccessor());
    $(element).animateNumber({
      number: value,
      numberStep: $.animateNumber.numberStepFactories.separator(','),
      eaising: 'easeInOutQuint'
    });
  }
};

ko.bindingHandlers.animatePercentage = {
  init: function(element, valueAccessor) {
    var value = ko.utils.unwrapObservable(valueAccessor());
    $(element).animateNumber({
      number: value,
      numberStep: $.animateNumber.numberStepFactories.append('%'),
      eaising: 'easeInOutQuint'
    });
  },

  update: function(element, valueAccessor) {
    var value = ko.utils.unwrapObservable(valueAccessor());
    $(element).animateNumber({
      number: value,
      numberStep: $.animateNumber.numberStepFactories.append('%'),
      eaising: 'easeInOutQuint'
    });
  }
};

ko.bindingHandlers.hideOnFocusout = {
  init: function (element, valueAccessor, allBindingsAccessor, viewModel, bindingContext) {
    var default_opts = {callback: null};
    var options = ko.utils.unwrapObservable(valueAccessor()) || {};

    options = _.extend(default_opts, options);

    var fn = viewModel[options.callback];

    $(element).click(function (evt) {
      //Handle case where we lose focus on an input element contained inside of our container.
      //once input element blurs return focus to container div.
      if ($(evt.target).is(':input')) {
        $(evt.target).focusout(function () {
          $(element).focus();
        });
      }
    });

    $(element).blur(function (evt) {
      setTimeout(function () {
        var $ac = $(document.activeElement).parents('#' + evt.target.id);
        if ($ac.length === 0) {
          if (typeof fn === 'function') {
            fn.apply();
          } else {
            $(element).hide();
          }
        }
      });
    });
  }
};

//
// TODO: can remove for Quill?
//
ko.bindingHandlers.redactor = {
  init: function(element, valueAccessor, allBindingsAccessor, viewModel, bindingContext) {
    var value = valueAccessor(),
      $el = $(element);

    if(ko.isObservable(value)) {
      var opts = {};
      if (allBindingsAccessor.has('attr')) {
        var opts = allBindingsAccessor.get('attr').redactorOpts;
      }
      Kadenze.Redactor.init(element, opts);

      $el.on('change', function() {
        var obs = valueAccessor();
        obs($(this).val());
      });

      if(ko.validation.utils.isValidatable(value)) {
        // Register with KO Validation, set the editor as the element to decorate
        var editor = $el.redactor('core.getEditor')[0];
        return ko.bindingHandlers.validationCore.init(editor, valueAccessor, allBindingsAccessor, viewModel, bindingContext);
      }
    }
  },

  update: function(element, valueAccessor) {
    var value = ko.utils.unwrapObservable(valueAccessor()) || '',
      $el = $(element);

    if($el.data('redactorInitialized') && value !== $el.redactor('core.getTextarea').val()) {
      $el.redactor('code.set', value);
      $el.redactor('placeholder.remove');

      // Addressing forum thread comments editing replies
      Kadenze.Redactor.utils.renderPreTags($el.redactor('core.getEditor'));
    }
  }
};

ko.bindingHandlers.quill = {
  init(element, valueAccessor, allBindingsAccessor, viewModel, bindingContext) {
    const value = valueAccessor();

    if (ko.isObservable(value)) {
      const className = allBindingsAccessor.get('attr').className;

      const quill = new Kadenze.Quill().init(
        element,
        value,
        className,
        allBindingsAccessor.get('attr')
      );

      if (value())
        quill.pasteHTML(value())

      quill.on('text-change', () => {
        const obs = valueAccessor();
        obs(document.querySelector(`.${className} .ql-editor`).innerHTML)
      });
    }
  }
};

ko.bindingHandlers.toggleClass = {
  update: function(element, valueAccessor) {
    var $el = $(element),
      values = ko.unwrap(valueAccessor());

    _.each(values, function(value, key) {
      var cssClass = s.dasherize(key);

      if(value()) { // value must evaluate to a boolean value
        $el.addClass(cssClass);
      } else {
        $el.removeClass(cssClass);
      }
    });
  }
};

ko.bindingHandlers.barrating = {
  init: function (element, valueAccessor) {
    var local = ko.toJS(valueAccessor()),
      options = { };

    if(typeof local === 'number') {
      local = {
        value: local
      };
    }

    ko.utils.extend(options, ko.bindingHandlers.barrating.options);
    ko.utils.extend(options, local);

    options.onSelect = function(val, txt) {
      var floa = parseFloat(val, 10);
      var observable = valueAccessor();
      if(ko.isObservable(observable)) {
        observable(floa);
      } else {
        if(observable.value !== undefined && ko.isObservable(observable.value)) {
          observable.value(floa);
        }
      }
    };

    options.onClear = function(val, txt) {
      var observable = valueAccessor();
      if(ko.isObservable(observable)) {
        observable(null);
      } else {
        if(observable.value !== undefined && ko.isObservable(observable.value)) {
          observable.value(null);
        }
      }
    };

    for (var i=1; i<=options.max; i++) {
      $(element).append('<option value=' + i + '>' + i + '</option>');
    }

    $(element).barrating('show', options);

    var $rating = $('.br-widget', $(element).parent());

    // add clear button... @Kevin I'm not sure what the best way of getting in the inline svg in the form.
    var $clearBtnMarkup =  $('<div class="bar-rating__clear js-rating-clr-btn"></a>');
    $clearBtnMarkup.append('<i class="kdnze-x-circle"/>');
    //-#: #{ embedded_svg("svg/svg-icons/x-circle.svg") }
    $rating.append($clearBtnMarkup);

    $clearBtnMarkup.on('click.clearRating', function(){
      $(element).barrating('clear', options);
    });

  },
  update: function(element, valueAccessor) {
    var local = ko.toJS(valueAccessor());

    if(typeof local === 'number') {
      local = {
        value: local
      };
    }

    if (local.value !== undefined) {
      var floa = parseFloat(local.value, 10);
      $(element).barrating('setVal', floa);
    }

  },
  options: {
    //this section is to allow users to override the barrating defaults on a per site basis.
    max: 10
  }
};

//For preventing parent VM to bind on child elements which are already bound to a different child VM.
//Prevents this error: "You cannot apply bindings multiple times to the same element."
ko.bindingHandlers.stopBindings = {
  init: function(elem, valueAccessor) {
    // Let bindings proceed as normal *only if* my value is false
    var shouldStopBindings = ko.unwrap(valueAccessor());
    return { controlsDescendantBindings: shouldStopBindings };
  }
};

ko.bindingHandlers.fadeTemplate = {
  init: function(element, valueAccessor, allBindings, viewModel, bindingContext) {
    return ko.bindingHandlers['template']['init'](element, valueAccessor, allBindings);
  },
  update: function(element, valueAccessor, allBindings, viewModel, bindingContext) {
    var value = valueAccessor();
    $(element).fadeOut(function() {
      ko.bindingHandlers['template']['update'](element, valueAccessor, allBindings, viewModel, bindingContext);
      $(this).fadeIn();
    });
  }
};

ko.bindingHandlers.slideTemplate = {
  init: function(element, valueAccessor, allBindings, viewModel, bindingContext) {
    return ko.bindingHandlers['template']['init'](element, valueAccessor, allBindings);
  },
  update: function(element, valueAccessor, allBindings, viewModel, bindingContext) {
    var direction = allBindings().slideDirection || 'right',
      disabled = allBindings().slideDisabled || false;

    if (disabled) {
      ko.bindingHandlers['template']['update'](element, valueAccessor, allBindings, viewModel, bindingContext);
    } else {
      ko.bindingHandlers['template']['update'](element, valueAccessor, allBindings, viewModel, bindingContext);
      var x = (direction === 'right') ? 500 : -500;
      TweenLite.from(element, 0.4, { x: x });
    }
  }
};

ko.bindingHandlers.removeChildDuplicatesBasedOn = {
  init: function(element, valueAccessor, allBindings) {
    Kadenze.UIUtil.removeDuplicateNodes(element.children, valueAccessor());
  }
};

ko.bindingHandlers.dotdotdot = {
  init: (element, valueAccessor, _allBindings) => {
    const value = ko.unwrap(valueAccessor());
    $(element).text(value).dotdotdot({ watch: "window" });
  }
};

ko.bindingHandlers.dotdotdot_readmore = {
  init: function(element, valueAccessor, allBindings) {
    $(element).dotdotdot({watch: 'window', after: 'a.read-more'});
    $(element).addClass('js-dotdotdot dotdotdot-readmore');
    $('body').off('click','a.read-more').on('click','a.read-more', function(e) {
      e.preventDefault;
      var el = $(this).closest('.dotdotdot-readmore');
      el.trigger('destroy').find('a.read-more').hide();
      // used later to reset the max-height css value to its original value
      el.data('maxHeight', el.css('max-height'));
      el.css('max-height', 'inherit');
      $('a.read-less', el).show();
    });
    $('body').off('click','a.read-less').on('click','a.read-less', function(e) {
      e.preventDefault;
      $(this).hide();
      var el = $(this).closest('.dotdotdot-readmore');
      $(this).closest('a.read-more').remove();
      el.trigger('destroy');
      el.css('max-height', el.data('maxHeight')).dotdotdot({ watch : 'window', after: 'a.read-more' });
    });
  }
};

ko.bindingHandlers.kdForm = {
  init: function(element, valueAccessor, allBindings, viewModel, bindingContext) {
    (new Kadenze.KdForms()).initField(element);
  },
  update: function(element, valueAccessor, allBindings, viewModel, bindingContext) {
    var val = ko.utils.unwrapObservable(valueAccessor());
    var hasVal = !isNaN(val) || (val && val.replace(/\s/, '') != '');
    (new Kadenze.KdForms()).updateField(element, hasVal);
  }
};

ko.bindingHandlers.numericValue = {
  init : function(element, valueAccessor, allBindings, data, context) {
    var interceptor = ko.computed({
      read: function() {
        return ko.unwrap(valueAccessor());
      },
      write: function(value) {
        if (!isNaN(value)) {
          valueAccessor()(parseFloat(value));
        }
      },
      disposeWhenNodeIsRemoved: element
    });

    ko.applyBindingsToNode(element, { value: interceptor }, context);
  }
};

ko.bindingHandlers.enterkey = {
  init: function (element, valueAccessor, allBindings, viewModel) {
    var callback = valueAccessor();
    $(element).keypress(function (event) {
      var keyCode = (event.which ? event.which : event.keyCode);
      if (keyCode === 13) {
        callback.call(viewModel);
        return false;
      }
      return true;
    });
  }
};

ko.bindingHandlers.enterkeyWithContext = {
  init: function (element, valueAccessor, allBindings, viewModel) {
    var callback = valueAccessor();
    $(element).keypress(function (event) {
      var keyCode = (event.which ? event.which : event.keyCode);
      if (keyCode === 13) {
        callback.call(viewModel, viewModel, event);
        return false;
      }
      return true;
    });
  }
};

// eachProp is for when you want a foreach loop want to loop through
// every property in the unwrapped observable and have access to both the key and value
ko.bindingHandlers.eachProp = {
  transformObject: function (obj) {
    var properties = [];
    for (var key in obj) {
      if (obj.hasOwnProperty(key)) {
        properties.push({ key: key, value: obj[key] });
      }
    }
    return ko.observableArray(properties);
  },
  init: function(element, valueAccessor, allBindingsAccessor, viewModel, bindingContext) {
    var value = ko.utils.unwrapObservable(valueAccessor()),
      properties = ko.bindingHandlers.eachProp.transformObject(value);

    ko.bindingHandlers['foreach'].init(element, properties, allBindingsAccessor, viewModel, bindingContext);
    return { controlsDescendantBindings: true };
  },
  update: function(element, valueAccessor, allBindingsAccessor, viewModel, bindingContext) {
    var value = ko.utils.unwrapObservable(valueAccessor()),
      properties = ko.bindingHandlers.eachProp.transformObject(value);

    ko.bindingHandlers['foreach'].update(element, properties, allBindingsAccessor, viewModel, bindingContext);
    return { controlsDescendantBindings: true };
  }
};

// http://stackoverflow.com/questions/18129392/how-to-tie-together-ko-validation-errors-with-related-viewmodel-field-names
var originalValidationMessageUpdate= ko.bindingHandlers.validationMessage.update;
ko.bindingHandlers.validationMessage.update =
    function (element, valueAccessor, allBindingAccessor, viewModel, bindingContext) {
      if (originalValidationMessageUpdate) {
        originalValidationMessageUpdate(element, valueAccessor,
          allBindingAccessor, viewModel,
          bindingContext);
      }
      var prepend = (ko.isObservable(valueAccessor())
                      && valueAccessor().friendlyName)
        ? valueAccessor().friendlyName + ' ' : '';

      if(prepend.length > 0) {
        $(element).html(prepend + 'is required');
      }
    };

ko.bindingHandlers.track = {
  init: function(element, valueAccessor, allBindingsAccessor) {
    $(element).on('click', function() {
      var params = _.extend({}, { collection: null, type: null, data: {} }, ko.mapping.toJS(ko.utils.unwrapObservable(valueAccessor())));
      if (params.collection && params.type) {
        Kadenze.Analytics.track(params.collection, params.type, params.data);
      }
    });
  }
};
/******************************************
 * CUSTOM EXTENDERS
 ******************************************/

ko.extenders.numberToPercentage = function (target, precision) {
  var result = ko.dependentObservable({
    read: function () {
      return Number(target()).toFixed(precision) + '%';
    },
    write: target
  });
  result.raw = target;
  return result;
};

ko.extenders.defaultValue = function(target, defaultValue) {
  var result = ko.computed({
    read: target,
    write: function(newValue) {
      if (!newValue){
        target(defaultValue);
      } else {
        target(newValue);
      }
    }
  });

  result(target());

  return result;
};

ko.extenders.friendlyName = function (obs, options) {
  obs.friendlyName = options;
};

ko.extenders.numeric = function(target, precision) {
  //create a writable computed observable to intercept writes to our observable
  var result = ko.pureComputed({
    read: target,  //always return the original observables value
    write: function(newValue) {
      var current = target(),
        roundingMultiplier = Math.pow(10, precision),
        newValueAsNum = isNaN(newValue) ? 0 : +newValue,
        valueToWrite = Math.round(newValueAsNum * roundingMultiplier) / roundingMultiplier;

      //only write if it changed
      if (valueToWrite !== current) {
        target(valueToWrite);
      } else {
        //if the rounded value is the same, but a different value was written, force a notification for the current field
        if (newValue !== current) {
          target.notifySubscribers(valueToWrite);
        }
      }
    }
  }).extend({ notify: 'always' });

  //initialize with current value to make sure it is rounded appropriately
  result(target());

  //return the new computed observable
  return result;
};
/*******************************************
* CUSTOM COMPONENTS
*******************************************/

ko.subscribable.fn.subscribeChanged = function (callback) {
  var oldValue;
  this.subscribe(function (_oldValue) {
    oldValue = _oldValue;
  }, this, 'beforeChange');

  this.subscribe(function (newValue) {
    callback(newValue, oldValue);
  });
};

ko.observable.fn.withPausing = function() {
  this.notifySubscribers = function() {
    if (!this.pauseNotifications) {
      ko.subscribable.fn.notifySubscribers.apply(this, arguments);
    }
  };

  this.sneakyUpdate = function(newValue) {
    this.pauseNotifications = true;
    this(newValue);
    this.pauseNotifications = false;
  };

  return this;
};

/*******************************************
* CUSTOM LOADERS
*******************************************/

const templateFromJST = {
  loadTemplate: (name, templateConfig, callback) => {
    if (templateConfig.JST) {
      const template = JST[templateConfig.JST]()
      ko.components.defaultLoader.loadTemplate(name, template, callback)
    } else {
      callback(null)
    }
  }
}

// Register loaders
ko.components.loaders.unshift(templateFromJST);


/*******************************************
* CUSTOM FUNCTIONS
*******************************************/
ko.dirtyFlag = function(root, isInitiallyDirty, mapping) {
  var result = function() {},
    _initialState = ko.observable(ko.mapping.toJSON(root, mapping)),
    _isInitiallyDirty = ko.observable(isInitiallyDirty);

  result.isDirty = ko.computed(function() {
    return _isInitiallyDirty() || _initialState() !== ko.mapping.toJSON(root, mapping);
  });

  result.reset = function() {
    _initialState(ko.toJSON(root, mapping));
    _isInitiallyDirty(false);
  };

  return result;
};

/*******************************************
* COMPONENTS
*******************************************/

if (!ko.components.isRegistered('checkbox')) {
  ko.components.register('checkbox', {
    viewModel: function(params) {
      var self = this;

      self.defaults = {
        name: 'kadenze_checkbox',
        label: 'Kadenze Checkbox',
        checked: ko.observable(false),
        required: ko.observable(false)
      };

      self.config = _.extend({}, self.defaults, params);

      self.name = self.config.name;
      self.label = self.config.label;
      self.checked = self.config.checked;
      self.required = self.config.required;

      self.toggleChecked = function() {
        self.checked(!self.checked());
      };
    },

    template: { JST: 'templates/checkbox' }
  });
}

if (!ko.components.isRegistered('datepicker')) {
  ko.components.register('datepicker', {

    viewModel: function(params, componentInfo) {
      var self = this;

      self.defaults = {
        label: 'Date',
        date: ko.observable(),
        class: '',
        options: {
          format: 'MM/DD/YYYY hh:mm A z'
        }
      };

      self.config = _.extend({}, self.defaults, params);

      self.label = self.config.label;
      self.format = self.config.format;
      self.options = self.config.options;
      self.date = self.config.date;

      self.className = ko.computed(function() {
        return 'input-group datetimepicker date form--datepicker ' + self.config.class;
      });
    },

    template: { JST: 'templates/datepicker' }
  });
}

///////////////////////////////////////////////////////////////////////////////
//NOTE: This component is deprecated please use Kadenze.ShareModal service instead
///////////////////////////////////////////////////////////////////////////////
if (!ko.components.isRegistered('share-menu')) {
  ko.components.register('share-menu', {
    viewModel: function(params) {
      var self = this;

      self.defaults = {
        shareableObj: {},
        title: 'Share',
        emailShareCb: null,
        attrs: {
          title: 'title',
          description: 'description',
          url: 'share_url'
        }
      };

      self.config = _.extend({}, self.defaults, params);

      self.title = ko.observable(self.config.title);
      self.shareEmails = ko.observable();
      self.emailSharing = ko.observable(false);

      self.share_title = ko.observable(self.config.shareableObj[self.config.attrs.title]);

      self.share_desc = ko.observable(self.config.shareableObj[self.config.attrs.description]);

      self.share_url = ko.observable(self.config.shareableObj[self.config.attrs.url]);



      self.hideShareMenu = function(item, event) {
        self.config.shareableObj.sharing(false);
      };

      self.showEmailScreen = function(item, event) {
        self.emailSharing(true);

        var $menu = $(event.target).parents('.js-course-card-share-menu');
        var $el = $menu.find('.js-tagsinput');
        if (typeof $el.tagsinput != 'undefined') {
          $el.tagsinput({
            confirmKeys: [9, 13, 188],
            trimValue: true,
            allowDuplicates: false
          });
        }
        $menu.find('.bootstrap-tagsinput input')[0].focus();
      };

      self.hideEmailScreen = function(item, event) {
        self.emailSharing(false);
        var $el = $(event.target).parents('.js-course-card-share-menu').find('.js-tagsinput');
        if ($el.length > 0 && typeof $el.tagsinput != 'undefined') {
          $el.tagsinput('removeAll');
        }
        self.shareEmails('');
      };

      self.sendShareEmail = function(item, event) {
        //Manually trigger an enter key press just in case if the user didn't
        //This is needed for tags input to accept all emails
        var e = jQuery.Event('keydown', { which: $.ui.keyCode.ENTER });
        var $el = $(event.target).parents('.js-course-card-email').find('.bootstrap-tagsinput > input');
        $el.trigger(e);

        var client_errors = ko.validation.group([self.shareEmails], { deep: true });
        if (_.compact(client_errors()).length === 0) {
          if (typeof self.config.emailShareCb == 'function') {
            self.config.emailShareCb.call(this, self.config.shareableObj, self.shareEmails().split(','));
          }
          else {
            console.warn('No e-mail function specified in the configuration. No e-mails were sent :(');
          }
          self.hideEmailScreen(null, event);
          self.hideShareMenu(null, event);
        } else {
          client_errors.showAllMessages();
          new Kadenze.Notice.error({message: client_errors()[0]});
        }
      };

      self.areEmailsValid = function(emails) {
        if (_.isEmpty(emails)) {
          return false;
        } else {
          return _.every(emails.split(','), function(email) {
            return (/^.+@.+\..+$/i).test(email);
          });
        }
      };
    },

    template: { JST: 'templates/share' }
  });
}
