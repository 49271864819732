export default function(params) {
  var self = this;

  self.defaults = {
    friendlyName: 'View Fullscreen (desktop only)'
  };

  self.config = _.extend({}, self.defaults, params);
  self.friendlyName = self.config.friendlyName;

  self.handler = function(event) {
    event.preventDefault();
    event.stopPropagation();
    event.stopImmediatePropagation();

    Kadenze.UIUtil.enterFullScreen(self.config.$iframe.get(0));
  };

  return self;
}

