export default function(params) {
  var self = this;

  self.defaults = {
    friendlyName: 'Open In New Tab'
  };

  self.config = _.extend({}, self.defaults, params);
  self.friendlyName = self.config.friendlyName;

  self.handler = function() {
    window.open(self.config.$iframe.prop('src'));
  };

  return self;
}

