Kadenze.Timers = [];

const WebApp = {
  init(container) {
    this.$container = container ? $(container) : $('body');

    this.setTimeZone();
    this.runInitializers();
    this.checkTimeoutStatus();
    this.checkRailsNotices();
    this.checkResetMixpanel();
    this.clearTimers()
    this.gaPageTrack()
  },

  runInitializers($container) {
    //
    // TODO: there's some kind of workaround
    // or counterintuitive workflow happening
    // with the container here, investigate it
    //
    this.$container = $container || this.$container || $('body');

    this.initFormElements(this.$container);
    this.initDatepicker(this.$container);
    this.initWysiwygLinks(this.$container);
    this.initHelpTooltips(this.$container);
    this.initSliders(this.$container);
    this.initQuillEditors(this.$container);
    this.highlightCode(this.$container);
    this.initKadenzeTooltips(this.$container);
    //
    // TODO: Due to how these functions are used in the client JS,
    // it is not currently possible to rely on a single
    // `this.$container`
    //

    this.initDropzoneConfig();
    this.initConfirmDelete();
    this.initProgressBar();
    this.initFileBoxes();


    Kadenze.UIUtil.initDotDotDotTruncation();
    Kadenze.UIUtil.initExpandableText();
    Kadenze.UIUtil.initTabAreas();
    Kadenze.UIUtil.initModalFocusEvents();
    Kadenze.UIUtil.initClipboard();
    Kadenze.UIUtil.initSmoothScroll();
    Kadenze.UIUtil.initSelectPickerEvents();
    Kadenze.UIUtil.initTabKeyFocus();

    Kadenze.KdTabs.init();

    new Kadenze.KdForms().init();

    Kadenze.IframeUtils.decorateAll();

    this.initMobileAppLogin();
  },

  initializePjax() {
    //
    // To avoid issues with JS frameworks that do their own DOM rendering
    //
    if ($.support.pjax)
      $.pjax.defaults.maxCacheLength = 0;

    $(document).pjax(
      'a[href]:not([data-remote]):not([data-behavior]):not([data-skip-pjax]):not([target="_blank"])',
      '[data-pjax-container]',
    );

    //
    // update the mobile nav header with the new page title
    //
    $(document).on('pjax:end', () => {
      $('#js_page-header-container--nav')
        .html($('#js_page-header-container').html());
      Kadenze.WebApp.clearTimers();
    });
  },

  clearTimers() {
    while (Kadenze.Timers.length) { clearInterval(Kadenze.Timers.shift()) }
  },

  setTimeZone() {
    $.cookie('browser.timezone', jstz.determine().name(), { expires: 365, path: '/' });
    moment.tz.setDefault(this.getTimeZone());
  },

  getTimeZone() {
    return $.cookie('user.timezone') || $.cookie('browser.timezone');
  },

  checkTimeoutStatus() {
    if (!Kadenze.Routes.session_time_left_path)
      return;

    const timer = setInterval(() => {
      Kadenze.onModuleLoaded('AjaxRequest', () => {
        const request = new Kadenze.AjaxRequest({
          type: 'GET',
          url: Kadenze.Routes.session_time_left_path(),
          headers: {
            'X-SILENCE-LOGGER': true
          }
        });

        request.done(data => {
          if (data.time_left && parseInt(data.time_left, 10) < (5 * 60)) {
            Kadenze.onModuleLoaded('UIUtil', () => {
              Kadenze.UIUtil.showTimeoutWarning((data.time_left / 60).floor);
            });

            clearInterval(timer);
          }
        });
      });
    }, 60000 * 5);

    Kadenze.Timers.push(timer);
  },

  gaPageTrack() {
    if (typeof ga === 'function') {
      ga('set', 'location', location.href);
      ga('send', 'pageview', { title: document.title });
    }
  },

  onHjLoaded(callback) {
    if (typeof hj === 'function') {
      if (typeof callback === 'function')
        return callback();

    } else {
      setTimeout(Kadenze.WebApp.onHjLoaded.bind(this, callback), 100);
    }
  },

  onFbLoaded(callback) {
    if (typeof fbq === 'function') {
      if (typeof callback === 'function')
        return callback();

    } else {
      setTimeout(Kadenze.WebApp.onFbLoaded.bind(this, callback), 100);
    }
  },

  fbEventTrack(tag, data) {
    data = data || {};
    Kadenze.WebApp.onFbLoaded(() => {
      fbq('track', tag, data);
    });
  },

  // Check for rails notices and fade them out after 5 seconds
  checkRailsNotices() {
    const $railsNotice = $('.notice-rails');

    if ($railsNotice.length)
      setTimeout(() => Kadenze.UIUtil.removeNotices(), 5000);
  },

  checkResetMixpanel() {
    Kadenze.onModuleLoaded('Util', () => {
      if (Kadenze.Util.getParameterByName('signed_out'))
        Kadenze.onModuleLoaded('Analytics', () => {
          Kadenze.Analytics.resetTrackingData();
        });
    });
  },

  initFormElements($container) {
    const _setCheckbox = $ele => {
      const targetID = $ele.attr('id');
      const $label = $('label[for=\'' + targetID + '\']');

      if ($ele.is(':checked')) {
        // $label.css("background-position", "0px -32px");
        $label.addClass('selected');
      } else {
        // $label.css("background-position", "0px 0");
        $label.removeClass('selected');
      }
    };

    $('body').off('click.checkbox')
      .on(
        'click.checkbox',
        'input[type=checkbox]',
        e => _setCheckbox($(e.target))
      );

    $('input[type=checkbox]', $container).each((i, item) => _setCheckbox($(item)));

    const _setRadioButtons = ele => {
      const $ele = $(ele);

      $('input[name=\'' + $ele.attr('name') + '\' ]', $container).each((i, item) => {
        $('label[for=\'' + i.id + '\']').removeClass('selected');
      });

      const targetID = $ele.attr('id');
      const $label = $('label[for=\'' + targetID + '\']');

      if ($ele.is(':checked')) {
        $label.addClass('selected');
      } else {
        $label.removeClass('selected');
      }
    };

    const _radioClickHandler = e => _setRadioButtons($(e.target));

    $('body').off('click.radio')
      .on(
        'click.radio',
        'input[type=radio]',
        e => _setRadioButtons(e.target)
      )

    $('body').off('focus.radio')
      .on(
        'focus.radio',
        'input[type=radio]',
        e => $('label[for=\'' + e.target.id + '\']').addClass('focused')
      );

    $('body').off('blur.radio')
      .on(
        'blur.radio',
        'input[type=radio]',
        e => $('label[for=\'' + e.target.id + '\']').removeClass('focused')
      )

    $('input[type=radio]', $container).each((i, item) => {
      if ($(this).is(':checked'))
        _setRadioButtons($(item));
    });

    $('.selectpicker', $container).selectpicker();

    $('.selectpicker-typeahead', $container).selectpicker({
      dropupAuto: false
    });

    // trim spaces from the beginning/end of non-password text fields before submitting
    $('body').on('keydown', 'input[type="text"]', e => {
      if (e.which == 13)
        trimSpaces(e.target);
    });

    $('body').on('blur', 'input[type="text"]', e => {
      trimSpaces(e.target);
    });

    const trimSpaces = inputObj => {
      const rawVal = $(inputObj).val();
      const trimmedVal = rawVal.trim();

      $(inputObj).val(trimmedVal);
    };
  },

  initDatepicker($container) {
    $('.datetimepicker', $container).each((i, item) => {
      const options = $(item).data();
      const input = $('input', $(item));

      options.pickSeconds = false;

      $(item).datetimepicker(options);

      if (options.setDate && !$.trim(input.val())) {
        const picker = $(item).data('DateTimePicker');

        picker.setDate(new Date(options.setDate));

        //
        // let the datepicker start at our date,
        // but clear out the input
        //
        $('input', $(item)).val('');
      }
    });
  },

  initWysiwygLinks($container) {
    const re = /^(https?:\/\/[^\/]+).*$/;
    const currentHref = window.location.href.replace(re, '$1');
    const reLocal = new RegExp('^' + currentHref.replace(/\./, '\\.'));

    let links;

    if ($container.hasClass('wysiwyg-content')) {
      links = $('a', $container);
    } else {
      links = $('.wysiwyg-content a', $container);
    }

    if (!links.length)
      return;

    links.each((_idx, ele) => {
      const $ele = $(ele);
      const href = $ele.attr('href');

      $ele.attr('data-skip-pjax', true);

      if (href == '' || reLocal.test(href) || !re.test(href)) {
        // This is an internal link, let it slide
        return;
      } else {
        $ele.attr('target', '_blank');
      }
    });
  },

  //Setup help and tips areas
  initHelpTooltips(aParent) {
    let $helpTooltip;

    const _createTooltip = () => {
      $helpTooltip = $('#help-tooltip', aParent);

      if (!$helpTooltip.length) {
        // create it
        $helpTooltip = $(JST['templates/tooltip_large']());
        $helpTooltip.css({ visiblity: 'hidden', opacity: 0 });
        aParent.append($helpTooltip);
      }
    };

    $('[data-toggle="help-tooltip"]', aParent).each((i, item) => {
      $(this).hover(function () {
        //
        // If tooltips have been dynamically disabled
        // on this element after the event has been attached,
        // just return
        //
        if ($(item).data('toggle') !== 'help-tooltip')
          return;

        //
        // First see if this element specifies a help tooltip ID
        // (for html tooltips)
        //
        if ($(item).data('tooltip-id')) {
          $helpTooltip = $('#' + $(item).data('tooltip-id'));

          if (!$helpTooltip) {
            console.warn('tooltip id "' + $(item).data('tooltip-id') + '" specified, but no tooltip found ');
            return;
          }
        } else {
          //
          // If not then use or create the standard one
          //
          if (!$helpTooltip || !$helpTooltip.legnth)
            _createTooltip();

          $helpTooltip.find('.help-tooltip__title').text($(item).attr('title'));
          $helpTooltip.find('.help-tooltip__description').text($(item).data().description);
        }

        //
        // Positioning and display
        //
        const $helpArrow = $helpTooltip.find('.help-tooltip__arrow');

        $helpTooltip.parent().css('position', 'relative');

        const left = $(item).position().left - 35 + ($(item).width() / 2);
        const top = $(item).position().top + $(item).outerHeight(true) + 15;

        $helpArrow.css({ left });
        $helpTooltip.css({ top });

        TweenLite.to($helpTooltip, 0.5, { autoAlpha: 1 });
      }, () => TweenLite.to($helpTooltip, 0.5, { autoAlpha: 0 }))
    });
  },

  //
  // set up jquery UI sliders
  // expects a min and max attribute
  //
  initSliders($container) {
    $('.slider', $container).each((idx, ele) => {
      const $ele = $(ele);
      const min = parseInt($ele.attr('min'), 10) || 0;
      const max = parseInt($ele.attr('max'), 10) || 100;
      const step = parseInt($ele.data('step'), 10) || 1;

      //
      //assume there is a hidden field sibling
      //
      const $updateEle = $($(ele.data('target')));

      if (!$updateEle.length) {
        $updateEle = $ele.siblings('input[type="hidden"]');

        if (!$updateEle.length)
          console.warn(
            `Attempting to initialize slider for ${$ele} but no target found`
          );
      }

      $ele.slider({
        min: min,
        max: max,
        step: step,

        change: (evt, ui) => {
          $updateEle.val(ui.value);

          //
          // bold the label... for fun!
          //
          $('span', $(item).siblings('.slider-label')).each((idx, ele) => {
            if (parseInt($ele.text(), 10) === ui.value) {
              $ele.addClass('strong');
            } else {
              $ele.removeClass('strong');
            }
          });
        }
      });

      //
      //attach our labels
      //
      const label = $ele.siblings('.slider-label');
      const labelWidth = $ele.width() / (max - min) - 1;

      for (const i = min; i <= max; i++) {
        label.append(`<span style="width: ${labelWidth}px">${i}</span>`);
      }
    });
  },

  initQuillEditors($container) {
    $('.quill', $container).each((idx, ele) => {
      const className = ele.dataset.classname;
      const toolbarType = ele.dataset.toolbartype;
      const value = document.getElementById(className).value

      new Kadenze.Quill().init(
        ele,
        value,
        className,
        {
          toolbarType
        }
      );
    });
  },

  highlightCode($container) {
    $('code', $container).each((idx, ele) => Prism.highlightElement(ele))
  },

  initDropzoneConfig() {
    Dropzone.prototype.addMockFile = function (file) {
      file.upload = {
        progress: 0,
        total: file.size,
        bytesSent: 0
      };

      this.files.push(file);

      file.status = Dropzone.ADDED;

      this.emit('addedfile', file);

      this.emit('success', file, {
        files: [file]
      });

      if (file.thumb)
        this.emit('thumbnail', file, file.thumb);

      this.emit('complete', file);
    };

    Dropzone.prototype.isValid = function () {
      return !this.getRejectedFiles().length
    };
  },

  initConfirmDelete() {
    $.rails.allowAction = link => {
      if (link.data("confirm") == undefined)
        return true;

      Kadenze.UIUtil.confirmAction(link)

      return false;
    }

    $.rails.deleteConfirmed = link => Kadenze.Ajax.delete({ $element: link })

    $('form[data-kconfirm]').on('click.confirm', evt => {
      const $ele = $(evt.target)
      Kadenze.UIUtil.confirmAction($ele.data('kconfirm'), () => $ele.submit())
      return false
    })
  },

  initProgressBar() {
    if (!$('.kl-main__content').length)
      return;

    const startClickProgress = e => {
      if (!e.ctrlKey && !e.shiftKey && !e.altKey && !e.metaKey) {
        NProgress.start();
      }
    };

    //
    // NProgress progress bar
    //
    NProgress.configure({
      showSpinner: false,
      parent: '.kl-main__content',
      trickleRate: 0.02,
      trickleSpeed: 400
    });

    $('body').on(
      'click',
      'a[data-skip-pjax="true"]:not([data-skip-loader])',
      startClickProgress
    );

    $(document).ready(NProgress.done);
    $(document).on('pjax:start', NProgress.start);
    $(document).on('pjax:end', NProgress.done);
  },

  initFileBoxes() {
    const $boxes = $('.js-kadenze-file');

    if (!$boxes.length)
      return;

    $boxes.each((idx, box) => {
      const $box = $(box);
      const opts = {};

      const boxData = $box.data('file-json');

      if ($box.data('file-template').trim())
        opts['template'] = $box.data('file-template');

      const fileObj = new Kadenze.GenericFileViewModel(boxData, $box, opts);

      fileObj.render();
    });
  },

  //
  // Globally init tooltips based on the '.js-tooltip' class
  //
  initKadenzeTooltips(selector = '.js-tooltip') {
    const $tips = this.$container ?
      this.$container.find(selector) :
      $(selector);

    //
    // Clear all tooltips,
    // necessary between pjax requests
    //
    $('.kadenze-tooltip').remove();

    $tips.each((idx, item) => {
      const $this = $(item);

      //
      // If we've already instantiated, don't do it again.
      // See scripts/common/tooltip.js for full documentation on options
      //
      if (!$this.data('tooltip'))
        new Kadenze.Tooltip({ target: item });
    });
  },

  initMobileAppLogin() {
    if (Kadenze.UIUtil.isMobileOrTablet())
      $(document).ready(() => new Kadenze.KdForms().init())
  }
}

export default WebApp
