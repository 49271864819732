export default {
  init: function() {
    let self = this;
    $(document).ready(function() {
      self.initTabs();
    });
  },
  initTabs: function() {
    var tab_link = 'a.kd-tab__tab-link';
    $(document).off('keyup.kd-tab__tab-link').on('keyup.kd-tab__tab-link', tab_link, function(e) {
      if (e.which === 9) {
        var $this = $(this);
        $this.addClass('tabbed');
        $this.one('blur', function(e) {
          $this.removeClass('tabbed');
        });
        return;
      }
    });
  }
};


