import Modal from './../../components/modal';

export default function(params) {
  this.defaults = {
    friendlyName: 'Open In Modal',
    template: 'iframe-preview',
  }

  this.config = Object.assign({}, this.defaults, params)

  this.friendlyName = this.config.friendlyName

  this.handler = event => {
    event.preventDefault()
    event.stopPropagation()
    event.stopImmediatePropagation()

    if(!this.$modal)
      this.buildModal()

    this.$modal.modal('show')
  }

  this.buildModal = () => {
    this.$modal = Modal.for(this.config.template, {
      title: 'Preview',
      iframeUrl: this.config.$iframe.prop('src'),
      btnText: 'Close',
      autoShow: false,
    });
  }

  return this
}
