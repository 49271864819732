let Chart = {};

Chart.SimpleGradeChart = function( holderDiv, grade, color ) {
  var w = $(holderDiv).width();
  var h = w;
  var radius = ( w  * 0.5 );
  var graphic = this.graphic = {};
  var numFontSize = (w * 0.25);
  this.chartHolder = holderDiv;
  this.value = Math.round(Number(grade));
  this.currentValue = 0;
  this.tween;

  // creates svg inside div
  var svgwrapper = d3.select( this.chartHolder ).append('svg')
    .attr('viewBox', '0 0 ' + w + ' ' + h )
    .attr('preserveAspectRatio', 'xMidYMid meet');

  graphic.ring = svgwrapper.append('svg')
    .attr('x', 0 )
    .attr( 'width', w )
    .attr( 'height', h );
  graphic.rect = graphic.ring.append('rect')
    .attr( 'width', w )
    .attr( 'height', h )
    .attr( 'fill', '#FFFFFF')
    .attr( 'opacity', '0');
  graphic.outerCircle = graphic.ring.append('circle')
    .attr( 'r', radius )
    .attr( 'fill', '#ebebeb')
    .attr('transform','translate(' + (w / 2) + ',' + (h * 0.5) + ')' );
  graphic.innerCircle = graphic.ring.append('circle')
    .attr( 'r', radius*.8 )
    .attr( 'fill', '#fff')
    .attr('transform','translate(' + (w / 2) + ',' + (h * 0.5) + ')' );
  // ring graphic
  graphic.arc = d3.svg.arc().innerRadius( radius * 0.8 ).outerRadius( radius * 1 ).startAngle( 0 );
  graphic.arcPath = graphic.ring.append('path')
    .attr('fill', color )
    .attr('d', graphic.arc.endAngle( (Math.PI * 2) * ( 0/100) ))
    .attr('transform','translate(' + (w / 2) + ',' + (h * 0.5) + ')' );
  // num inside ring
  graphic.percentText = graphic.ring.append('text')
    .attr('text-anchor', 'middle')
    .attr('fill', color )
    .style('font-size', numFontSize+'px' )
    .style('font-weight', 300 )
    .text( 0 + '%' )
    .attr('dx', (w / 2) )
    .attr('dy', (h * 0.5) + (numFontSize * 0.35) )
    .attr('transform','translate(0,0)');
  this.show();
  return this;
};

Chart.SimpleGradeChart.prototype.show = function( animateTime, delayTime ){
  var percentText = this.graphic.percentText;
  var arc = this.graphic.arc;
  var arcPath = this.graphic.arcPath;
  var self = this;

  this.tween = TweenLite.to( self, animateTime, {
    currentValue: self.value,
    delay: delayTime,
    onComplete: function() {
      var percent = Math.round( self.value );
      percentText.text( percent+'%' );
      arcPath.attr('d', arc.endAngle( (Math.PI * 2) * ( percent/100) ));
    },
    onUpdate: function () {
      var percent = Math.round( self.currentValue );
      percentText.text( percent+'%' );
      arcPath.attr('d', arc.endAngle( (Math.PI * 2) * ( percent/100) ));
    }
  } );
};



// RING PERCENTAGE CHART

Chart.PRing = function( svgwrapper, w, x, val, title, perColor, titleColor, scrolling ){
  var w = w;
  var h = w;
  var numFontSize = (scrolling)?( w * 0.2 ):( w * 0.2 );
  var labelFontSize = (scrolling)?( h * 0.05):( h * 0.09 );
  var graphic = this.graphic = {};
  this.value = Math.round(val);
  this.currentValue = 0;
  this.tween;

  var ringW = w*0.8;
  var r = ( ringW  * 0.5 );

  graphic.ring = svgwrapper.append('svg')
    .attr('x', x )
    .attr( 'width', w )
    .attr( 'height', h );
  graphic.rect = graphic.ring.append('rect')
    .attr( 'width', w )
    .attr( 'height', h )
    .attr( 'fill', '#FFFFFF')
    .attr( 'opacity', '0');
  graphic.outerCircle = graphic.ring.append('circle')
    .attr( 'r', r*.8 )
    .attr( 'fill', '#ebebeb')
    .attr('transform','translate(' + (w / 2) + ',' + (h * 0.5) + ')' );
  graphic.innerCircle = graphic.ring.append('circle')
    .attr( 'r', r*.7 )
    .attr( 'fill', '#fff')
    .attr('transform','translate(' + (w / 2) + ',' + (h * 0.5) + ')' );
  // ring graphic
  graphic.arc = d3.svg.arc().innerRadius( r * 0.7 ).outerRadius( r * 0.8 ).startAngle( 0 );
  graphic.arcPath = graphic.ring.append('path')
    .attr('fill', perColor )
    .attr('d', graphic.arc.endAngle( (Math.PI * 2) * ( 0/100) ))
    .attr('transform','translate(' + (w / 2) + ',' + (h * 0.5) + ')' );
  // num inside ring
  graphic.percentText = graphic.ring.append('text')
    .attr('text-anchor', 'middle')
    .attr('fill', perColor )
    .style('font-size', numFontSize+'px' )
    .style('font-weight', 700 )
    .text( 0 + '%' )
    .attr('dx', (w / 2) )
    .attr('dy', (h * 0.5) + (numFontSize * 0.35) )
    .attr('transform','translate(0,0)');
  // text label above ring
  graphic.titleText = graphic.ring.append('text')
    .text( title )
    .attr('text-anchor', 'middle')
    .attr('class', 'circle-percent__title')
    .attr('fill', titleColor)
    .attr('dx', (w / 2) )
    .attr('dy', labelFontSize )
    .style('font-size', labelFontSize+'px' );

  return this;
};

Chart.PRing.prototype = {
  get x(){
    return this.graphic.ring.attr('x');
  },
  set x(val){
    this.graphic.ring.attr( 'x', val );
  },
  get width(){
    return w;
  }

};


Chart.PRing.prototype.show = function( animateTime, delayTime ){
  var self = this;

  //    console.log( "self.graphic.percentText", self.graphic.percentText );
  //    console.log( "self.graphic.percentText", d3.select(self.graphic.percentText) );

  var percentText = self.graphic.percentText;
  var arc = self.graphic.arc;
  var arcPath = self.graphic.arcPath;

  this.tween = TweenLite.to( self, animateTime, {
    currentValue: self.value,
    delay: delayTime,
    onComplete: function() {
      var percent = Math.round( self.value );
      percentText.text( percent+'%' );
      arcPath.attr('d', arc.endAngle( (Math.PI * 2) * ( percent/100) ));
    },
    onUpdate: function () {
      var percent = Math.round( self.currentValue );
      percentText.text( percent+'%' );
      arcPath.attr('d', arc.endAngle( (Math.PI * 2) * ( percent/100) ));
    }
  } );
};

Chart.PercentageRings = function( svgHolder, $rings, scrolling, displayNum ) {
  var self = this;
  this.chartHolder = svgHolder;
  this.scrolling = scrolling;
  this.displayNum = displayNum;
  this.arrCharts = [];
  this.numRingsWide = (this.scrolling) ? 2 : this.displayNum | $rings.length;
  this.utils = Chart.PercentageRings.Utils;
  this.ringWidth;
  this.animateToX;
  this.leftArrow;
  this.rightArrow;

  var tween;

  var chartW = $(this.chartHolder).width();
  var w = this.ringWidth = Math.floor( chartW / this.numRingsWide );
  var h = w;
  var r = ( w  * 0.5 );
  var p = Math.PI * 2;

  var startX = 0;

  // grab data from rings
  var ringObjs = this.utils.convertRingsToObj($rings);

  console.log( 'this.chartHolder', this.chartHolder);

  if( scrolling ) {
    startX = this.animateToX = (-w * 1.5);
    var lastClone = ringObjs.slice( ringObjs.length -1 )[0];
    var last2ndClone = ringObjs.slice( ringObjs.length -2 )[0];
    var firstClone = ringObjs.slice( 0, 1 )[0];
    var secondClone = ringObjs.slice( 1, 2 )[0];
    ringObjs.unshift( last2ndClone, lastClone );
    ringObjs.push( firstClone, secondClone );

    var leftArrow = $('<a href=\'javascript:void(0);\' class=\'svg-holder__arrow svg-holder__arrow--left\'>' +
            '<i class=\'kdnze-arrow-left\'></a>');
    $(svgHolder).append(leftArrow);
    leftArrow.on('click', function(){
      self.prevChart();
    } );

    var rightArrow = $('<a href=\'javascript:void(0);\' class=\'svg-holder__arrow svg-holder__arrow--right\'>' +
            '<i class=\'kdnze-arrow-right\'></a>');
    $(svgHolder).append(rightArrow);
    rightArrow.on('click', function(){
      self.nextChart();
    } );
  }

  var svgwrapper = d3.select( this.chartHolder ).append('svg')
    .attr('viewBox', '0 0 ' + chartW + ' ' + h )
    .attr('preserveAspectRatio', 'xMidYMid meet');

  for( var i= -1 ; ++i < ringObjs.length; ){
    var ringObj = ringObjs[i];
    if (typeof ringObj == 'undefined')
      return;

    var x = ( i * this.ringWidth ) + startX;
    var ring = new Chart.PRing( svgwrapper, w, x, ringObj.totalVal, ringObj.title, ringObj.color, ringObj.titleColor, scrolling );
    ring.show( 2.0, 1.5 );

    this.arrCharts.push(ring);
  }
  $(this.chartHolder).height( h );

  //    console.log( "this.arrCharts: ", this.arrCharts );

  return this;
};

Chart.PercentageRings.Utils = {
  convertRingsToObj : function( $rings ) {
    var ringObjs = [];
    for(var i=-1; ++i<$rings.length;) {
      var ring = $rings[i];
      var obj = {
        val: 0,
        totalVal: parseFloat($(ring).find('.circle-percent__value').text()),
        color: $(ring).find('.circle-percent__value').css('color'),
        title: $(ring).find('.circle-percent__title').text(),
        titleColor: $(ring).find('.circle-percent__title').css('color')
      };
      ringObjs.push(obj);
    }
    return ringObjs;
  }
};

Chart.PercentageRings.prototype.resize = function( ){
  var containerW = $(this.chartHolder).width();
  var newWidth = Math.floor( containerW / this.numRingsWide );
  var newHeight = newWidth;
  $(this.chartHolder).height( newHeight );

  $('.svg-holder__arrow').each( function() {
    $(this).height(newHeight);
    var h = Math.floor((newHeight-36)/2);
    $(this).find('i').css('top', h + 'px');
  });
};

Chart.PercentageRings.prototype.nextChart = function( ){
  var i, shiftXOffset, ring,
    lastRing = this.arrCharts[ this.arrCharts.length -2 ];

  if( lastRing.x <= (this.ringWidth * 0.5) ) {
    shiftXOffset = ((this.arrCharts.length - 4) * this.ringWidth);
    for (i = -1; ++i < this.arrCharts.length;) {
      ring = this.arrCharts[i];
      ring.x = shiftXOffset + Number( ring.x );
    }
    this.animateToX += shiftXOffset;
  }

  this.animateToX -= this.ringWidth;

  for( i= -1 ; ++i < this.arrCharts.length; ){
    ring = this.arrCharts[i];
    var nX = this.animateToX + ( this.ringWidth * i );
    var t = TweenLite.to(ring, 0.3, { x: nX } );
  }
};

Chart.PercentageRings.prototype.prevChart = function( ){
  var i, shiftXOffset, ring,
    firstRing = this.arrCharts[2];

  if( firstRing.x >= (this.ringWidth * 0.5) ) {
    shiftXOffset = ((this.arrCharts.length - 4) * -this.ringWidth);
    for (i = -1; ++i < this.arrCharts.length;) {
      ring = this.arrCharts[i];
      ring.x = shiftXOffset + Number(ring.x);
    }
    this.animateToX += shiftXOffset;
  }

  this.animateToX += this.ringWidth;

  for( i= -1 ; ++i < this.arrCharts.length; ){
    ring = this.arrCharts[i];
    var nX = this .animateToX + ( this.ringWidth * i );
    var t = TweenLite.to(ring, 0.3, { x: nX } );
  }
};

Chart.StatComparisonTable = function( $table ){
  var self = this;
  var tween;


  var animateScroll = function () {
    self.checkBtnState();
    var currentLeft = $(vars.innerWrapper).scrollLeft();
    var newLeft = (vars.currentCol * vars.colWidth);
    var time = Math.abs( newLeft - currentLeft ) * 0.0015;
    tween = TweenLite.to( $(vars.innerWrapper) ,time, { scrollLeft:  newLeft });
  };

  self.checkBtnState = function(){
    leftArrow.removeClass('inactive');
    rightArrow.removeClass('inactive');

    if( vars.currentCol >= ( vars.$cols.length - vars.numVisible)  )
      rightArrow.addClass('inactive');
    if( vars.currentCol <= 0 )
      leftArrow.addClass('inactive');
  };

  $(window).on('resize', function(){
    self.resize();
  });

  var vars = this.vars = {
    currentCol : 0,
    $table : $table,
    innerWrapper : $table.find('.stat-table__inner-wrap')
  };

  var leftArrow = $table.find('.stat-table__arrow.left');
  var rightArrow = $table.find('.stat-table__arrow.right');

  leftArrow.on('click', function(){
    vars.currentCol = Math.max( vars.currentCol - vars.numVisible, 0 );
    animateScroll();
  });

  rightArrow.on('click', function() {
    vars.currentCol = Math.min( vars.currentCol + vars.numVisible, vars.$cols.length - vars.numVisible );
    animateScroll();
  });

  self.evaluateData();
  self.checkBtnState();

  return this;
};

Chart.StatComparisonTable.prototype.evaluateData = function() {
  if (this.vars.$table) {
    this.vars.$cols = $(this.vars.$table.find('.stat-table__row')[0]).find('.stat-table__td');
    this.vars.$row = $(this.vars.$table.find('.stat-table__row')[0]);
  }
  this.resize();
};

Chart.StatComparisonTable.prototype.resize = function(){
  var vars = this.vars;
  var minSize = 60;

  this.vars.$cols.each( function(){
    $(this).css( 'min-width', minSize );
  });

  vars.colWidth = vars.$row.width() / vars.$cols.length;
  vars.visibleArea = vars.innerWrapper.width();
  vars.numVisible = Math.floor( vars.visibleArea/ vars.colWidth );
  vars.colWidth = vars.visibleArea / vars.numVisible;

  this.vars.$cols.each( function(){
    $(this).css( 'min-width', vars.colWidth );
  });

  $(vars.innerWrapper).scrollLeft( (vars.currentCol * vars.colWidth) + 1);

  this.checkBtnState();
};



// new code for d3pie ----------------------------- //

Chart.RingChart = function(svgHolder, data, chartTitle, chartSubtitle){

  this.data = data;
  this.$holder = $(svgHolder);

  this.chartTitle = chartTitle;
  this.chartSubtitle = chartSubtitle;

  this.draw(true);

  var self = this;

  //TODO: @Tim remove this recreated debounce and just use underscore's _.debounce
  var debounce = function(fn, timeout) {
    var timeoutID = -1;
    return function() {
      if (timeoutID > -1) {
        window.clearTimeout(timeoutID);
      }
      timeoutID = window.setTimeout(fn, timeout);
    };
  };

  this.debounced_draw = debounce(function() {
    self.draw();
  }, 125);

  // Triggering the refresh event is being handled in the js file that calls RingChart.draw()
  // This is just here for reference
  // d3.select(window).on('resize', function(){
  //     self.debounced_draw();
  // });

};

Chart.RingChart.prototype.draw = function(isAnimated){

  var data = this.data,
    $holder = this.$holder,
    chartTitle = this.chartTitle,
    chartSubtitle = this.chartSubtitle,
    displayTotal = this.displayTotal,
    width = $holder.width(),
    height = $holder.height(),
    pieInnerRadius = '75%',
    pieOuterRadius = '90%',
    fontSize = 12,
    titleFontSize = 16,
    subtitleFontSize = 14,
    labelDistance = 30;

  if (!$holder[0])
    return;

  if(height < 300){
    pieInnerRadius = '75%';
    pieOuterRadius = '90%';
    fontSize = 10;
    titleFontSize = 12;
    subtitleFontSize = 8;
    labelDistance = 10;
  }
  if(width < 400){
    labelDistance = 10;
    pieOuterRadius = '80%';
  }

  $holder.empty();

  var dataContent = [];

  for (var i = 0; i < data.length; i++) {
    dataContent.push({
      label: data[i].label,
      value: data[i].percent,
      color: data[i].color,
      href: data[i].href
    });
  }

  var pie = new d3pie($holder[0], {
    header: {
      title: {
        text: chartTitle,
        fontSize: titleFontSize
      },
      subtitle: {
        text: chartSubtitle,
        fontSize: subtitleFontSize
      },
      location: 'pie-center'
    },
    size: {
      canvasHeight: height,
      canvasWidth: width,
      pieInnerRadius: pieInnerRadius,
      pieOuterRadius: pieOuterRadius
    },
    effects: {
      pullOutSegmentOnClick: {
        effect: 'none'
      },
      highlightLuminosity: 0.1
    },
    labels: {
      mainLabel: {
        fontSize: fontSize,
        color: '#4b5253'
      },
      percentage: {
        fontSize: fontSize,
        color: '#fff'
      },
      lines: {
        style: 'straight',
        color: '#4b5253'
      },
      outer: {
        pieDistance: labelDistance,
      }
    },
    data: {
      sortOrder: 'value-desc',
      content: dataContent
    },
    callbacks: {
      onClickSegment: function(segObj) {
        var name = segObj.data.href.slice(1);
        var $tgt = $('a[name="' + name + '"]');
        if ($tgt.length > 0) {
          $('html,body').animate({
            scrollTop: $tgt.offset().top
          }, 500, 'easeOutCubic');
        }

      },
      onMouseoverSegment: function(segObj) {
        $(segObj.segment).css('cursor', 'pointer');
        $('.p1_labelGroup-inner[data-index="' + segObj.index + '"]').css('cursor', 'pointer');
      },
    }
  });

};

Chart.RingChart.prototype.refresh = function(){
  this.debounced_draw();
};

// end code for d3pie ----------------------------- //



Chart.GradeBreakdown = function(svgHolder, data, displayTotal, chartTitle ){
  // displayTotal should be false for survey ring chart
  // data should have a title
  // labelText "% of total"
  this.data = data;
  this.$holder = $(svgHolder);
  this.svgHolder = svgHolder;

  if(!chartTitle){
    chartTitle = 'Course Total Grade:';
  }

  this.chartTitle = chartTitle;
  this.displayTotal = displayTotal;
  this.draw(true);

  var self = this;

  var debounce = function(fn, timeout) {
    var timeoutID = -1;
    return function() {
      if (timeoutID > -1) {
        window.clearTimeout(timeoutID);
      }
      timeoutID = window.setTimeout(fn, timeout);
    };
  };

  this.debounced_draw = debounce(function() {
    self.draw();
  }, 125);

  d3.select(window).on('resize', function(){
    self.debounced_draw();
  });

};


Chart.GradeBreakdown.prototype.draw = function( isAnimated ){
  var $holder = this.$holder;
  var data = this.data;
  var svgHolder = this.svgHolder;
  var chartTitle = this.chartTitle;
  var displayTotal = this.displayTotal;

  $holder.empty();

  // Size Vars
  var width = $holder.outerWidth(),
    height = $holder.outerHeight(),
    radius = ( Math.min(width, height) / 2 );

    // Text Vars
  var centerTitle = chartTitle,
    labelAppend = (width > 500)? '% of Total': '%';

  var centerTitleFontSize = parseInt( Math.min( (radius * 0.2) , 24 )),
    labelFontSize = parseInt(((width-radius)*0.55) / (labelAppend.length + 12));

  /*
 Unused grades: 100% of Total
*/
    // Animation vars
  var animeSpeed = (isAnimated)?500:0,
    animeDelay = (isAnimated)?500:0;

  var arc = d3.svg.arc().outerRadius(radius - (radius*0.2)).innerRadius(radius);
  var labelMargin = parseInt(height * 0.02);

  var pie = d3.layout.pie()
    .sort(null)
    .startAngle(0*Math.PI)
    .endAngle(2*Math.PI)
    .value(function(d) { return d.percent; });

  var svg = d3.select(svgHolder).append('svg')
    .attr('id', 'chart')
    .attr('width', width)
    .attr('height', height)
    .attr('perserveAspectRatio', 'xMinYMid')
    .append('g')
    .attr('transform', 'translate(' + width / 2 + ',' + height / 2 + ')');


  var g = svg.selectAll('.arc')
    .data(pie(data))
    .enter()
    .append('g')
    .attr('class', 'arc')
    .style('fill', function(d) { return d.data.color; })
    .append('path')
    .transition().delay(function(d, i) { return i * animeDelay; }).duration(animeSpeed)
    .attrTween('d', function(d) {
      var i = d3.interpolate(d.startAngle, d.endAngle);
      return function(t) {
        d.endAngle = i(t);
        return arc(d);
      };
    });

  var labels = svg.selectAll('text.breakdown-label')
    .data( pie(data) )
    .enter()
    .append('svg:text')
    .attr('class', 'breakdown-label')
    .attr('dy','.35em')
    .attr('transform', function(d) {
      var pos = arc.centroid(d);
      pos[0] = (pos[0] > 0)?(radius+labelMargin):(-labelMargin-radius);
      return 'translate(' + pos + ')';
    })
    .style('text-anchor', function(d) {
      return (arc.centroid(d)[0] > 0)?'start':'end';
    });


  labels.append('tspan')
    .style('font-weight','700')
    .style( 'font-size', labelFontSize + 'px' )
    .text(function(d) {
      return d.data.label + ': ';
    })
    .attr('dy','.35em');

  labels.append('tspan')
    .style( 'font-size', labelFontSize + 'px' )
    .text(function(d) {
      return d.data.percent + labelAppend;
    });

  var lines = svg.selectAll('line.breakdown-line')
    .data( pie(data) )
    .enter()
    .append('line')
    .attr('x1', function(d) {return arc.centroid(d)[0];})
    .attr('y1', function(d) {return arc.centroid(d)[1];})
    .attr('x2', function(d) {
      var pos = arc.centroid(d);
      pos[0] = (pos[0] > 0)?(radius):(-radius);
      return pos[0];
    })
    .attr('y2', function(d) {return arc.centroid(d)[1];})
    .attr('class','breakdown-line');

  svg.append('svg:text')
    .attr('class', 'breakdown__total_title')
    .style('text-anchor', 'middle')
    .attr('dy','-0.25em')
    .text(chartTitle)
    .style( 'font-size', centerTitleFontSize + 'px' )
    .call(Chart.wrap, (radius*1.5));

  if (displayTotal) {
    svg.append('svg:text')
      .attr('class', 'breakdown__total_value')
      .style('text-anchor', 'middle')
      .attr('dy','.35em')
      .attr('transform', function() {
        return 'translate( 0 '+ (radius*0.2) +' )';
      })
      .text( function(){
        var total=0;
        for (var i=0; i<data.length; i++){
          var obj=data[i];
          if(obj.label!='Unused Grades')
            total+= Number(obj.percent);
        }
        return total+'%';
      })
      .style('font-size', function() { return Math.min(2 * radius, (2 * radius - 8) / this.getComputedTextLength() * 24) + 'px'; })
      .attr('dy', '.35em');
  }
};

Chart.GradeBreakdown.prototype.refresh = function(){
  // TODO: we'll take out the d3.select(window) onresize out of the initial gradebreakdown and have to add the functionality to coursework-table.js
  this.debounced_draw();
};


Chart.BarChart = function( svgHolder, xlabel, ylabel, data, enableResize ){

  this.svgHolder = svgHolder;
  this.xlabel = xlabel;
  this.ylabel = ylabel;
  this.data = data;
  this.enableResize = enableResize;
  this.draw();

  var self = this;

  var debounce = function(fn, timeout) {
    var timeoutID = -1;
    return function() {
      if (timeoutID > -1) {
        window.clearTimeout(timeoutID);
      }
      timeoutID = window.setTimeout(fn, timeout);
    };
  };

  this.debounced_draw = debounce(function() {
    self.draw();
  }, 300);

  if(enableResize){
    d3.select(window).on('resize', function(){
      self.debounced_draw();
    });
  }
};

Chart.BarChart.prototype.refresh = function () {
  this.debounced_draw();
};

Chart.BarChart.prototype.draw = function () {

  //TODO: draw lines after you draw bars

  // various size vars
  var svgHolder = this.svgHolder,
    xlabel = this.xlabel,
    ylabel = this.ylabel,
    data = this.data,
    containerW = $(svgHolder).outerWidth(),
    barWidthRatio = 0.6,    // smaller number = less white space between bars
    chartHeight = 400,
    yLabelWrapWidth = 100;

  // remove the existing svg element, otherwise the chart will be duplicated
  $('svg', svgHolder).remove();

  // margin vars
  var margins = {
    top: 10, right: (containerW * 0.25), bottom: 50, left: (containerW * 0.25)
  };

    // responsive breakpoint
  if(containerW < 800) {
    margins.right = 0;
    margins.left = 75;
    barWidthRatio = 0.5;
    chartHeight = 200;
    yLabelWrapWidth = 50;
  }

  // text vars
  var yAxisLabelFontSize = parseInt( yLabelWrapWidth * 0.24 );

  // width vars
  var width = containerW - margins.left - margins.right,
    height = chartHeight - margins.top - margins.bottom;
  var barWidth; // `width` is calculated by using `width` / `count of bars`

  // setting the ordinal scale
  var x = d3.scale.ordinal()
    .rangeRoundBands([0, width], barWidthRatio) // chagne the ratio of bar to white space by changing the decimal value after '[0, width],'
    .domain(data.map(function(d) { return d.name; }));

    // Since svg define its origin at `top left`
    // for this bar chart, the bigger the `y` value the smaller the `y` value is
    // that why the `range` is inverted
  var y = d3.scale.linear()
    .range([height, 0])
    .domain([0, d3.max(data, function(v) {
      return v.value;
    })]);
  var chart = d3.select(svgHolder).append('svg').attr('width', width + margins.left + margins.right).attr('height', height + margins.top + margins.bottom)
    .append('g').attr('transform', 'translate(' + margins.left + ', ' + margins.top + ')');
  barWidth = width / data.length;

  chart.selectAll('.bar').data(data)
    .enter().append('rect')
    .attr('class', 'bar')
    .attr('x', function(d) { return x(d.name); })
    .attr('y', function(d) { return y(d.value); })
    .attr('height', function(d) { return height - y(d.value); })
    .attr('width', x.rangeBand());

  var xAxis = d3.svg.axis().scale(x).orient('bottom');
  var yAxis = d3.svg.axis().scale(y).orient('left').ticks(6);

  // append x axis
  chart.append('g').attr('class', 'x axis')
    .attr('transform', 'translate(0, ' + height + ')')
    .call(xAxis);

  // append y asix
  chart.append('g').attr('class', 'y axis').call(yAxis);

  // add the label below the x axis
  chart.append('text')
    .attr('class', 'x label')
    .attr('text-anchor', 'end')
    .attr('x', width / 2)
    .attr('y', height + 45)
    .text(xlabel);

  // add the label next to the y axis
  chart.append('text')
    .attr('class', 'y label')
    .attr('text-anchor', 'end')
    .attr('x', -30)
    .attr('y', height / 2)
    .attr('dy', 0)
    .text(ylabel)
    .style('font-size', yAxisLabelFontSize + 'px')
    .call(Chart.wrap, yLabelWrapWidth);

};

// function to wrap the text in the ylabel if necessary
Chart.wrap = function (text, width) {
  text.each(function() {
    var text = d3.select(this),
      words = text.text().split(/\s+/).reverse(),
      word,
      line = [],
      lineNumber = 0,
      lineHeight = 1.1, // ems
      y = text.attr('y'),
      x = text.attr('x'),
      dy = parseFloat(text.attr('dy')),
      tspan = text.text(null).append('tspan').attr('x', x).attr('y', y).attr('dy', dy + 'em');
    while (word = words.pop()) {
      line.push(word);
      tspan.text(line.join(' '));
      if (tspan.node().getComputedTextLength() > width) {
        line.pop();
        tspan.text(line.join(' '));
        line = [word];
        tspan = text.append('tspan').attr('x', x).attr('y', y).attr('dy', ++lineNumber * lineHeight + dy + 'em').text(word);
      }
    }
  });
};

Chart.GroupedBarChart = function( svgHolder, xlabel, ylabel, data, enableResize ){

  this.svgHolder = svgHolder;
  this.xlabel = xlabel;
  this.ylabel = ylabel;
  this.data = data;
  this.enableResize = enableResize;
  this.draw();

  var self = this;

  var debounce = function(fn, timeout) {
    var timeoutID = -1;
    return function() {
      if (timeoutID > -1) {
        window.clearTimeout(timeoutID);
      }
      timeoutID = window.setTimeout(fn, timeout);
    };
  };

  this.debounced_draw = debounce(function() {
    self.draw();
  }, 300);

  if(enableResize){
    d3.select(window).on('resize', function(){
      self.debounced_draw();
    });
  }
};

Chart.GroupedBarChart.prototype.refresh = function () {
  this.debounced_draw();
};

Chart.GroupedBarChart.prototype.wrap_label = function (text, width) {
  text.each(function() {
    var text = d3.select(this),
      words = text.text().split(/\s+/).reverse(),
      word,
      line = [],
      lineNumber = 0,
      lineHeight = 1.1, // ems
      y = text.attr('y'),
      dy = parseFloat(text.attr('dy')),
      tspan = text.text(null).append('tspan').attr('x', 0).attr('y', y).attr('dy', dy + 'em');
    while (word = words.pop()) {
      line.push(word);
      tspan.text(line.join(' '));
      if (tspan.node().getComputedTextLength() > width) {
        line.pop();
        tspan.text(line.join(' '));
        line = [word];
        tspan = text.append('tspan').attr('x', 0).attr('y', y).attr('dy', ++lineNumber * lineHeight + dy + 'em').text(word);
      }
    }
  });
};

Chart.GroupedBarChart.prototype.draw = function () {
  //Reference: http://bl.ocks.org/mbostock/3887051

  var svgHolder = this.svgHolder,
    xlabel = this.xlabel,
    ylabel = this.ylabel,
    data = this.data,
    containerW = $(svgHolder).outerWidth(),
    barWidthRatio = 0.6,    // smaller number = less white space between bars
    chartHeight = 400,
    yLabelWrapWidth = 100;

  // remove the existing svg element, otherwise the chart will be duplicated
  $('svg', svgHolder).remove();

  // margin vars
  var margins = {
    top: 10, right: (containerW * 0.25), bottom: 50, left: (containerW * 0.25)
  };

  // responsive breakpoint
  if(containerW < 800) {
    margins.right = 0;
    margins.left = 75;
    barWidthRatio = 0.5;
    chartHeight = 200;
    yLabelWrapWidth = 50;
  }

  // text vars
  var yAxisLabelFontSize = parseInt( yLabelWrapWidth * 0.24 );

  // width vars
  var width = containerW - margins.left - margins.right,
    height = chartHeight - margins.top - margins.bottom;
  var barWidth; // `width` is calculated by using `width` / `count of bars`

  var x0 = d3.scale.ordinal()
    .rangeRoundBands([0, width], .1);

  var x1 = d3.scale.ordinal();

  var y = d3.scale.linear()
    .range([height, 0]);

  var color = d3.scale.ordinal()
    .range(['#98abc5', '#8a89a6', '#7b6888', '#6b486b', '#a05d56', '#d0743c', '#ff8c00']);

  var xAxis = d3.svg.axis()
    .scale(x0)
    .orient('bottom');

  var yAxis = d3.svg.axis()
    .scale(y)
    .orient('left')
    .tickFormat(d3.format('d'));

  var svg = d3.select(svgHolder).append('svg')
    .attr('width', width + margins.left + margins.right)
    .attr('height', height + margins.top + margins.bottom)
    .append('g')
    .attr('transform', 'translate(' + margins.left + ',' + margins.top + ')');

  var columnNames = d3.keys(data[0]).filter(function(key) { return key !== 'row' && key !== 'cols'; });

  data.forEach(function(d) {
    d.cols = columnNames.map(function(name) { return {name: name, value: +d[name]}; });
  });

  x0.domain(data.map(function(d) { return d.row; }));
  x1.domain(columnNames).rangeRoundBands([0, x0.rangeBand()]);
  y.domain([0, d3.max(data, function(d) { return d3.max(d.cols, function(d) { return d.value; }); })]);

  svg.append('g')
    .attr('class', 'x axis')
    .attr('transform', 'translate(0,' + height + ')')
    .call(xAxis)
    .selectAll('.tick text')
    .call(this.wrap_label, x0.rangeBand());

  svg.append('g')
    .attr('class', 'y axis')
    .call(yAxis);

  svg.append('text')
    .attr('class', 'y label')
    .attr('text-anchor', 'end')
    .attr('x', -30)
    .attr('y', height / 2)
    .attr('dy', 0)
    .text(ylabel)
    .style('font-size', yAxisLabelFontSize + 'px')
    .call(Chart.wrap, yLabelWrapWidth);

  var row = svg.selectAll('.row')
    .data(data)
    .enter().append('g')
    .attr('class', 'g')
    .attr('transform', function(d) { return 'translate(' + x0(d.row) + ',0)'; });

  row.selectAll('rect')
    .data(function(d) { return d.cols; })
    .enter().append('rect')
    .attr('width', x1.rangeBand())
    .attr('x', function(d) { return x1(d.name); })
    .attr('y', function(d) { return y(d.value); })
    .attr('height', function(d) { return height - y(d.value); })
    .style('fill', function(d) { return color(d.name); });

  //Build a legend indicator
  var legend = svg.selectAll('.legend')
    .data(columnNames.slice())
    .enter().append('g')
    .attr('class', 'legend')
    .attr('transform', function(d, i) { return 'translate(0,' + i * 20 + ')'; });

  legend.append('rect')
    .attr('x', width)
    .attr('width', 18)
    .attr('height', 18)
    .style('fill', color);

  legend.append('text')
    .attr('x', width + 25)
    .attr('y', 9)
    .attr('dy', '.35em')
    .style('text-anchor', 'left')
    .text(function(d) { return d; });
};

export default Chart;
