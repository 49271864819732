export default {
  get (options) {
    if (!options.routeName)
      throw new Error('Missing required param `routeName`')

    const request = Kadenze.AjaxRequest(this._getRequestOptions(options, 'GET'))

    if (options.done)
      request.done(options.done)

    if (options.success)
      request.success(options.success)

    if (options.fail)
      request.fail(options.fail)

    return request
  },

  post (options) {
    if (!options.routeName)
      throw new Error('Missing required param `routeName`')

    const request = Kadenze.AjaxRequest(this._getRequestOptions(options, 'POST'))

    request.done((data, status, xhr) => {
      new Kadenze.Notice.success({ message: xhr.responseJSON.message });

      if (options.done)
        options.done(data, status, xhr)
    })

    if (options.success)
      request.success(options.success)

    request.fail(xhr => {
      const message = xhr?.responseJSON?.message || 'An unexpected error occurred'

      new Kadenze.Notice.error({ message });

      if (options.fail)
        options.fail(xhr)
    })

    return request
  },

  patch (options) {
    if (!options.routeName)
      throw new Error('Missing required param `routeName`')

    const request = Kadenze.AjaxRequest(this._getRequestOptions(options, 'PUT'))

    if (options.done)
      request.done(options.done)

    if (options.success)
      request.success(options.success)

    request.fail(xhr => {
      const message = xhr?.responseJSON?.message || 'An unexpected error occurred'

      new Kadenze.Notice.error({ message });

      if (options.fail)
        options.fail(xhr)
    })

    return request
  },

  delete (options) {
    options.url = this._getRoute(options)

    let url = options.url
    let $parent

    const $element = $(options.$element || options.el)

    if ($element) {
      url ||= $element.attr('href')
      $parent = options.$parent || $element.parents('tr, .js-delete-parent')
    }

    const request = Kadenze.AjaxRequest({
      url,
      type: 'DELETE',
      data: options.data || {},
    })

    if (options.success)
      request.success(options.success)

    request.done(data => {
      if ($parent)
        $parent.remove()

      new Kadenze.Notice.success({ message: data.message })

      if (options.done)
        options.done(data)

      if (data.redirect)
        Kadenze.Request.pjax({ url: data.redirect })
    })

    request.fail(data => {
      new Kadenze.Notice.error({ message: data.message })
    })

    if (options.always)
      request.always(options.always)
  },

  _getRequestOptions (options, type) {
    const url = this._getRoute(options)

    const requestOpts = { url, type }

    if (options.dataType)
      requestOpts.dataType = options.dataType

    if (options.dataAsJSON)
      requestOpts.dataAsJSON = options.dataAsJSON

    if (options.data)
      requestOpts.data = options.data

    if (options.loadingContainer)
      requestOpts.loadingIndicator = { container: options.loadingContainer }

    if (options.loadingIndicator)
      requestOpts.loadingIndicator = options.loadingIndicator

    if (options.timeout)
      requestOpts.timeout = options.timeout

    if (options.progressBar)
      requestOpts.progressBar = options.progressBar

    return requestOpts
  },

  _getRoute(options) {
    if (options.url) {
      return options.url
    } else if (options.routeName) {
      const routeParams = [].concat(options.routeParams)

      const routeName = options.routeName.match(/_(?:path|url)$/) ?
        options.routeName :
        `${options.routeName}_path`

      return Kadenze.Routes[routeName](...routeParams)
    } else {
      return;
    }
  },
}
