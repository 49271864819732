let AjaxRequestPool = function() {
  var self = this;
  self.reqs = [];
};

AjaxRequestPool.prototype = {
  add: function(req) {
    this.reqs.push(req);
  },
  abortAll: function () {
    _.each(this.reqs, function (req) {
      req.abort();
    });
    this.reqs = [];
  },
  inProgress: function () {
    return _.any(this.reqs, function (req) {
      return req.readyState != 4;
    });
  },
  onComplete: function (callback) {
    $.when.apply(null, this.reqs).done(callback);
  }
};

export default AjaxRequestPool;
