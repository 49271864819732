import Modal from './../components/modal'

var parentOrBody = function(parent) {
  return parent ? $(parent) : $('body');
};

export default {

  // Necessary for handling HTML5 history popstate with Pjax
  cleanupDynamicElements: function() {
    // Clean up dynamic DOM elements
    $('.cke').remove();
    $('.tr.dz-table-preview').remove();
    $('.dz-message, .dz-img__preview, .dz-message').remove();
    $('.modal').modal('hide');
    $('.bootstrap-select').remove();
    $('.tt-dropdown-menu').remove();
  },


  /*
   * Kadenze.UIUtil.showLoadingIndicator
   *
   * displays a loading indicator
   *
   * @parentObj {Jquery Object} - Parent holder for loader
   * @cb {function} - Call back function
   * @position {String} - 'top' || 'fixed'
   * @message {String} - message displayed under loader
   *
   */
  showLoadingIndicator (parent, onComplete, position, message) {
    position = position || 'fixed';
    message = message || I18n.t('dict.loading').capitalize_first();

    const $parent = parent && $(parent) || $('body');

    let $loader = $('> .ajax-overlay', $parent);

    if (!$loader.length) {
      $loader = $(JST['templates/loader']({ message }));
      $parent.append($loader);
    }

    //
    // For a long list, use position == 'top'
    // so user can see the loading indicator above the fold
    // Use 'fixed' to show loader over the whole page
    //
    if (position == 'top') {
      const top = $parent.height() / 2 * 0.3;
      $loader.find('.ajax-loader').css({ top });
    }

    if (position == 'fixed')
      $loader.css({ position });

    Kadenze.SvgIcon.init($loader[0].querySelector('.kdnze-svg-icon'));

    TweenLite.fromTo(
      $loader,
      .5,
      { autoAlpha: 0 },
      { autoAlpha: 1, onComplete }
    );
  },

  hideLoadingIndicator: function(parentObj, cb) {
    var $parentObj = parentOrBody(parentObj);
    var $target = $('> .ajax-overlay', $parentObj);
    TweenLite.to( $target, .5, { autoAlpha: 0, onComplete: cb } );
  },

  removeNotices: function(parentObj) {
    var $parentObj = parentOrBody(parentObj);
    $('.alert:not(.alert-persist), .notice-rails', $parentObj).fadeOut(500, function() {
      $(this).remove();
    });
  },

  removeNotice: function(notice) {
    $(notice).fadeOut(500, function() {
      $(notice).remove();
    });
  },

  showNotice: function(parentObj, data, timeout) {
    var msg;
    var data = data || {};

    if (_.deepHas(data, 'responseJSON.status') && _.deepHas(data, 'responseJSON.message')) {
      msg = {
        status: data.responseJSON.status,
        message: data.responseJSON.message
      };
    } else {
      msg = data;
    }

    if (msg.status == 'success') {
      Kadenze.onModuleLoaded('Notice', function() {
        new Kadenze.Notice.success({message: msg.message});
      });
    }
    else {
      Kadenze.onModuleLoaded('Notice', function() {
        new Kadenze.Notice.error({message: msg.message});
      });
    }

  },

  showTimeoutWarning: function(timeLeft) {
    let $modal = $('#timeout-dialog');

    if (!$modal.length) {
      $modal = Modal.for('timeout-warning');
      $modal.attr('id', 'timeout-dialog');
    }

    var $amt = $('.timeout-amt', $modal);

    $amt.text(timeLeft);

    var interval = setInterval(function() {
      var prev = parseInt($amt.text(), 10);
      var next = prev - 1;
      if (next <= 0) {
        clearInterval(interval);
        window.location = '/sign_out';
      }
      else {
        $amt.text(prev - 1);
      }
    }, 60 * 1000);

    $modal.on('hidden.bs.hide', function() {
      $modal.modal('hide');
    });
    $modal.find('.btn--del').on('click', function() {
      // redirect to sign out
      clearInterval(interval);
      window.location = '/sign_out';
    });
    $modal.find('.btn--add').on('click', function() {
      $modal.modal('hide');
      // send continue working ajax request
      $.ajax({
        url: Kadenze.Routes.reset_user_clock_path(),
        headers: {
          'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content')
        }
      }).done(function(data) {
        Kadenze.WebApp.checkTimeoutStatus();
      });
    });
  },

  showModal: function(aModalId, aContentTarget) {
    $('#' + aModalId).modal('show');
    this.showModalContent(aContentTarget);
  },

  showModalContent: function(target) {
    var $body = $('.modal-body');
    var $target = $('.' + target + '-content').show(function() {
      var $this = $(this);
      var isOpening = ($this.outerHeight()==0);

      if ($this.hasClass('inactive')) {
        $this.removeClass('inactive');
        $this.css( 'opacity' ,0 );

        var fadeDelay = (isOpening)? 1.0: 0.25;

        TweenLite.to( $this, 0.5, { delay:fadeDelay, css:{autoAlpha:1}});

        // if mContent is inside .modal-body
        if ($this.parent().is($body)) {
          if( isOpening ) {
            setTimeout(function() {
              TweenLite.to( $body, 0.5, { height:$this.outerHeight() });
            }, 500);
          } else {
            TweenLite.to( $body, 0.5, { height:$this.outerHeight() });
          }
        }
      }

    });

    $('.mContent').each(function() {
      var $oldTarget = $(this).not($target);
      if (!$oldTarget.hasClass('inactive')) {
        TweenLite.to( $oldTarget, 0.5, { css:{autoAlpha:0}});
        $oldTarget.addClass('inactive');
        //                    setTimeout(function() {
        //                        $oldTarget.hide();
        //                    }, 500);
      } else {
        TweenLite.to( $oldTarget, 0, { css:{autoAlpha:0}});
      }
    });
  },

  //For splitting ko.observableArray into rows/cols in the DOM
  splitRowsByCols: function(collection, colLength) {
    return ko.computed(function() { //To show two column rows
      var result = [],
        row;
      //Loop through items and push each item to a row array that gets pushed to the final result
      for (var i = 0, j = collection().length; i < j; i++) {
        if (i % colLength === 0) {
          if (row) {
            result.push(row);
          }
          row = [];
        }
        row.push(collection()[i]);
      }

      //Push the final row
      if (row) {
        result.push(row);
      }

      return result;
    }, this);
  },

  //For converting ko item into Rails friendly form data format
  prepareFormData: function(formData, parentKey, model) {
    for (var k in model) {
      if (model.hasOwnProperty(k)) {
        if (model[k] instanceof Array) {
          for (var i = 0; i < model[k].length; i++) {
            for (var key in model[k][i]) {
              if (typeof model[k][i][key] != 'undefined') {
                formData.append(parentKey+'['+k+']['+i+']['+key+']', model[k][i][key]);
              }
            }
          }
        } else if (model[k] instanceof Object) {
          for (var property in model[k]) {
            if (model[k].hasOwnProperty(property) && model[k][property]) {
              formData.append(parentKey+'['+k+']['+property+']', model[k][property]);
            }
          }
        } else {
          formData.append(parentKey+'['+k+']', model[k]);
        }
      }
    }
  },

  // TO DECPRECATE IN FAVOR OF FileTypeMapping CLASS
  iconClass: function(type) {
    var res = Kadenze.FILE_ICONS_MAP[type];
    return res || 'kdnze-file-pdf';
  },

  getFileTypeIcon: function( $fileTypes ) {
    var types = $fileTypes.split(',');
    var audioTypes = '.mp3 .ogg .aif .wav';
    var docTypes = '.pdf .doc .txt .csv';
    var codeTypes = '.js .html .css .rb';
    var arduinoTypes = '';
    var imgTypes = '.png .gif .svg. .jpg .jpeg .psd .ai';
    var threeDTypes = '';
    var compressionTypes = '.zip .rar';
    var reaktorTypes = '';
    var videoTypes = '.mpg .mp4 .fla';
    var chucKTypes = '';


    for( var i = -1; ++i < types.length; ) {
      var type = types[i];

      if( audioTypes.indexOf(type) >= 0 )
        return 'kdnze-file-audio';
      if( docTypes.indexOf(type) >= 0 )
        return 'kdnze-file-pdf';
      if( codeTypes.indexOf(type) >= 0 )
        return 'kdnze-file-code';
      if( arduinoTypes.indexOf(type) >= 0 )
        return 'kdnze-file-arduino';
      if( imgTypes.indexOf(type) >= 0 )
        return 'kdnze-file-image';
      if( threeDTypes.indexOf(type) >= 0 )
        return 'kdnze-file-3d';
      if( compressionTypes.indexOf(type) >= 0 )
        return 'kdnze-file-zip';
      if( reaktorTypes.indexOf(type) >= 0 )
        return 'kdnze-file-reaktor';
      if( videoTypes.indexOf(type) >= 0 )
        return 'kdnze-file-video';
      //        if( chucKTypes.indexOf(type) >= 0 )
      //            return "kdnze-file-chuck";
    }
    return 'kdnze-file-pdf';
  },

  confirmAction: (linkOrPrompt, confirmed, cancelled, opts) => {
    let body = typeof linkOrPrompt === 'string' ?
      linkOrPrompt :
      linkOrPrompt?.data('confirm')

    const link = typeof linkOrPrompt === 'string' ?
      null :
      linkOrPrompt

    const options = Object.assign({}, {
      title: I18n.t('modals.please_confirm'),
      body,
      cancel_btn_text: I18n.t('dict.no'),
      ok_btn_text: I18n.t('dict.yes')
    }, opts);

    const $modal = Modal.for('confirmation', options);

    $modal.on('hidden.bs.hide', () => $modal.modal('hide'))

    $modal.find('.js_cancel').on('click', () => {
      $modal.modal('hide')

      if (typeof cancelled == 'function')
        cancelled()
    })

    $modal.find('.js_ok').on('click', () => {
      $modal.modal('hide')

      if (link)
        $.rails.deleteConfirmed(link)

      if (typeof confirmed == 'function')
        confirmed()
    })
  },

  confirmNavigation: function(prompt, path, opts) {
    Kadenze.UIUtil.confirmAction( prompt, () => {
      window.location = path;
    }, null, opts);
  },

  displayMessage: function(title, message, options) {
    var dlg_options = { show: true };

    const $modal = Modal.for('message', {
      title: title,
      body: message,
      okBtnText: 'Ok',
      autoShow: false,
    });

    if (options && options.info_modal === true) {
      $.extend(dlg_options, {backdrop: 'static'});
      $modal.find('button').remove();
      $modal.find('a').remove();
    }

    $modal.modal(dlg_options);

    $modal.on('hidden.bs.hide', function() {
      $modal.modal('hide');
    });

    $modal.find('.btn--add').on('click', function() {
      $modal.modal('hide');
    });
  },

  makeRequest: function(url, type, data, $container, beforeSend, success, error, complete) {
    return $.ajax({
      type: type,
      url: url,
      data: JSON.stringify(data),
      dataType: 'json',
      contentType: 'application/json', //Why? http://goo.gl/YNwSUD
      timeout: 60000, // 1 minute
      headers: {
        'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content')
      },
      beforeSend: function(xhr, settings) {
        if ($container)
          Kadenze.UIUtil.showLoadingIndicator($container);
        if(typeof beforeSend === 'function') {
          beforeSend(xhr, settings);
        }
      },
      success: function(data, textStatus, xhr) {
        if(typeof success === 'function') {
          success(data, textStatus, xhr);
        }
      },
      error: function(xhr, textStatus, errorThrown) {
        console.log(errorThrown);
        if(typeof error === 'function') {
          error(xhr, textStatus, errorThrown);
        }
      },
      complete: function(xhr, textStatus) {
        if ($container)
          Kadenze.UIUtil.hideLoadingIndicator($container);
        if(typeof complete === 'function') {
          complete(xhr, textStatus);
        }
      }
    });
  },

  showErrors: function(errors, $container, timeout) {
    $container.empty();
    _.each(errors, function(_err, _idx) {
      $container.append($('<li>' + _err + '</li>'));
    });
    $container.removeClass('hidden');
    if (timeout) {
      setTimeout(function() {
        $container.fadeOut(500);
      }, timeout);
    }
  },

  hideErrors: function($container) {
    $container.empty().addClass('hidden');
  },

  initJumpNav: function(){ // jump nav that moves as user scrolls. used on survey details page
    // only do this if a .js-jump-nav exists
    if($('.js-jump-nav').length > 0) {
      // only do this for desktop resolution
      var $jumpNav = $('.js-jump-nav');
      var deviceSize = Kadenze.onModuleLoaded('Layout', function() {
        return Kadenze.Layout.getDeviceSize();
      });
      if( deviceSize == 'lg' || deviceSize == 'md') {
        // the next two variables are used together to calculate the scroll position to trigger when the 'sticky' class is added
        var jumpNavOffsetTop = $jumpNav.offset().top;
        var jumpNavPosTop = $jumpNav.position().top;
        var stickyNav = function() {
          var scrollTop = $(window).scrollTop();
          // we use jumpNavPosTop to avoid a flicker when the stickiness starts, we do this by considering the current offset top position (usu. set by css)
          if(scrollTop > (jumpNavOffsetTop - jumpNavPosTop)) {
            $jumpNav.addClass('sticky');
          } else {
            $jumpNav.removeClass('sticky');
          }
        };
        stickyNav();
        $(window).scroll(function() {
          stickyNav();
        });
      }
    }
    console.log('Kadenze.UIUtil.initJumpNav() finished');
  },

  initStickyScrollModule: function($container, $elem, threshold){
    // --------------------------------------------------------------
    // Used to make an element (such as a sidebar nav etc) scroll with the user once
    // the user has scrolled down a certian amount
    //
    // PARAMS
    // ------------------------------------------------------------------------------
    // $container:  jquery object representing the container that holds the scrolling
    //              module. This object usually has absolute positioning before the
    //              threshold is reached then fixed positioning after. See the public
    //              syllabus view at views/courses/info_public.html.haml for info
    // ------------------------------------------------------------------------------
    // $elem:       jquery object representing the actual module
    // ------------------------------------------------------------------------------
    // threshold:   int number representing the vertical pixels the user can scroll
    //              before the module "sticks" and starts moving with the user
    // ------------------------------------------------------------------------------
    var intThreshold = threshold ? threshold : 180;
    var $footer = $('footer');
    var setScrolling = function(doc, intThreshold) {
      // set scrolling for the desktop floating sidebar area: .js-sidebar-container
      var $doc = $(doc);
      var elem_height = $elem.outerHeight();
      var footerHeight = $footer.outerHeight();
      var documentHeight = $doc.height();
      var scrollTop = $doc.scrollTop();
      if ($doc.scrollTop() > intThreshold) {
        $('body').addClass('sticky-scrolling-module--fixed');
      } else {
        $('body').removeClass('sticky-scrolling-module--fixed');
      }
      // keep the sidebar module from overlapping the footer
      if ( scrollTop > (documentHeight - elem_height - footerHeight) ) {
        $container.css('top', -(scrollTop - (documentHeight - elem_height - footerHeight - 48)));
      } else {
        $container.attr('style', '');
      }
      // end footer overlap prevention

    };
    $(document).off('scroll.stickyScrollModule').on('scroll.stickyScrollModule', _.debounce( function() {
      setScrolling(this, intThreshold);
    }, 10 ));
    // add listener for keydown so that the right panel adjusts while tabbing through the page as well as scrolling
    $(document).off('keydown.stickyScrollModule').on('keydown.stickyScrollModule', function() {
      setScrolling(this, intThreshold);
    });
  },

  initDotDotDotTruncation: function() {
    // global handler for performing dotdotdot truncation on a generic element
    $('.dotdotdot').dotdotdot({ watch : 'window' });
    $('.js-dotdotdot').dotdotdot({ watch : 'window' });
    // global handler for performing dotdotdot truncation with a 'read more' link on a generic element
    $('.dotdotdot-readmore').dotdotdot({ watch : 'window', after: 'a.read-more' });

    $(document).unbind('ajaxSuccess').bind('ajaxSuccess', function(){
      $('.dotdotdot, .js-dotdotdot').change();
    });

    $(document).off('change','.dotdotdot, .js-dotdotdot').on('change','.dotdotdot, .js-dotdotdot',function(){
      $(this).dotdotdot({watch: 'window'});
    });

    // functionality to make the 'read more' and 'read less' work
    $('body').off('click','a.read-more').on('click','a.read-more', function(e) {
      e.preventDefault;
      var el = $(this).closest('.dotdotdot-readmore');
      el.trigger('destroy').find('a.read-more').hide();
      // used later to reset the max-height css value to its original value
      el.data('maxHeight', el.css('max-height'));
      el.css('max-height', 'inherit');
      $('a.read-less', el).show();
    });
    $('body').off('click','a.read-less').on('click','a.read-less', function(e) {
      e.preventDefault;
      $(this).hide();
      var el = $(this).closest('.dotdotdot-readmore');
      $(this).closest('a.read-more').remove();
      el.trigger('destroy');
      el.css('max-height', el.data('maxHeight')).dotdotdot({ watch : 'window', after: 'a.read-more' });
    });
    console.log('Kadenze.UIUtil.initDotDotDotTruncation() finished');
  },

  initMultilineTruncation: function() {
    const isTruncationNeeded = _.debounce(function() {
      $('.js-multiline-truncate').each(function(index) {
        const $this = $(this);
        const containerHeight = $this.height();

        const contentHeight = $this.children().first().hasClass('wysiwyg-content') ?
          $this.children().first()[0].scrollHeight :
          $this[0].scrollHeight;

        const expanded = $this.hasClass('expanded');

        if (!expanded && contentHeight > containerHeight) {
          $this.addClass('truncated');
        } else if (!expanded && contentHeight <= containerHeight) {
          $this.removeClass('truncated');
          $this.removeClass('expanded');
          $this.attr('style', '');
        }
      });
    }, 100);

    isTruncationNeeded();

    $(window).off('resize.multilineTruncation')
      .on('resize.multilineTruncation', isTruncationNeeded);

    $('body').off('click.multilineTruncation')
      .on(
        'click.multilineTruncation_readmore',
        '.js-multiline-truncate__read-more',
        function(event) {
          const $this = $(this);
          const $readless = $this.siblings('.js-multiline-truncate__read-less');

          let $parent;

          if (this.dataset.target) {
            $parent = $(this.dataset.target);
          } else {
            $parent = $this.closest('.js-multiline-truncate');
          }

          const parentHeight = $parent.outerHeight();

          $parent.attr('data-height', parentHeight);
          $parent.css('height', 'auto');
          $parent.addClass('expanded');

          TweenLite.from( $parent, 0.4, { height:parentHeight });

          $readless.focus();
        }
      );

    $('body').off('click.multilineTruncation')
      .on(
        'click.multilineTruncation_readless',
        '.js-multiline-truncate__read-less',
        function(event) {
          const $this = $(this);
          const $readmore = $this.siblings('.js-multiline-truncate__read-more');

          let $parent;

          if (this.dataset.target) {
            $parent = $(this.dataset.target);
          } else {
            $parent = $this.closest('.js-multiline-truncate');
          }

          const parentHeight = $parent.attr('data-height');

          $parent.removeClass('expanded');
          $parent.attr('data-height', '');

          TweenLite.to(
            $parent,
            0.4,
            {
              height: parentHeight,
              onComplete: isTruncationNeeded
            }
          );

          $readmore.focus();
        }
      );
  },

  initTabKeyFocus: function(target){
    var addListeners = function($this,e){
      if (e.which === 9) { // if tab key has been pressed
        $this.addClass('is-tabbed');
        return;
      }
    };
    if(target){
      var $target = $(target);
      $target
        .off('keyup.tabKeyFocus')
        .on('keyup.tabKeyFocus', function(e) {
          addListeners($target,e);
        })
        .off('focusin')
        .on('focusin', function(){
          $target.addClass('is-tabbed');
        })
        .off('focusout.tabKeyFocus')
        .on('focusout.tabKeyFocus', function(){
          $target.removeClass('is-tabbed');
        })
        .off('click.tabKeyFocus')
        .on('click.tabKeyFocus', function(){
          $target.removeClass('is-tabbed');
        })
        // handle events for child <a> elements
        .find('a')
        .off('keyup.tabKeyFocus_child')
        .on('keyup.tabKeyFocus_child', function(){
          $target.addClass('is-tabbed');
        })
        .off('click.tabKeyFocus_child')
        .on('click.tabKeyFocus_child', function(){
          $target.removeClass('is-tabbed');
        });
      // end child element event handling
    } else {
      $(document).off('keyup.tabKeyFocus').on('keyup.tabKeyFocus', '.js-tabkeyfocus', function(e) {
        addListeners($(this),e);
      });
      $(document).off('blur.tabKeyFocus').on('blur.tabKeyFocus', '.js-tabkeyfocus', function(e) {
        $(this).removeClass('is-tabbed');
      });
      $(document).off('click.tabKeyFocus').on('click.tabKeyFocus', '.js-tabkeyfocus', function(){
        $(this).removeClass('is-tabbed');
      });
    }
  },

  initExpandableTextareas: function(){
    $('.js-expandable-textarea').on('focus', function(){
      $(this).addClass('expanded');
    });

    $('.js-expandable-textarea').on('blur', function(){
      var $this = $(this);
      if($this.val().length < 150){
        $this.removeClass('expanded');
      }
    });

    $('.js-expandable-textarea').each(function(){
      var $this = $(this);
      if($this.val().length > 150){
        $this.addClass('expanded');
      }
    });
  },

  initExpandableText: function(){
    const initMultilineTruncation = this.initMultilineTruncation;
    $('.js-expandable-text').each(function(){
      var $this = $(this);
      var $prompt = $('.prompt', $this);
      // open any text initially set to active
      if($prompt.hasClass('active')) {
        $this.attr('aria-expanded', 'true');
        $('.answer', $prompt).show().css('opacity', 1);
      }
      // todo: probably should move the click stuff below into here
    });


    $('.js-expandable-text .prompt > a').off('click').on('click', function(e) {
      var $this = $(this);
      var $prompt = $this.closest('.prompt');
      $prompt.toggleClass('active');
      var dur = 230;
      if ($prompt.hasClass('active')) {
        $this.attr('aria-expanded', 'true');
        $('.answer', $prompt)
          .slideDown(dur, 'easeInOutQuad')
          .animate({
            opacity: 1
          }, {
            queue: false,
            duration: dur + 100
          });
      } else {
        $this.attr('aria-expanded', 'false');
        $('.answer', $prompt).slideUp(dur, 'easeInOutQuad', function() {
          $(this).hide().css('opacity', 0);
        });
      }
      initMultilineTruncation();
      e.preventDefault();
    });
  },

  initClipboard: function(){
    new Clipboard('.js-clipboard');
  },

  initSmoothScroll: function(){
    $('body').off('click.smoothScroll').on('click.smoothScroll', '.js-scroll', function(event){
      var $scrollTrigger = $(this),
        targetSelector = $scrollTrigger.attr('data-scroll-target'),
        scrollTarget = $(targetSelector)[0],
        animationTime = $scrollTrigger.attr('data-scroll-time');
      Kadenze.UIUtil.scrollTo(scrollTarget, animationTime);
    });
  },

  //temporarily highlight element (e.g., for newly-adding content to page)
  toggleElementHighlight: function($el, animationTime) {
    var highlightColor = '#59d8e6';
    var targetColor = $el.css('background-color');

    $el.animate({backgroundColor: highlightColor}, 0.2 * animationTime).animate({backgroundColor: targetColor}, 0.8 * animationTime);
  },

  initSortable: function(url, $el, $container, sortFunc) {
    $el.sortable({
      itemSelector: '.display-table__data',
      handle: '.display-table__sort-handle',
      update: function(e, ui) {
        sortFunc.call(this, url, $(this), $container);
      }
    });
    //Prevent handle clicks to propogate
    $el.find('.display-table__sort-handle').on('click.tableSort', function(e){
      e.stopPropagation();
    });
  },

  initTabAreas: function(){
    //console.log('initializing tabs');
    // this handles changing tabs
    $('a[data-toggle="tab"]').off('shown.bs.tab').on('shown.bs.tab', function (e) {
      Kadenze.UIUtil.initDotDotDotTruncation();
      Kadenze.UIUtil.refreshCarousel($(e.target).parents('.panel').find('.js-carousel'));
      $(window).trigger('resize');
      // set aria-selected to active tab
      $(e.target).parent().attr('aria-selected', 'true');
      $(e.relatedTarget).parent().attr('aria-selected', 'false');
    });


    var tab = Kadenze.onModuleLoaded('Util', function() {
      return Kadenze.Util.getQueryVariable('tab');
    });

    if(tab) {
      $('.tab-subnav__tab-link[href=\'#' + tab + '\']').tab('show');
    } else {
      $('li.tab-subnav__tab:first-child a').tab('show');
    }
  },

  initModalFocusEvents: function() {
    $('body').on('shown.bs.modal', '.modal', function() {
      var btn = $(this).find('button')[0];
      if (btn)
        btn.focus();
    });
  },

  initSelectPickerEvents: function(){
    $('body').off('loaded.bs.select', '.selectpicker[data-live-search="true"]');
    $('body').on('loaded.bs.select', '.selectpicker[data-live-search="true"]', function(){
      var $bs_select = $(this).parents('.bootstrap-select');
      var $bs_searchbox = $bs_select.find('.bs-searchbox');
      $bs_searchbox.attr('role', 'combobox');
      $bs_searchbox.attr('aria-haspopup', 'listbox');
      $bs_searchbox.attr('aria-expanded', 'false');
      $bs_searchbox.find('input.form-control').attr('role', 'searchbox');
      $bs_select.find('div.dropdown-menu').find('ul.dropdown-menu').attr('role', 'listbox');
    });
    $('body').off('shown.bs.select', '.selectpicker[data-live-search="true"]');
    $('body').on('shown.bs.select', '.selectpicker[data-live-search="true"]', function(){
      var $bs_select = $(this).parents('.bootstrap-select');
      var $bs_searchbox = $bs_select.find('.bs-searchbox').attr('aria-expanded', 'true');
    });
    $('body').off('hidden.bs.select', '.selectpicker[data-live-search="true"]');
    $('body').on('hidden.bs.select', '.selectpicker[data-live-search="true"]', function(){
      var $bs_select = $(this).parents('.bootstrap-select');
      var $bs_searchbox = $bs_select.find('.bs-searchbox').attr('aria-expanded', 'false');
    });
  },

  // init lazy image loading with retina capabilities, using jquery.unveil plugin
  initLazyImageLoading: function(){
    // the parm is the 'threshold' - the number of pixels outside the viewport when loading of the image is triggered
    $('img').unveil(300);
  },


  // Check if an element is completely in view
  isInFullView (selectorOrElement) {
    let el

    if (typeof selectorOrElement === 'string') {
      el = document.querySelector(selectorOrElement)
    } else if (selectorOrElement.jquery) {
      el = selectorOrElement[0];
    } else {
      el = selectorOrElement
    }

    const rect = el.getBoundingClientRect();

    return (
      rect.top >= 0 &&
      rect.left >= 0 &&
      rect.bottom <= (window.innerHeight || $(window).height()) &&
      rect.right <= (window.innerWidth || $(window).width())
    );
  },

  // Check if an element is in view at all
  isInView: function(el) {
    if(el.jquery) {
      el = el[0];
    }

    var rect = el.getBoundingClientRect(),
      doc = document.documentElement;

    return (
      rect.bottom >= 0 &&
      rect.right >= 0 &&
      rect.top <= doc.clientHeight &&
      rect.left <= doc.clientWidth
    );
  },

  // get what sides of an element are visible
  getVisibleSides: function(el) {
    if(el.jquery) {
      el = el[0];
    }

    var rect = el.getBoundingClientRect();

    return {
      top: rect.top >= 0 && (rect.top <= (window.innerHeight || $(window).height())),
      left: rect.left >= 0,
      bottom: rect.bottom <= (window.innerHeight || $(window).height()),
      right: rect.right <= (window.innerWidth || $(window).width())
    };
  },

  scrollTo: function(element, speed) {
    var offset = $(element).offset().top;
    TweenLite.to(window, speed, { scrollTo: { y: offset, x:0 }, ease:Power2.easeInOut });
  },

  isMobileApp: function() {
    return IS_MOBILE_APP;
  },

  isMobileOrTablet: function() {
    return (is.mobile() || is.tablet());
  },

  mobileBreakPoint: function() {
    return 768;
  },

  tabletBreakPoint: function() {
    return 992;
  },

  isSafari: function() {
    return (navigator.vendor && navigator.vendor.indexOf('Apple') > -1 && navigator.userAgent && !navigator.userAgent.match('CriOS'));
  },

  /*
  ** Kadenze.UIUtil.removeDuplicateNodes
  **
  **  Removes duplicate nodes from the DOM based on uniqueness of an attribute
  **
  ** nodes: Array of DOM nodes, can also be a jQuery object of nodes
  ** attr: String, attribute to compare each node against
  **
  ** Returns:
  **  Array of unique nodes, while removing the non unique nodes from the DOM
  **
  ** Ex:
  **  var myArrayWithDuplicates = [<div id='1'>one</div>, <div id='1'>one</div>]
  **
  **  Kadenze.UIUtil.removeDuplicateNodes(myArrayWithDuplicates, "id");
  **
  **  The second div in your array is now removed from the DOM
  **
  */

  removeDuplicateNodes: function(nodes, attr) {
    var items = nodes instanceof jQuery ? nodes[0] : nodes,
      keep = _.uniq(items, function(child) { return child[attr]; }),
      reject = _.difference(items, keep);

    $(reject).remove();

    return keep;
  },

  /*
  ** Kadenze.UIUtil.refreshCarousel
  **
  **  calls slick.refresh on a carousel if it exists
  **
  ** $carousel: jQuery object, the selected node that has had slick initialized on it
  */

  refreshCarousel: function($carousel) {
    if($carousel.length) {
      $carousel.slick('refresh');
    }
  },

  /*
   ** Kadenze.UIUtil.rgb2hex
   **
   **  takes a RGB color value and converts it to HEX
   **
   ** orig: RGB color value string, eg: "rgb(75, 82, 83)"
   **
   ** Returns: a HEX string, eg: "#4b5253"
   */

  rgb2hex: function(orig) {
    var hex = '';
    if (orig && orig.length > 0) {
      var rgb = orig.replace(/\s/g,'').match(/^rgba?\((\d+),(\d+),(\d+)/i);
      hex = (rgb && rgb.length === 4) ? '#' +
        ('0' + parseInt(rgb[1],10).toString(16)).slice(-2) +
        ('0' + parseInt(rgb[2],10).toString(16)).slice(-2) +
        ('0' + parseInt(rgb[3],10).toString(16)).slice(-2) : orig;
    }
    return hex;
  },

  turnOffDueDates: function(user_preferences, $el) {
    var url = Kadenze.Routes.users_path();
    var method = 'PUT';
    var request = null;
    var pref = user_preferences;
    pref['hide_due_dates'] = true;

    var data = {user: {preferences: pref}};

    request = Kadenze.UIUtil.makeRequest(url, method, data, $el, null,
      function(data, textStatus, xhr) { //Success
        new Kadenze.Notice.success({message: I18n.t('coursework.show.first_submission_modal.cancel_success')});
      }, function(xhr, textStatus, errorThrown) { //Error
        new Kadenze.Notice.error({message: I18n.t('coursework.show.first_submission_modal.cancel_failure')});
      });

    return request;
  },

  showFirstSubmissionModal: function(user_preferences, $el) {
    var week_from_now = moment().add(1, 'weeks').format(I18n.t('date.formats.js_default'));
    var prompt  = 'We\'ve assigned a suggested completion date of 1 week from today (' + week_from_now + ') to your next assignment(s).';
    var opts = {
      title: I18n.t('coursework.show.first_submission_modal.title'),
      cancel_btn_text: I18n.t('coursework.show.first_submission_modal.cancel_button'),
      ok_btn_text: I18n.t('coursework.show.first_submission_modal.ok_button'),
      layout_type: 'icon',
      icon_class: 'calendar'
    };
    var turnOff = function() {
      var req = Kadenze.UIUtil.turnOffDueDates(user_preferences, $el);
      req.success(function() {
        user_preferences['hide_due_dates'] = true;
      });
    };
    Kadenze.UIUtil.confirmAction(prompt, null, turnOff, opts);
  },

  showCropper: function (file, callback, cropts) {
    if(!file) return;

    // dynamically create modals to allow multiple files processing
    var $cropperModal = Modal.for('img-crop', { autoShow: false });

    // 'Crop and Upload' button in a modal
    var $uploadCrop = $cropperModal.find('.js-crop-upload');

    var $img = $('<img style="width: 100%"/>');
    // initialize FileReader which reads uploaded file
    var reader = new FileReader();
    var cropper;
    reader.onloadend = function () {
      // add uploaded and read image to modal
      $cropperModal.find('.js-image-container').html($img);
      $img.attr('src', reader.result);
      $img.css({maxHeight: $(window).height() - $(window).height() / 3, width: 'auto'});

      var defaults = {
        aspectRatio: 16 / 9,
        autoCropArea: 1,
        movable: false,
        cropBoxResizable: true,
        wheelZoomRatio: 0.05,
        zoomable: true,
        zoomOnWheel: false,
        // minContainerWidth: $(window).width() - $(window).width() / 3,
        minContainerHeight: $(window).height() - $(window).height() / 2.5
      };


      setTimeout(function() {
        // initialize cropper for uploaded image
        cropper = new Cropper($img[0], _.extend({}, defaults, cropts));
        var $zoomOut = $cropperModal.find('.js-crop-zoom-out');
        var $zoomIn = $cropperModal.find('.js-crop-zoom-in');

        $zoomOut.click(function() {
          cropper.zoom(-0.1);
        });

        $zoomIn.click(function() {
          cropper.zoom(0.1);
        });
      }, 200);
    };

    reader.readAsDataURL(file);

    $cropperModal.modal({
      show: true,
      backdrop: 'static',
      keyboard: false
    });

    // listener for 'Crop and Upload' button in modal
    $uploadCrop.on('click', function() {
      callback(cropper);
      $cropperModal.modal('hide');
    });
  },

  trianglify: function($el, colors, cell_size, stroke_width, color_space, variance) {
    var width = $el.outerWidth();
    var height = $el.outerHeight() + 100;
    var t = Trianglify({
      x_colors: colors,
      cell_size: cell_size,
      width: width,
      height: height,
      stroke_width: stroke_width,
      color_space: color_space,
      variance: variance
    });
    var pattern = t.png();
    $el.css('background-image', 'url(' + pattern + ')');
  },

  // hideAllLoadingIndicators: function(cb) {
  //  $('.ajax-overlay').fadeOut(500, cb);
  // }
  //

  enterFullScreen: function(element) {
    if (element.requestFullscreen) {
      element.requestFullscreen();
    } else if (element.mozRequestFullScreen) {
      element.mozRequestFullScreen();
    } else if (element.webkitRequestFullscreen) {
      element.webkitRequestFullscreen();
    } else if (element.webkitRequestFullScreen) {
      element.webkitRequestFullScreen(Element.ALLOW_KEYBOARD_INPUT);
    } else if (element.msRequestFullscreen) {
      element.msRequestFullscreen();
    }
  },

  exitFullScreen: function() {
    if (document.exitFullscreen) {
      document.exitFullscreen();
    } else if (document.cancelFullScreen) {
      document.cancelFullScreen();
    } else if (document.mozCancelFullScreen) {
      document.mozCancelFullScreen();
    } else if (document.webkitExitFullscreen) {
      document.webkitExitFullscreen();
    } else if (document.webkitCancelFullScreen) {
      document.webkitCancelFullScreen();
    } else if (document.msExitFullscreen) {
      document.msExitFullscreen();
    }
  }
};
