import DICOMMediaRenderer from './../dicom-media-renderer';
import Modal from './../components/modal';

export default {
  favorite: function() {
    console.log('favoriting not yet implemented');
  },

  play: function(mediaObj) {
    if (!Kadenze.AudioPlayer.initialized()) {
      Kadenze.AudioPlayer.init(function() {
        Kadenze.AudioPlayer.play( mediaObj );
      });
    }
    else {
      Kadenze.AudioPlayer.play( mediaObj );
    }
  },

  queue: function(mediaObj) {
    if (!Kadenze.AudioPlayer.initialized()) {
      Kadenze.AudioPlayer.init(function() {
        Kadenze.AudioPlayer.addToQueue( mediaObj );
      });
    } else {
      Kadenze.AudioPlayer.addToQueue( mediaObj );
    }
  },

  preview: function(mediaObj) {
    const args = {}

    const $modal = Modal.for('modal', {
      title: 'Loading...',
      body: '',
      okBtnText: 'Done',
      prefixTemplateName: false,
    });

    $('.modal-body .ajax-overlay', $modal).show()
    $('.modal-body-content', $modal).hide()
    $('.modal-body-caption', $modal).hide()

    const node = $modal.find('.kdnze-svg-icon')[0]

    Kadenze.SvgIcon.init( node )

    let url

    if (mediaObj.file.file) {
      url = mediaObj.file.file.url
    } else {
      url = mediaObj.file.url || mediaObj.url
    }

    if (mediaObj.file.gallery && mediaObj.file.gallery.url)
      url = mediaObj.file.gallery.url

    if (
      mediaObj.meta.type === 'image' ||
        (mediaObj.attachment && mediaObj.attachment.meta.type == 'image')
    ) {
      const $content = $('<img class="img-preview" src="' + url + '" alt="' + self.name + '"/>')

      $content.load(() => {
        $('.modal-body-content', $modal).html($content)
        $('.modal-body-caption', $modal).html(mediaObj.text_data)

        $('.modal-body .ajax-overlay', $modal).delay(500).fadeOut(500, () => {
          $('.modal-body-content', $modal).fadeIn(500)
          $('.modal-body-caption', $modal).fadeIn(500)
        })

        $('.modal-title', $modal).fadeOut(500, () => {
          $('.modal-title', $modal).text(mediaObj.name || 'Preview').fadeIn(500)
        })
      })
    } else if (
      mediaObj.meta.type === 'video' ||
        (mediaObj.attachment && mediaObj.attachment.meta.type == 'video')
    ) {
      if (self.html5Player) {
        self.html5Player.pause();
        self.html5Player.hide();
      }

      var mediaUrls = {
        h264_360_url: mediaObj.file.h264_360_url,
        h264_720_url: mediaObj.file.h264_720_url
      };

      var $player = $('#js-video-player', $modal);

      $player.show();

      $('.modal-body .ajax-overlay', $modal).hide();
      $('.modal-body-content', $modal).show();

      self.html5Player = new Kadenze.VideoPlayer($player, mediaUrls, 'vjs-student-skin');

      $modal.on('shown.bs.modal', function(e) {
        self.html5Player.show().play(false);
      });

      $modal.on('hidden.bs.modal', function(e) {
        self.html5Player.pause();
        self.html5Player.hide();
      });

      $('.modal-title', $modal).fadeOut(500, function() {
        $('.modal-title', $modal).text(mediaObj.name || 'Preview').fadeIn(500);
      });
    } else if (mediaObj.meta.type === 'url') {
      if (mediaObj.playableLink()) {
        $('.modal-body .ajax-overlay', $modal).hide();
        $('.modal-body-content', $modal).show();
        var $wrapper = $('#js-modalIframeWrapper', $modal);
        $wrapper.show();
        var player = new Kadenze.IframeMediaPlayer($wrapper, url);
        $modal.on('shown.bs.modal', function(e) {
          player.show();
        });
        $modal.on('hidden.bs.modal', function(e) {
          player.stop();
          player.pause();
          player.hide();
        });
      }
    } else if (mediaObj.meta.type === 'text' && !_.isEmpty(mediaObj.text_data)) {
      var $ajaxOverlay = $('.modal-body .ajax-overlay', $modal),
        $modalBody = $('.modal-body-content', $modal),
        $modalTitle = $('.modal-title', $modal);

      $ajaxOverlay.fadeOut(500, function() {
        var content = mediaObj.text_data;
        content = $('<blockquote>' + content + '</blockquote>');
        $modalBody.html(content).fadeIn(500);

        //Fix for race condition
        $(this).hide();
      });

      $modalTitle.fadeOut(500, function() {
        var title = mediaObj.name ? mediaObj.name : 'Preview';
        $('.modal-title', $modal).text(title).fadeIn(500);
      });
    } else if (mediaObj.meta.type === 'pdf' || (mediaObj.attachment && mediaObj.attachment.meta.type === 'pdf')) {
      var fileUrl = mediaObj.file.file ? mediaObj.file.file.url : mediaObj.file.url,
        $ajaxOverlay = $('.modal-body .ajax-overlay', $modal),
        $modalBody = $('.modal-body-content', $modal),
        $modalTitle = $('.modal-title', $modal);

      var content = $('<iframe width=\'100%\' frameborder=\'0\' height=\'500px\' src=\'' + fileUrl + '\'></iframe>');
      $modalBody.html(content).fadeIn(500);

      $modalTitle.fadeOut(500, function() {
        var title = mediaObj.name ? mediaObj.name : 'Preview';
        $('.modal-title', $modal).text(title).fadeIn(500);
      });

      $ajaxOverlay.hide();
    } else if (mediaObj.meta.type === 'dicom' || (mediaObj.attachment && mediaObj.attachment.meta.type == 'dicom')) {
      try {
        let $ajaxOverlay = $('.modal-body .ajax-overlay', $modal),
          $modalBody = $('.modal-body-content', $modal),
          $modalTitle = $('.modal-title', $modal),
          $dicomImage = $('#js-dicom-image', $modalBody)[0];

        $modalBody.show();
        $($dicomImage).show();

        $modal.on('shown.bs.modal', function(e) {
          const renderer = new DICOMMediaRenderer({ src: url });
          renderer.present(() => {
            renderer.$viewer = $($dicomImage);
            $ajaxOverlay.hide();
          });

          $modalTitle.fadeOut(500, function() {
            var title = mediaObj.name ? mediaObj.name : 'Preview';
            $('.modal-title', $modal).text(title).fadeIn(500);
          });
        });

      } catch(err) {
        console.log(err);
      }
    } else {
      //Code/text files
      var fileUrl = mediaObj.file.file ? mediaObj.file.file.url : mediaObj.file.url,
        $ajaxOverlay = $('.modal-body .ajax-overlay', $modal),
        $modalBody = $('.modal-body-content', $modal),
        $modalTitle = $('.modal-title', $modal);

      var request = new Kadenze.AjaxRequest({
        url: fileUrl,
        type: 'GET',
        dataAsJSON: false,
        contentType: 'text/plain',
        dataType: 'text'
      });

      request.fail(function(xhr, responseText, textStatus) {
        $ajaxOverlay.hide();
        $modalBody.show();
        $modalTitle.text('Error Loading File');

        new Kadenze.Notice.error({
          title: 'Error Loading File',
          message: Kadenze.I18n.t('notice.unexpected_error'),
          parentObject: $modalBody,
          onDismiss: function() {
            $modal.modal('hide');
          }
        });
      });

      request.done(function(data) {
        $ajaxOverlay.fadeOut(500, function() {
          var content = data;

          if (mediaObj.meta.type === 'code') {
            content = $('<pre><code>' + data + '</code></pre>');
            if (mediaObj.meta.h_lang) {
              content.addClass('language-' + mediaObj.meta.h_lang);
              Prism.highlightElement(content[0]);
            }
          } else {
            content = $('<pre>' + data + '</pre>');
          }

          $modalBody.html(content).fadeIn(500);

          $(this).hide();
        });

        $modalTitle.fadeOut(500, function() {
          var title = mediaObj.name ? mediaObj.name : 'Preview';
          $('.modal-title', $modal).text(title).fadeIn(500);
        });
      });
    }
  },

  previewInGallery: function(media) {
    window.location =  Kadenze.Routes.course_gallery_detail_path(media.course_slug, media.coursework_slug, media.user_slug);
  }
};
