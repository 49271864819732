let KadVideoPlayer = (function () {
  //events supported:
  //  playing, pause, stop, ended, timeupdate, cuechange, durationchange, show, hide

  //shared across instances
  var __last_id = 0;

  //constructor
  function VideoPlayer($video_wrapper, media_src_urls, skin, subtitles, user_state, vjs_opts) {
    //private instance data
    var _self = this;
    var _vjs = null;

    var _makePlayerWasCalled = null;

    var _$vjs_el = null; //vjs wrapper around <video> tag
    var _video_player_id = -1;
    var _$animation_options = null;
    var _completion_callback = null;
    var _playerReady = false;
    var _playOnReady = false;
    var _durationKnown = false;
    var _shown = false;
    var _skin = skin ? skin : 'vjs-default-skin';
    var _metadataReady = false;
    var _captionLanguage = null;
    var _isInstructor = skin == 'vjs-instructor-skin' ? true : false;

    //private methods
    var onPlayEvent = function (e) {
      _self.triggerEvent('playing');
      if (_captionLanguage) {
        _self.showCaptions(_captionLanguage);
      }
    };

    var onFirstPlayEvent = function (e) {
      _self.triggerEvent('firstplay');
    };

    var onPauseEvent = function (e) {
      _self.triggerEvent('pause');
    };

    var onEnded = function (e) {
      _self.triggerEvent('ended');

      if (_completion_callback) {
        _completion_callback();
      }
    };

    var onTimeUpdate = function (e) {
      //console.log('timeupdate for ' + _video_player_id);
      _self.triggerEvent('timeupdate', {seconds: _vjs.currentTime()});
    };

    var onSeeked = function (e) {
      _self.triggerEvent('seeked', {seconds: _vjs.currentTime()});
    };

    var onFullscreen = function (e) {
      _self.triggerEvent('fullscreenchange');
    };

    var onVolumechange = function (e) {
      _self.triggerEvent('volumechange');
    };

    var onRatechange = function (e) {
      _self.triggerEvent('ratechange');
    };

    var onSkipBack = function (e, data) {
      _self.triggerEvent('skipBack', data);
    };

    var onSkipForward = function (e, data) {
      _self.triggerEvent('skipForward', data);
    };

    var onSkipBackToQuestion = function (e, data) {
      _self.triggerEvent('skipBackToQuestion', data);
    };

    var onSkipForwardToQuestion = function (e, data) {
      _self.triggerEvent('skipForwardToQuestion', data);
    };

    var onCaptionchange = function (e) {
      console.log('captionChange called');
      var templang = _captionLanguage;
      _captionLanguage = null;
      for (var i = 0; i < this.textTracks().length; i++) {
        var track = this.textTracks()[i];
        if (track.mode == 'showing') {
          if (templang != track.label) {
            _self.triggerEvent('captionstrackchange', track);
          }
          _captionLanguage = track.label;
          break;
        }
      }
    };

    var onLoadedData = function (e) {
      console.log('data loaded', e);
      _metadataReady = true;
      _self.triggerEvent('loadedData', e);
    };

    var onLoadedMetaData = function (e) {
      console.log('metadata loaded', e);
      _self.triggerEvent('loadedMetaData', e);
    };

    var makePlayer = function () {
      _makePlayerWasCalled = true;
      if (_vjs) {
        destroyPlayer();
      }

      _playerReady = false;

      //insert player into the document
      var $video_el = $(JST['templates/video-element']({
        h264_360_url: _self.media_src_urls.h264_360_url,
        h264_720_url: _self.media_src_urls.h264_720_url
      }));

      if (_video_player_id < 0) {
        _video_player_id = 'vjs_player_' + __last_id++;
      }

      console.log('makePlayer for ' + _video_player_id);

      $video_el.attr('id', _video_player_id);

      //set the skin
      $video_el.addClass(_skin);

      _self.$video_wrapper.append($video_el);
      _self.$video_wrapper.prop('tabindex', 0);

      _self.$video_wrapper.on('focus', function () {
        let $vjsPlayer = $(this).find('.video-js');
        $vjsPlayer.removeClass('vjs-user-inactive');
        $vjsPlayer.addClass('vjs-user-active');
      });

      //instantiate vjs player
      var preload = 'auto';


      var defaults = {
        playbackRates: [1.0, 1.25, 1.5, 1.75, 2.0, 2.25, 2.5],
        defaultVolume: 1,
        preload: preload,
        'html5': {
          nativeTextTracks: is.ios() && is.safari()
        },
        plugins: {
          videoJsResolutionSwitcher: {
            default: 'high',
            dynamicLabel: true
          }
        }
      };


      // This is how you set the order of control bar components BEFORE videojs is instantiated... ugh.
      videojs.getComponent('ControlBar').prototype.options_ = {
        loadEvent: 'play',
        children: [
          'progressControl',
          'seekButton',
          'playToggle',
          'volumeMenuButton',
          'durationDisplay',
          'captionsButton',
          //'resolution',
          'playbackRateMenuButton',
          'fullscreenToggle'
        ]
      };

      var opts = _.extend({}, defaults, vjs_opts);
      var thevideo = videojs($video_el[0], opts, function () {
        // Player (this) is initialized and ready.
        _vjs = this;
        var v = $('video', _$vjs_el)[0];

        this.user_state = _self.user_state;

        this.on('play', onPlayEvent);
        this.on('firstplay', onFirstPlayEvent);
        this.on('pause', onPauseEvent);
        this.on('ended', onEnded);
        this.on('timeupdate', onTimeUpdate);
        this.on('seeked', onSeeked);
        this.on('durationchange', function () {
          _durationKnown = true;
        });
        this.on('fullscreenchange', onFullscreen);
        this.on('volumechange', onVolumechange);
        this.on('ratechange', onRatechange);
        this.on('loadeddata', onLoadedData);
        this.on('loadedmetadata', onLoadedMetaData);
        this.on('texttrackchange', onCaptionchange);
        this.on('skipBack', onSkipBack);
        this.on('skipForward', onSkipForward);
        this.on('skipBackToQuestion', onSkipBackToQuestion);
        this.on('skipForwardToQuestion', onSkipForwardToQuestion);
        // $(document).on('webkitfullscreenchange mozfullscreenchange fullscreenchange MSFullscreenChange', onFullscreen);

        _$vjs_el = $('#' + _video_player_id);
        //at this point, vjs has overwritten the id of $video_el, as well
        //as wrapping it with a div that has $video_el's original id.

        _playerReady = true;
        _self.triggerEvent('ready');

      }); // End thevideo var declaration

      buildComponents(thevideo, opts);

      if (_self.subtitles && _self.subtitles.length > 0) {
        _.each(_self.subtitles, function (subtitle) {
          _self.addTextTrack(subtitle, thevideo);
        });
      }
    }; // End makePlayer

    var destroyPlayer = function () {
      console.log('destroyPlayer for ' + _video_player_id);

      _vjs && _vjs.dispose();
      _$vjs_el && _$vjs_el.remove();

      _$vjs_el = null;
      _vjs = null;
      _playerReady = false;
      _playOnReady = false;
      _durationKnown = false;
    };

    var onCueChange = function (e) {
      //'this' is set by vjs to the track emitting the event
      var track = this;

      //find type of track
      var kind = track.kind();

      //get active cues at time of cuechange
      var cues = track.activeCues();

      var evt = {kind: kind, activeCues: cues};

      //pass it on:
      _self.triggerEvent('cuechange', evt);

      //console.log('cuechange');
    };

    var getCueTrack = function (kind) {
      kind = kind || 'metadata';

      //find the first text track with correct kind:
      var tracks = _vjs.textTracks();
      var firstTrack = null;
      tracks.some(function (tk, ix, arr) {
        tk.kind() === kind ? (firstTrack = tk, true) : false;
      });

      return firstTrack;
    };

    var buildComponents = function (video, opts) {
      var displayMode = opts['displayMode'];
      if (_isInstructor) {
        if (displayMode != 'gallery') {
          new Kadenze.VideoPlayer.components.skipToQuestionButton({
            player: video,
            direction: 'back',
            cssClass: 'kf-question-prev',
            indexPosition: 1
          });

          new Kadenze.VideoPlayer.components.skipToQuestionButton({
            player: video,
            direction: 'forward',
            cssClass: 'kf-question-next',
            indexPosition: 3
          });
        }
      } else {
        new Kadenze.VideoPlayer.components.seekButton({
          player: video,
          direction: 'back',
          interval: 10,
          indexPosition: 1,
          cssClass: 'kf-10-back'
        });

        new Kadenze.VideoPlayer.components.seekButton({
          player: video,
          direction: 'forward',
          interval: 10,
          indexPosition: 3,
          cssClass: 'kf-10-forward'
        });

        if(displayMode == 'session') {
          // Remove default play button
          video.bigPlayButton.dispose();

          new Kadenze.VideoPlayer.components.ctaModal({
            player: video,
            user_type: opts['user_type'],
            state: 'unlocked',
            ctaCallback: opts['ctaCallback']
          });

          new Kadenze.VideoPlayer.components.ctaModal({
            player: video,
            user_type: opts['user_type'],
            state: 'locked',
            ctaCallback: opts['ctaCallback']
          });

          new Kadenze.VideoPlayer.components.ctaModal({
            player: video,
            user_type: opts['user_type'],
            state: 'locked',
            premium_only: true,
            ctaCallback: opts['ctaCallback']
          });
        }

        if (displayMode != 'gallery') {
          new Kadenze.VideoPlayer.components.skipButton({
            player: video,
            direction: 'forward',
            cssClass: 'kf-arrow-thin'
          });

          new Kadenze.VideoPlayer.components.skipButton({
            player: video,
            direction: 'back',
            cssClass: 'kf-arrow-thin'
          });
        }
      }
    };

    //public methods
    _.extend(this, Kadenze.EventSource); //add event handling capability

    this.metaReady = function () {
      return _metadataReady;
    };

    this.setMetaReady = function (val) {
      _metadataReady = val;
    };

    this.show = function ($animation_options) {
      _$animation_options = $animation_options;
      if (!_makePlayerWasCalled) {
        makePlayer();
      }
      else {
        $('#' + _video_player_id).show();
      }
      this.triggerEvent('show');
      _shown = true;

      console.log('show for ' + _video_player_id);

      return this;
    };

    this.shown = function () {
      return _shown;
    };

    this.play = function (autoplay, completion_callback) {
      console.log('play for ' + _video_player_id);

      _completion_callback = completion_callback;

      if (autoplay) //don't wait for user to press play
      {
        //queue it up if the player is not yet ready
        if (!_playerReady) {
          _playOnReady = true;
        } else {
          _vjs.play();
        }
      }
      return this;
    };

    this.pause = function () {
      console.log('pause for ' + _video_player_id);

      if (_playerReady) {
        _vjs.pause();
      }

      return this;
    };

    //is the player paused? no if playing, yes all other times
    this.paused = function () {
      if (_playerReady) {
        return _vjs.paused();
      }
      return true;
    };

    this.stop = function () {
      console.log('stop for ' + _video_player_id);

      if (_playerReady) {
        _vjs.pause();

        this.triggerEvent('stop', {currentTime: _vjs.currentTime(), ended: _vjs.ended()});

        //if(_durationKnown)
        //    _vjs.currentTime(0);
      }
      return this;
    };

    this.hide = function ($animation_options) {
      console.log('hide for ' + _video_player_id);

      // destroyPlayer();
      $('#' + _video_player_id).hide();
      this.triggerEvent('hide');
      _shown = false;

      return this;
    };

    this.src = function (source) {
      _metadataReady = false;

      if (_vjs) {
        _vjs.src(source);
        _vjs.load();
      } else {
        console.log('videojs not yet shown');
      }
    };

    this.load = function () {
      if (_vjs) {
        _vjs.load();
      }
    };

    this.exitFullscreen = function () {
      if (_playerReady) {
        return _vjs.exitFullscreen();
      }

      return true;
    };

    this.isFullscreen = function () {
      if (_playerReady) {
        return _vjs.isFullscreen();
      }
    };

    this.requestFullscreen = function () {
      if (_vjs) {
        return _vjs.requestFullscreen();
      }
    };

    this.contentEl = function () {
      if (_playerReady) {
        return _vjs.contentEl();
      }

      return true;
    };

    this.controls = function () {
      if (_playerReady) {
        return _vjs.controls();
      }

      return true;
    };


    //retrieves the player's seek bar, for compositing
    this.seekBar$El = function () {
      return _vjs ? $(_vjs.getChild('controlBar').getChild('progressControl').getChild('seekBar').el()) : null;
    };

    //retrieves the player's transport bar, for compositing
    this.transportBar$El = function () {
      return _vjs ? $(_vjs.getChild('controlBar').el()) : null;
    };

    //retrieves the player's video display area, for compositing
    this.videoDisplay$El = function () {
      return $('#' + _video_player_id);
    };

    this.textTracksDisplay$El = function () {
      return this.videoDisplay$El().find('.vjs-text-track-display');
    };

    //gets (no argument) or sets playhead time
    this.currentTime = function (seconds) {
      //console.log('currentTime for ' + _video_player_id);

      if (_playerReady) {
        if (seconds !== undefined) {
          _vjs.currentTime(seconds);
        } else {
          return _vjs.currentTime();
        }
      }

      return 0;
    };

    this.duration = function () {
      return _durationKnown ? _vjs.duration() : 0;
    };

    this.volume = function () {
      return _playerReady ? _vjs.volume() : 0;
    };

    this.resolution = function () {
      return _playerReady ? _vjs.currentResolution()['label'] : 'HD';
    };

    this.currentCaptionLanguage = function () {

    };

    this.setResolution = function (res) {
      if (_vjs) {
        _vjs.currentResolution(res);
      }
    };

    this.setVolume = function (volume) {
      if (_vjs) {
        _vjs.volume(volume);
      }
    };

    this.ended = function () {
      //console.log('ended for ' + _video_player_id);
      if (_playerReady) {
        return _vjs.ended();
      }

      return false;
    };

    this.playbackRate = function () {
      //console.log('playbackRate for ' + _video_player_id);

      if (_vjs) {
        return _vjs.playbackRate();
      }

    };

    this.setPlaybackRate = function (rate) {
      if (_vjs) {
        _vjs.playbackRate(rate);
      }
    };

    this.showCaptions = function (language) {
      console.log('showcaptions called');
      for (var i = 0; i < _vjs.textTracks().length; i++) {
        var track = _vjs.textTracks()[i];
        if (track.label == language) {
          // _vjs.showTextTrack(track.id, true);
          track.mode = 'showing';
        }
      }

    };

    this.addTextTrack = function (subtitle, player) {
      var track,
        vjsInstance = _.isNull(_vjs) ? player : _vjs;

      if (_.isUndefined(vjsInstance) || _.isNull(vjsInstance)) return;

      if (subtitle.src_file) {
        var opts = {
          kind: 'captions',
          language: subtitle.srclang,
          label: subtitle.label,
          src: subtitle.src_file.url
        };
        track = vjsInstance.addRemoteTextTrack(opts);
      } else {
        track = vjsInstance.addTextTrack('captions', subtitle.label, subtitle.srclang);
      }
      // _vjs.addTextTrack('captions', subtitle.label, subtitle.srclang, opts);
      // if(_playerReady)

      // _vjs.load();
      // return null;
      return track;
    };

    this.clearTextTracks = function () {
      if (_vjs) {
        var tracks = _vjs.remoteTextTracks();
        while (tracks.length > 0) {
          var track = tracks[0];
          track.mode = 'hidden';
          _vjs.removeRemoteTextTrack(track);
        }
      }
    };

    this.removeTextTrack = function (language) {
      // if(_playerReady && _vjs) {
      //   _vjs.removeTextTrack(language);
      //   _vjs.load();
      // }
      return null;
    };

    //is the player seeking?
    this.seeking = function () {
      if (_playerReady) {
        return _vjs.seeking();
      }

      return false;
    };

    this.getTextTrackSettings = function () {
      if (_vjs) {
        return _vjs.textTrackSettings.getValues();
      }
    };

    this.setTextTrackSettings = function (settings) {
      if (_vjs) {
        return _vjs.textTrackSettings.setValues(settings);
      }
    };

    this.getCaptionLanguage = function() {
      return _captionLanguage;
    };

    this.setCaptionLanguage = function(lang) {
      _captionLanguage = lang;
      _self.showCaptions(_captionLanguage);
    };

    this.updateUserState = function (t) {
      _self.user_state = t;
      _self.user_state.volume = this.volume();
      _self.user_state.playback_rate = this.playbackRate();
      _self.user_state.caption_language = this.getCaptionLanguage();
    };

    this.setMediaSrcUrls = function (urls) {
      this.media_src_urls = urls;
      var el = _vjs.el().getElementsByTagName('video')[0];

      var sources = [
        {
          'label': 'HD',
          'type': 'video/mp4',
          'src': urls.h264_720_url,
          'res': '720'
        },
        {
          'label': 'SD',
          'type': 'video/mp4',
          'src': urls.h264_360_url,
          'res': '480'
        }
      ];

      $('<source src="' + urls.h264_360_url + '" type="video/mp4" label="SD" res="480">').appendTo($(el));
      $('<source src="' + urls.h264_720_url + '" type="video/mp4" label="HD" res="720">').appendTo($(el));

      return _vjs.updateSrc(sources);
      // var srcObj = _vjs.resolutions_.selectSource(sources);
      // this.src(srcObj.src);

      // var button = new ResolutionsButton(_vjs);
      // _vjs.controlBar.addChild(button);
    };

    //constructor continued:
    //options
    this.$video_wrapper = $video_wrapper;

    this.media_src_urls = {
      h264_360_url: '',
      h264_720_url: ''
    };

    if (media_src_urls) {
      $.extend(this.media_src_urls, media_src_urls);
    }

    this.subtitles = subtitles || null;
    this.user_state = user_state || {};
    makePlayer();
  }

  return VideoPlayer;
})();

// Trying not to add more complexity above, components are generally generic
// NOTE: This is the older method to add components to videojs
// since v6 they recommend this method: http://docs.videojs.com
KadVideoPlayer.components = {};

/*
 * seekButton
 *
 * Sets the current time of the player ahead or back by the 'interval' option
 * passed in
 *
 * @params Object
 *  player: the reference to the player the button has been added to
 *  interval: the interval, in seconds, to seek
 *  direction: 'back' or 'forward', the direction the button should seek
 */

KadVideoPlayer.components.seekButton = videojs.extend(videojs.getComponent('Button'), {
  constructor: function (options) {
    this.config = options;

    videojs.getComponent('Button').call(this, this.config.player);

    this.config.player.controlBar
      .addChild(this, {}, this.config.indexPosition)
      .addClass('kf')
      .addClass(this.config.cssClass)
      .controlText('Seek ' + s.capitalize(this.config.direction));
  },

  handleClick: function (event) {
    var curTime = this.config.player.currentTime();

    if (this.config.direction == 'back') {
      this.config.player.currentTime(curTime - this.config.interval);
    } else if (this.config.direction == 'forward') {
      this.config.player.currentTime(curTime + this.config.interval);
    }
  }

});

/*
 * skipButton
 *
 * Emits a 'skip' event to react to, direction 'forward' or 'back', and player instance
 * will be exposed.
 *
 * @params Object
 *  player: the reference to the player the button has been added to
 *  direction: 'back' or 'forward', the direction the button should skip
 */

KadVideoPlayer.components.skipButton = videojs.extend(videojs.getComponent('Button'), {
  constructor: function (options) {
    this.config = options;

    videojs.getComponent('Button').call(this, this.config.player);

    this.config.player
      .addChild(this, {})
      .addClass('kf')
      .addClass(this.config.cssClass)
      .addClass('vjs-skip-button')
      .addClass('vjs-skip-' + this.config.direction)
      .controlText('Skip ' + s.capitalize(this.config.direction));
  },

  handleClick: function (event) {
    var triggered = 'skip' + s.capitalize(this.config.direction);
    this.config.player.trigger(triggered, this.config);
  }

});

/*
 * skipToQuestionButton
 *
 * Emits a 'skipForwardToQuestion' or 'skipBackToQuestion' event to react to,
 * direction 'forward' or 'back', and player instance will be exposed.
 *
 * @params Object
 *  player: the reference to the player the button has been added to
 *  direction: 'back' or 'forward', the direction the button should skip
 */

KadVideoPlayer.components.skipToQuestionButton = videojs.extend(videojs.getComponent('Button'), {
  constructor: function (options) {
    this.config = options;

    videojs.getComponent('Button').call(this, this.config.player);

    this.config.player.controlBar
      .addChild(this, {}, this.config.indexPosition)
      .addClass('kf')
      .addClass(this.config.cssClass)
      .controlText('Skip ' + s.capitalize(this.config.direction) + ' To Question');
  },

  handleClick: function (event) {
    var triggered = 'skip' + s.capitalize(this.config.direction) + 'ToQuestion';
    this.config.player.trigger(triggered, this.config);
  }
});

/*
 * ctaModal
 *
 *
 * Modal with a play/locked button, shows copy and a link to
 * prompt user to sign up to watch all videos.
 * Visibility state is controlled by the parentVM.
 * This relies on the modalCtaButton, modalText, & modalPlayButton
 * child components listed below.
 *
 * @params Object
 *  player: the reference to the player the button has been added to
 *  user_type: 'public', 'member', or 'enrolled'
 *  state: 'unlocked' or 'locked'
 *
 */

KadVideoPlayer.components.ctaModal = videojs.extend(videojs.getComponent('Component'), {
  constructor: function (options) {
    var self = this;
    this.config = options;

    this.config.subtitleContent = 'to watch all lessons';

    if(this.config.state == 'unlocked') {
      this.config.titleContent = 'Click To Play';
    } else if (this.config.premium_only) {
      this.config.titleContent = 'This content is only available to premium members';
      this.config.subtitleContent = 'to view this exclusive content';
    } else {
      this.config.titleContent = 'We hope you enjoyed your preview';
    }

    
    var ModalDialog = videojs.getComponent('ModalDialog');
    this.modal = new ModalDialog(this.config.player, {
      temporary: false,
      user_type: this.config.user_type,
    });

    this.createChildren();
    this.addListeners();
  },

  createChildren: function() {
    if(this.config.state == 'locked') {

      // Locked Icon
      new KadVideoPlayer.components.modalLockedContainer({
        player: this.config.player,
        modal: this.modal,
        cssClass: 'vjs-cta-locked'
      });

      // Text Wrapper for Title and Subtitle
      new KadVideoPlayer.components.modalTextContainer({
        player: this.config.player,
        modal: this.modal,
        containerCssClass: 'vjs-modal-text-container',
        titleCssClass: 'vjs-cta-title',
        titleContent: this.config.titleContent,
        subtitleCssClass: 'vjs-cta-container',
        subtitleContent: this.config.subtitleContent,
        state: this.config.state,
        user_type: this.config.user_type,
        premium_only: this.config.premium_only,
        ctaCallback: this.config.ctaCallback
      });

      this.modal.addClass('vjs-modal-hidden');
      if (this.config.premium_only) {
        this.modal.addClass('vjs-premium-locked-modal');
      } else {
        this.modal.addClass('vjs-locked-modal');
      }

      this.config.player.addChild(this.modal);

    } else if (this.config.user_type == 'public') {

      // Play Button
      new KadVideoPlayer.components.modalPlayButton({
        player: this.config.player,
        modal: this.modal,
        cssClass: 'vjs-cta-play-button'
      });

      // Text Wrapper for Title and Subtitle
      new KadVideoPlayer.components.modalTextContainer({
        player: this.config.player,
        modal: this.modal,
        containerCssClass: 'vjs-modal-text-container',
        titleCssClass: 'vjs-cta-title',
        titleContent: this.config.titleContent,
        subtitleCssClass: 'vjs-cta-container',
        subtitleContent: this.config.subtitleContent,
        state: this.config.state,
        user_type: this.config.user_type,
        ctaCallback: this.config.ctaCallback
      });

      this.config.player.addChild(this.modal)
        .addClass('vjs-' + this.config.state + '-modal');
    }
  },

  addListeners: function() {
    var self = this;

    this.config.player.on('play', function() {
      self.modal.hide();
    });

    if(this.config.state == 'unlocked') {
      this.config.player.on('ready', function() {
        self.modal.show();
      });

      this.config.player.on('pause', function() {
        self.modal.show();
      });

      this.config.player.on('ended', function() {
        self.modal.hide();
      });
    } else {
      this.config.player.on('ended', function() {
        $('.vjs-unlocked-modal').hide();
        self.modal.show();
      });
    }
  }
});

/*
 * modalPlayButton
 *
 *
 * Called by ctaModal component, inherits default BigPlayButton
 *
 *
 * @params Object
 *  player: the reference to the player the button has been added to
 *  modal: the reference to the modal the button has been added to
 *  cssClass: Css class that is added to the element
 */

KadVideoPlayer.components.modalPlayButton = videojs.extend(videojs.getComponent('BigPlayButton'), {
  constructor: function(options) {
    var self = this;
    this.config = options;

    videojs.getComponent('BigPlayButton').call(this, this.config.player);

    this.config.modal.addChild(this)
      .addClass(this.config.cssClass);

    this.config.player.on('ended', function() {
      self.addClass('vjs-hidden');
    });

    this.config.player.on('play', function() {
      self.removeClass('vjs-hidden');
    });
  }
});

/*
 * modalTextContainer
 *
 *
 * Called by ctaModal component, contains locked state icon
 *
 *
 * @params Object
 *  player: the reference to the player the button has been added to
 *  modal: the reference to the modal the button has been added to
 *  cssClass: Css class that is added to the element
 */

KadVideoPlayer.components.modalTextContainer = videojs.extend(videojs.getComponent('Component'), {
  constructor: function(options) {
    var self = this;
    this.config = options;

    videojs.getComponent('Component').call(this, this.config.player);

    // Title
    var title = new KadVideoPlayer.components.modalText({
      player: this.config.player,
      modal: this.config.modal,
      cssClass: this.config.titleCssClass,
      content: this.config.titleContent,
      state: this.config.state
    });

    // Link and Subtitle Container
    var subtitle = new KadVideoPlayer.components.modalLinkContainer({
      player: this.config.player,
      modal: this.config.modal,
      cssClass: 'vjs-cta-container',
      content: this.config.subtitleContent,
      user_type: this.config.user_type,
      state: this.config.state,
      premium_only: this.config.premium_only,
      ctaCallback: this.config.ctaCallback
    });

    this.config.modal.addChild(this)
      .addClass(this.config.containerCssClass);

    this.addChild(title);
    this.addChild(subtitle);
  }
});

/*
 * modalLockedContainer
 *
 *
 * Called by ctaModal component, contains text fields
 *
 *
 * @params Object
 *  player: the reference to the player the button has been added to
 *  modal: the reference to the modal the button has been added to
 *  cssClass: Css class that is added to the element
 */

KadVideoPlayer.components.modalLockedContainer = videojs.extend(videojs.getComponent('Component'), {
  constructor: function(options) {
    var self = this;
    this.config = options;

    videojs.getComponent('Component').call(this, this.config.player);

    var outline = new KadVideoPlayer.components.modalLockedIcon({
      player: this.config.player,
      modal: this.config.modal,
      cssClass: 'kf-circle-outline'
    });

    var icon = new KadVideoPlayer.components.modalLockedIcon({
      player: this.config.player,
      modal: this.config.modal,
      cssClass: 'kf-lock'
    });

    this.config.modal.addChild(this)
      .addClass('kf-stack')
      .addClass('kf-stack--circle')
      .addClass(this.config.cssClass);

    this.addChild(outline);
    this.addChild(icon);
  }
});

/*
 * modalLockedIcon
 *
 *
 * Called by modalLockedContainer component
 *
 *
 * @params Object
 *  player: the reference to the player the button has been added to
 *  modal: the reference to the modal the button has been added to
 *  cssClass: Css class that is added to the element
 */

KadVideoPlayer.components.modalLockedIcon = videojs.extend(videojs.getComponent('Component'), {
  constructor: function(options) {
    var self = this;
    this.config = options;

    videojs.getComponent('Component').call(this, this.config.player);

    this.config.modal.addChild(this)
      .addClass('kf')
      .addClass(this.config.cssClass);
  }
});

/*
 * modalLinkContainer
 *
 *
 * Called by ctaModal component, holds cta link and subtitle components
 *
 * @params Object
 *  player: the reference to the player the button has been added to
 *  modal: the reference to the modal the button has been added to
 *  content: The text that is shown inside the button
 *  user_type: 'public', 'member', or 'enrolled'
 *  cssClass: Css class that is added to the element
 */

KadVideoPlayer.components.modalLinkContainer = videojs.extend(videojs.getComponent('Component'), {
  constructor: function(options) {
    this.config = options;

    videojs.getComponent('Component').call(this, this.config.player);

    this.config.modal.addChild(this)
      .addClass(this.config.cssClass);

    var content = this.config.user_type == 'public' ? 'Sign Up' : 'Enroll';
    if (this.config.premium_only) {
      content = 'Upgrade';
    }
 

    // Link
    var link = new KadVideoPlayer.components.modalCtaLink({
      player: this.config.player,
      modal: this.config.modal,
      content: content,
      cssClass: 'vjs-cta-button',
      user_type: this.config.user_type,
      ctaCallback: this.config.ctaCallback
    });
    
    // Subtitle Line
    var copy = new KadVideoPlayer.components.modalText({
      player: this.config.player,
      modal: this.config.modal,
      cssClass: 'vjs-cta-subtitle',
      content: this.config.content,
      state: this.config.state,
      ctaCallback: this.config.ctaCallback
    });

    this.addChild(link);
    this.addChild(copy);
  }
});

/*
 * modalText
 *
 *
 * Called by ctaModal and modalLinkContainer components
 * to create generic elements containing text
 *
 * @params Object
 *  player: the reference to the player the button has been added to
 *  modal: the reference to the modal the button has been added to
 *  content: The text that should be inside the element
 *  state: unlocked or locked state, needed to show/hide correct titles
 *  cssClass: Css class that is added to the element
 */

KadVideoPlayer.components.modalText = videojs.extend(videojs.getComponent('Component'), {
  constructor: function(options) {
    this.config = options;

    videojs.getComponent('Component').call(this, this.config.player);

    this.el().innerHTML = this.config.content;

    if(this.config.state == 'locked') {
      this.config.modal.addChild(this)
        .addClass('vjs-locked')
        .addClass(this.config.cssClass);
    } else {
      this.config.modal.addChild(this)
        .addClass(this.config.cssClass);
    }
  }
});

/*
 * modalCtaLink
 *
 *
 * Called by modalLinkContainer component, creates the CTA href element
 *
 *
 * @params Object
 *  player: the reference to the player the button has been added to
 *  modal: the reference to the modal the button has been added to
 *  cssClass: Css class that is added to the element
 */

KadVideoPlayer.components.modalCtaLink = videojs.extend(videojs.getComponent('Button'), {
  constructor: function(options) {
    this.config = options;

    videojs.getComponent('Button').call(this, this.config.player);
    this.el().innerHTML = this.config.content;

    this.config.modal.addChild(this)
      .addClass('inline-block-link')
      .addClass(this.config.cssClass);
  },

  handleClick: function() {
    // Need to accept a callback function so we can call this from a ko context
    this.config.ctaCallback();
  }
});

export default KadVideoPlayer;
