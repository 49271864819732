import Constants from '../layout_helpers/constants';
import ContentBlocker from '../layout_helpers/content-blocker';
var self, $self, $userMenu, $mmMainContent, $toggle;
var pushMenuWidth, isMobile, timeline;
var debugging = false;

var jslog = function(msg, arg) {
  var txt = '++ '+msg;
  if(arg) {
    txt += ' : '+arg;
  }
  if (debugging) {
    console.log(txt);
  }
};

var initialize = function () {
  jslog('MainMenu.initialize()');
  // Set instances
  self = this;
  $self = $('#js__main-menu');
  $toggle = $('#js__right-nav_toggle');
  var $moreOptions = $('.js-more-options');
  $userMenu = $('#js__user-menu');
  var $userMenuToggle = $('#js__user-dropdown-toggle');

  $mmMainContent = $('#klMain');

  pushMenuWidth = 260;

  // more options menu dropdown event listening for accessibilty
  $('.top-nav__li--more-options').on('show.bs.dropdown', function(event){
    $moreOptions.attr('aria-expanded', 'true');
  });
  $('.top-nav__li--more-options').on('hide.bs.dropdown', function(event){
    $moreOptions.attr('aria-expanded', 'false');
  });
  // user menu dropdown event listening for accessibilty
  $('.dropdown.top-nav__user').on('show.bs.dropdown', function(event){
    $userMenuToggle.attr('aria-expanded', 'true');
  });
  $('.dropdown.top-nav__user').on('hide.bs.dropdown', function(event){
    $userMenuToggle.attr('aria-expanded', 'false');
  });

  // hide "more" dropdown when the user presses esc key
  $('#js__more-menu a').off('keyup.moremenu').on('keyup.moremenu', function(e){
    if(e.which == 27){
      $('#js__more-menu').dropdown('toggle');
    }
  });

  // hide "user" dropdown when the user presses esc key
  $('#js__user-menu a').off('keyup.usermenu').on('keyup.usermenu', function(e){
    if(e.which == 27){
      $userMenu.dropdown('toggle');
    }
  });

  jslog('Kadenze.Layout.getDeviceSize()', Kadenze.Layout.getDeviceSize() );
  if( Kadenze.Layout.getDeviceSize() === Constants.DEVICE_XS ) {
    mobilize();
  } else {
    demobilize();
  }
};

var reset = function(){
  jslog('MainMenu.reset()');
  closeMobileMenu(0);
  self = null;
  isMobile = false;
  removeListeners();
};

var addListeners = function() {
  jslog('MainMenu.addListeners()');
  $toggle.on('click.mobileToggle', function(){
    if( $(this).hasClass('open')) {
      closeMobileMenu(0.3);
    } else {
      openMobileMenu(0.4);
    }
  });
};

var removeListeners = function() {
  jslog('MainMenu.removeListeners()');
  $toggle.off('click.mobileToggle');
};

var mobilize = function() {
  if(isMobile){
    return;
  }

  jslog('MainMenu.mobilize()');
  isMobile = true;
  $('.top-nav__main-nav').css({'display':'block'});
  $userMenu.removeClass('dropdown-menu pull-right');

  addListeners();
};

var demobilize = function() {
  if(!isMobile){
    return;
  }
  jslog('MainMenu.demobilize()');
  isMobile = false;

  $('.top-nav__main-nav').css( {'display':'flex'} );

  $userMenu.addClass('dropdown-menu pull-right');
  if( $toggle.hasClass('open') ) {
    closeMobileMenu(0);
  }
  removeListeners();
};

var closeMobileMenu = function(animationTime) {

  jslog('MainMenu.closeMobileMenu()');
  ContentBlocker.hide();

  var duration = (_.isUndefined(animationTime))?0:animationTime;
  $toggle.removeClass('open');
  $mmMainContent.removeClass('mobileMenuOpened');

  $('#main-nav').css({ position:'' });

  if($('#nav-side-left').length > 0) {
    $('#nav-side-left').show();
  }

  var mainNavDisplay = (isMobile)?'block':'flex';
  $('.top-nav__main-nav').css({
    'display': mainNavDisplay,
    height:'auto'
  });

  if( $mmMainContent ){
    var resetTopPos = $mmMainContent.position().top;
    $mmMainContent.css({'position':'', 'top':'0px', 'width':'100%'});
    window.scrollTo( 0 , Math.abs(resetTopPos) );
  }

  if (!timeline)
    timeline = new TimelineLite();

  //timeline.to($self,duration, {right:(-1 * pushMenuWidth)});
  //timeline.to($mmMainContent, duration, {left:0}, '-='+duration);
};

var openMobileMenu = function(animationTime) {
  jslog('MainMenu.openMobileMenu()');
  // If left nav, hide it.
  if($('#nav-side-left').length > 0) {
    Kadenze.LeftNav.hide(false);
    $('#nav-side-left').hide();
  }

  ContentBlocker.show({'opacity':'.6', 'position':'fixed'}, function () {
    closeMobileMenu(0.3);
  });

  //var duration = (_.isUndefined(animationTime))?0:animationTime;
  $toggle.addClass('open');
  $mmMainContent.addClass('mobileMenuOpened');

  var currentTopPos = $(window).scrollTop();
  $mmMainContent.css({ 'position' : 'fixed', 'top' : -currentTopPos+'px', 'width':'100%' });
  window.scrollTo(0,0);

  var winH = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
  var menuH = $('#js__main-menu').height();

  $('.top-nav__main-nav').css({
    height: Math.max( menuH,winH ),
    display:'block'
  });


  //var tween = TweenLite.to($mmMainContent, duration, {left:(-1 * pushMenuWidth)});
  //$mmMainContent.css({ 'left' : (-1 * pushMenuWidth)+'px' });

  //timeline.to($mmMainContent,duration, {left:(-1 * pushMenuWidth)}, '-=duration');
};

export default {
  init: function(){
    //console.log("Kadenze.MainMenu.init()");
    // If already initialized. Reset before initializing
    if(self) {
      var callInit = _.debounce(initialize, 300);
      reset();
      callInit();
      return;
    } else {
      initialize();
    }
  },
  deviceChange: function( deviceSize ){
    //console.log("Kadenze.MainMenu.deviceChange(" + deviceSize + ")");
    if( $self == null )
      return;
    if( deviceSize == Constants.DEVICE_XS ){
      mobilize();
    } else {
      demobilize();
    }
  },
  open: function(animationTime) {
    //console.log("Kadenze.MainMenu.open(" + animationTime + ")");
    openMobileMenu(animationTime);
  },
  close: function(animationTime) {
    //console.log("Kadenze.MainMenu.close(" + animationTime + ")");
    closeMobileMenu(animationTime);
  }
};
