// Analytics wrapper for Kannu.analytics
//
// Documentation:
// https://kadenze.atlassian.net/wiki/display/KADWEB/Event+Tracking

var isReady = false;
var instance = {};

var setupEventListeners = function() {
  $('[data-kdz-event-type]').each(function() {
    $(this).on('click', function() {
      var event = $(this).data('kdz-event-collection');
      var type = $(this).data('kdz-event-type');
      var params = $(this).data('kdz-event-params');
      Kadenze.Analytics.track(event, type, params);
      return true;
    });
  });
};

// Function for handling oauth logins since that requires an external redirect to complete
var trackRegisterLogin = function() {
  if (typeof OAUTH_REGISTER != 'undefined') {
    Kadenze.Analytics.mpTrack('Register', {auth_type: OAUTH_REGISTER});
  } else if (typeof OAUTH_LOGIN != 'undefined') {
    Kadenze.Analytics.mpTrack('Login', {auth_type: OAUTH_LOGIN});
  }
};

export default {
  init: function(enabled, course_id) {
    var self = this;
    _.extend(this, Kadenze.EventSource); //add event handling capability
    instance.trackingEnabled = enabled;
    instance.course_id = course_id;
    setupEventListeners();
    trackRegisterLogin();
    self.triggerEvent('ready');
  },
  initEventListeners: function () {
    setupEventListeners();
  },
  track: function(collection, type, data) {
    if (instance && instance.trackingEnabled) {
      var event = {
        collection: collection,
        type: type,
        data: data
      };
      var request = new Kadenze.AjaxRequest({
        type: 'POST',
        url:  Kadenze.Routes.track_events_path({event: event, course_id: instance.course_id})
      });
      request.fail(function(data) {
        console.log('Error tracking data');
      });
    } else {
      console.warn('No analytics instance present to track event ' + type);
    }
  },

  mpTrack: function(event_name, props, cb) {
    if (typeof mixpanel != 'undefined') {
      if (Kadenze.TRACK_ANALYTICS) {
        mixpanel.track(event_name, props);
      } else {
        console.log('Mixpanel tracking stub for event ' + event_name + ' with properties: ');
        console.log(props);
      }
    } else {
      console.warn('No mixpanel instance present');
    }
  },

  resetTrackingData: function() {
    if (typeof mixpanel != 'undefined' && Kadenze.TRACK_ANALYTICS) {
      mixpanel.reset();
    }
  }
};
