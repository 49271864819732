import ResponsiveDecorator from '../iframe_decorators/responsive-decorator';
import DataDecorator from '../iframe_decorators/data-decorator';
import EventDecorator from '../iframe_decorators/event_decorators/event-decorator';

export default {
  makeResponsive: function(params) {
    return new ResponsiveDecorator(params).decorate();
  },

  addAction: function(params) {
    return new EventDecorator(params).decorate();
  },

  decorateForActions: function(params) {
    return new DataDecorator(params).decorate();
  },

  decorateAll: function() {
    this.decorateAllActions();
    this.decorateAllResponsive();
  },

  decorateAllActions: function() {
    let self = this;
    var $iframes = $('iframe[data-enable-fullscreen], iframe[data-enable-new-tab], iframe[data-enable-modal]');
    $iframes.each(function(i, iframe) {
      if(iframe.dataset.hasActions) return;
      var config = _.extend({ iframe: iframe }, iframe.dataset);
      self.addAction(config);
      iframe.dataset.hasActions = true;
    });
  },

  decorateAllResponsive: function() {
    let self = this;
    var $iframes = $('iframe[data-responsive]');
    $iframes.each(function(i, iframe) {
      if(iframe.dataset.isResponsive) return;
      self.makeResponsive({ iframe: iframe, aspectRatio: iframe.dataset.responsive });
      iframe.dataset.isResponsive = true;
    });
  },
};
