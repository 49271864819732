//A mixin for enhancing an object with event generation capabilities (promoting loose coupling).
//Use some flavor of extend(), for example, to add it to your object.

export default {
  events: {},

  addEventListener(eventName, callback) {
    this.events[eventName] = this.events[eventName] || [];
    this.events[eventName].push(callback);
  },

  removeEventListener(eventName, callback) {
    if (!eventName && !callback) return

    const callbacks = this.events[eventName];

    if (callbacks) {
      for(let i = 0; i < callbacks.length; i++) {
        if(callbacks[i] === callback)
          this.events[eventName].splice(i, 1);
      }
    }
  },

  triggerEvent(eventName, eventData = {}) {
    const evt = {
      type: eventName,
      target: this,
      data: {},
      ...eventData
    }

    const callbacks = this.events[eventName];

    if (callbacks) {
      const executeCallback = (cb, evt) => cb(evt)

      for(let i = 0; i < callbacks.length; i++)
        setTimeout(executeCallback, 0, callbacks[i], evt);
    }
  }
};
