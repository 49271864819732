import * as Hammer from 'hammerjs';
/*
 * Kadenze.Notice
 *
 * Displays a notice to the user
 *
 *      var myNotificaion = new Kadenze.Notice(myParams);
 *
 *      // Shortcut syntax
 *      var error = new Kadenze.Notice.error(myParams);
 *      var success = new Kadenze.Notice.success(myParams);
 *      var info = new Kadenze.Notice.info(myParams);
 *      var attention = new Kadenze.Notice.attention(myParams);
 *
 * @params: Object
 *   @title {String}  - title of the notice
 *   @message {String, Array} - message content, if this is an array, each array item will be laid out in an ordered list
 *   @type {String} - can be: 'attention', 'danger', 'info', 'success'
 *   @dismissible {Boolean} - true will display the 'x', false will not and cause the notice to fade out on its own
 *   @persistent {Boolean} - true will cause the notice to NOT fade out BUT *   still have an 'x' to manually dismiss
 *   @parentObject {String} - jQuery selector of the node to insert the notice into, this is null by default, the notice will appear as 'growl' style
 *   @template {String} - JST valid path to a template
 *   @fadeTime {Integer} - time in milliseconds for fading in / out
 *   @timeout {Integer} - how long to wait before automatically fading out, 'dismissible' must be false first
 *   @identifierClass {String} - class the identifies the template markup, see 'javascripts/templates/notice_ko' for an example
 *   @onDismiss {Function ( controlData )} - callback function that is called when the notice is dismissed
 *   @controls {Object} - config for checkbox control
 *     @show {Boolean} - whether or not to show the controls
 *     @label {String} - label for the checkbox
 *     @name {String} - name of the checkbox, really just for semantics
*/

let Notice = function(params) {
  var self = this;

  self.defaults = {
    title: '',
    message: '',
    type: 'attention',
    dismissible: true,
    persistent: false,
    parentObject: null,
    template: 'templates/notice_ko',
    fadeTime: 500,
    timeout: 8000,
    identifierClass: '.notice',
    method: 'prepend',
    onDismiss: function() {},
    applyBindings: true,
    controls: {
      show: false,
      label: 'Please don’t show me this anymore, I get it',
      name: 'dismiss-permanently'
    }
  };

  self.config = $.extend(true, {}, self.defaults, params);

  self.title = ko.observable(self.config.title);
  self.message = ko.observable(self.config.message);
  self.messages = ko.observableArray();
  self.messageIsList = ko.observable(false);
  self.type = ko.observable(self.config.type);
  self.dismissible = ko.observable(self.config.dismissible);
  self.persistent = ko.observable(self.config.persistent);
  self.controls = ko.mapping.fromJS(self.config.controls);
  self.$parentObject = $(self.config.parentObject);
  self.template = $(JST[self.config.template]())[0];
  self.timeoutInstance = null;
  self.swipeManager = null;

  self.cssClass = ko.computed(function() {
    return 'notice notice-' + self.type();
  });

  self.notMobile = ko.computed(function() {
    return !Kadenze.UIUtil.isMobileApp();
  });

  self.UUID = ko.computed(function() {
    // faking "UUID" may need to make this more robust
    // and extract out to a utility class
    return Date.now() + '-' + Math.floor(Math.random()*100);
  });

  self.controlIdentifier = ko.computed(function() {
    return self.UUID() + '-control';
  });

  self.init = function() {
    // Allow binding to be skipped if this needs to be used
    // as a child to another viewModel
    if(self.config.applyBindings) {
      // Apply bindings to inserted template
      ko.cleanNode(self.template);
      ko.applyBindings(self, self.template);
    }

    // Insert the template
    self.render();

    // Determine which object to get, if we prepend we get the first else the last
    var getMethod = self.config.method === 'prepend' ? 'first' : 'last';

    // Save reference to jQuery object
    var nodeID = self.UUID();
    self.$node = $('#' + nodeID);

    // Start hidden so we can fade in
    self.hide();

    // Determine content format, displaying a list?
    self.checkMessageStructure();

    // Fade in and check if we should fade out automatically
    self.show();

    var swipeTarget = document.getElementById(nodeID);
    self.swipeManager = new Hammer.Manager(swipeTarget);
    var Swipe = new Hammer.Swipe();
    self.swipeManager.add(Swipe);

    self.swipeManager.on('swipe', function(e) {
      var direction = e.offsetDirection;
      if (direction === 4) {
        self.fadeSwipe(50);
      }
      if (direction === 2) {
        self.fadeSwipe(-50);
      }
    });
  };

  self.render = function() {
    if(self.$parentObject.length) {
      // Insert into the first matched parent only
      self.$parentObject.first()[self.config.method](self.template);

    } else {
      // Show as growl style if no parentObject is specified
      self.renderAsGrowl();
    }
  };

  self.renderAsGrowl = function() {
    var $growler;
    if ($('#notice-growler').length) {
      $growler = $('#notice-growler');
    } else {
      $growler = $('<div/>', { 'id': 'notice-growler', 'role': 'alert', 'aria-live': 'assertive' });
      $('body').append($growler);
    }

    self.$parentObject = $growler;
    self.config.method = 'append';
    self.render();
    self.positionGrowl();
    self.watchGrowlPosition();
  };

  self.show = function() {
    self.$node.fadeIn(self.config.fadeTime, self.checkIfDismissible);
  };

  self.fadeSwipe = function(xOffset) {
    TweenLite.to(self.$node, .3, {
      autoAlpha:0,
      x: '+='+xOffset,
      onComplete: function(){
        self.$node.remove;
      }
    });

  };

  self.hide = function() {
    self.$node.hide();
  };

  self.remove = function() {
    // Cleanup timeout
    if(self.timeoutInstance)
      clearTimeout(self.timeoutInstance);

    // Call onDismiss callback with control data
    self.config.onDismiss.call(self, self.getControlData());

    self.$node.fadeOut(self.config.fadeTime, function() {
      $(this).remove();
    });
  };

  // Remove without fading if need be
  self.destroy = function() {
    self.$node.remove();
  };

  self.checkIfDismissible = function() {
    if(self.dismissible() && !self.persistent()) {
      self.timeoutInstance = setTimeout(self.remove, self.config.timeout);
    }
  };

  self.checkMessageStructure = function() {
    var message = ko.utils.unwrapObservable(self.message());

    if(message instanceof Array) {
      self.messageIsList(true);
      self.messages(message);
    }
  };

  self.getControlData = function() {
    var $control = self.$node.find('#' + self.controlIdentifier()),
      controlValue = $control.is(':checked');

    return { controlChecked: controlValue };
  };

  self.watchGrowlPosition = function() {
    var throttled = _.throttle(self.positionGrowl, 200);
    $(window).off('scroll.notice').on('scroll.notice', throttled);
  };

  self.positionGrowl = function() {
    if(window.scrollY >= $('#main-nav').height()) {
      self.$parentObject.removeClass('pushed');
    } else {
      self.$parentObject.addClass('pushed');
    }
  };

  self.init();

  return self;

};

/*
 * A little shortcut API
*/

Notice.error = function(params) {
  var settings;
  if (params === null) {
    params = {};
  }

  settings = {
    type: 'error',
    title: `${I18n.t('dict.error')}!`,
    timeout: 8000 // A bit longer to read the error
  };

  return new Notice($.extend(true, {}, settings, params));
};

Notice.success = function(params) {
  var settings;
  if (params === null) {
    params = {};
  }

  settings = {
    type: 'success',
    title: `${I18n.t('success')}!`
  };

  return new Notice($.extend(true, {}, settings, params));
};

Notice.attention = function(params) {
  var settings;
  if (params === null) {
    params = {};
  }

  settings = {
    type: 'attention',
    title: `${I18n.t('attention')}!`
  };

  return new Notice($.extend(true, {}, settings, params));
};

Notice.info = function(params) {
  var settings;
  if (params === null) {
    params = {};
  }

  settings = {
    type: 'info',
    title: I18n.t('information')
  };

  return new Notice($.extend(true, {}, settings, params));
};

export default Notice;
