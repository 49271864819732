export default {
  init(node) {
    const config = {
      panelOneVisible: {
        infinite: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        dots: true,
        responsive: [
          {
            breakpoint: 992,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              arrows: false
            }
          }
        ]
      },

      arrowsOneVisible: {
        infinite: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 1,
        arrows:true
      },

      kadenzecHomepageMyCourses: {
        infinite: false,
        accessibility: false,
        draggable: false,
        slidesToShow: 3,
        slidesToScroll: 3,
        prevArrow: JST['templates/carousel-control']({ control_type: 'prev' }),
        nextArrow: JST['templates/carousel-control']({ control_type: 'next' }),
        responsive: [
          {
            breakpoint: 1200,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1
            }
          },

          {
            breakpoint: 991,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              dots: true
            }
          }
        ]
      },

      studentQuizQuestionNav: {
        infinite: true,
        slidesToShow: 3,
        slidesToScroll: 3,
        responsive: [
          {
            breakpoint: 1200,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1
            }
          },

          {
            breakpoint: 991,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              dots: true
            }
          }
        ]
      },

      ticker: {
        accessibility: false,
        draggable: false,
        slidesToShow: 4,
        slidesToScroll: 4,
        arrows: false,
        autoplay: true,
        cssEase: 'ease-in-out',
        autoplaySpeed: 2500,
        slide: 'a',
        responsive: [
          {
            breakpoint: 1050,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 3
            }
          },

          {
            breakpoint: 760,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2
            }
          },

          {
            breakpoint: 540,
            settings: {
              autoplaySpeed: 2500,
              slidesToShow: 1,
              slidesToScroll: 1
            }
          }
        ]
      },

      verticalNav:{
        slidesToShow: 3,
        slidesToScroll: 2,
        vertical: true,
        infinite: false
      }
    }; // End self.config

    const delegateEvents = element => {
      const carouselConfig = $(element).data('carousel-type')
      const $el = $(element)

      //
      // http://kenwheeler.github.io/slick/
      // event bindings
      //
      $el.on('beforeChange', function(event, slick, currentSlide, nextSlide){
        //
        // Sets the 'aria-live' attribute to 'off'. If aria-live is 'polite',
        // then the screen reader will announce the contents of each slide of
        // an autoplay carousel as they slide in - a bad experience for
        // screen reader users
        //
        // The 'beforeChange' is triggered before the carousel changes slides.
        // This may not be the best event to bind to since it's triggered
        // multiple times, but binding to the init or reInit didn't work
        //
        // (something changed aria-live back to polite after init triggered,
        //  and reInit was never triggered)
        //
        $('.slick-list', this).attr('aria-live', 'off');

        //
        // slick-active-last is used to hide the right border on the
        // rightmost active (visible) slide. Beforechange, we want to remove
        // all slick-active-last classes so we don't have duplicate instances
        //
        $('.slick-slide').removeClass('slick-active-last');
      });

      $el.on('afterChange', (event, slick) => {
        //
        // slick-active-last is used to hide the right border
        // on the rightmost active (visible) slide
        //
        $('.slick-active').last().addClass('slick-active-last');

        $el.find('.slick-slide').find('a, btn').attr('tabindex', '-1');
        $el.find('.slick-slide.slick-active').find('a, btn').attr('tabindex', '');
        $el.find('.slick-dots li').attr('aria-hidden', 'false');
      });

      $el.on('init', (event, slick) => {
        //
        // slick-active-last is used to hide the right border
        // on the rightmost active (visible) slide
        //
        $('.slick-active').last().addClass('slick-active-last');

        $el.find('.slick-slide').find('a, btn').attr('tabindex', '-1');
        $el.find('.slick-slide.slick-active').find('a, btn').attr('tabindex', '');
        $el.find('.slick-list').attr('aria-live', 'off');
        $el.find('.slick-dots button').prepend('carousel page');
        $el.find('.slick-dots li').attr('aria-hidden', 'false');
      });

      //
      // initialize slick
      //
      $el.slick(config[carouselConfig]);
    };

    //
    // Attach various event handlers
    //
    delegateEvents(node);
  }
};
