/*
* Kadenze.NavGuard
*
* Utility to guard against end-user page navigation
*
*      Kadenze.NavGuard.init();
*
*/

var disabled = false;

var addRandomHash = function() {
  // This will harmlessly change the url hash to "#random",
  // which will trigger onhashchange when they hit the back button
  if (_.isEmpty(location.hash)) {
    var random_hash = '#ng-' + new Date().getTime().toString(36);

    // Push "#random" onto the history, making it the most recent "page"
    window.history.pushState({navGuard: true}, '', random_hash);
  }
};

var enableGuard = function() {
  var msg = Kadenze.I18n.t('notice.nav_guard_warning');

  addRandomHash();

  $(window).off('hashchange.ng').on('hashchange.ng', function(event) {
    if (_.isEmpty(location.hash)) {
      if (disabled) {
        setTimeout(function(){ window.history.back(); }, 500);
        return false;
      } else {
        var result = confirm(msg);
        if (result) {
          //Go back to where they were trying to go
          //Only go back if there is something to go back to
          if (window.history.length > 2) {
            window.history.back();
          }
        } else {
          // Put the hash back in; rinse and repeat
          window.history.forward();
        }
      }
    }
  });

  $(window).off('beforeunload.ng').on('beforeunload.ng', function(event) {
    if (!disabled) {
      return msg;
    }
  });

  //If navigating within app without pjax, don't show beforeunload warning (unless link has a .js-nav-guard class)
  $('a').not('a:not([href]),[href^="#"],[href^="javascript"],[class^="js-nav-guard"]').mousedown(function() {
    $(window).off('beforeunload.ng');
  });

  //If something is explicitly set to ignore nav guard, don't show beforeunload warning
  $('.js-skip-nav-guard').mousedown(function() {
    $(window).off('beforeunload.ng');
  });
};

// const __construct = function(that) {
//   console.log('constructor called for NavGuard');
// }(this);

export default {
  isFakeNav: function() {
    var isFake = (Kadenze.prevPath == document.location.pathname && s.startsWith(Kadenze.prevHash, '#ng-'));
    if (!isFake) {
      Kadenze.prevPath = document.location.pathname;
      Kadenze.prevHash = document.location.hash;
    }
    return isFake;
  },
  destroy: function() {
    $(window).off('hashchange.ng');
    $(window).off('beforeunload.ng');
  },
  disable: function (value) {
    console.log('disabled from: ', disabled, value);
    disabled = value;
  },
  init: function() {
    var history_api = typeof history.pushState !== 'undefined';
    if (history_api) {
      enableGuard();
    }
    return this;
  }
};
