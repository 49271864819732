var $loMainContent, $contentBlocker, $main, $navHeader;
var deviceSize;
var isInitialized = false;

var initializeLayout = function() {
  deviceSize = null;
  $loMainContent = $('#main-container');
  $main = $('#main');
  $navHeader = $('#main-nav');

  // creates a semi transparent screen to block content when mobile menus are open
  $contentBlocker = $( document.createElement('div') );
  $contentBlocker.addClass('content-blocker');
  $('#main-container').append( $contentBlocker );

  // trigger page resize handler
  evalDeviseSize();
  onPageResize();

  // set resize listener
  $(window).resize(onPageResize);
  isInitialized = true;

  if( Kadenze.UIUtil.isMobileApp() || Kadenze.Layout.isDeviceSize('xs')) {
    // this is to force software keyboard to close on mobile when hitting return.
    // without it notification growls get hidden under the keyboard.
    $(document).on('keyup', 'input', function(event) {
      if(event.keyCode == 13) {
        $('input').blur();
      }
    });
  }

  Kadenze.Layout.update()
};

var isDeviceSize = function( str ){
  return $('.device-' + str).is(':visible');
};

var getDeviceGridSize = function(){
  var val,
    sizes = ['xs','sm','md','lg'];
  $.each( sizes,function(i){
    if( isDeviceSize( sizes[i] ) ){
      val = sizes[i];
      return false;
    }
  });
  return val;
};

/* DISABLE/ENABLE CONTENT FOR MOBILE MENUS
--------------------------------------------*/
var disableContentScroll = function() {
  var headerHeight = $navHeader.height();
  var currentTopPos = $(window).scrollTop();
  currentTopPos =  currentTopPos - headerHeight;
  $main.css({ 'position' : 'fixed', 'top' : -currentTopPos+'px', 'width':'100%' });
  window.scrollTo(0,0);
};

var restoreContentScroll = function( ) {
  if( $main ){
    var headerHeight = $navHeader.height();
    var resetTopPos = $main.position().top;
    resetTopPos = resetTopPos - headerHeight;
    $main.css({'position':'', 'top':'0px', 'width':'100%'});
    window.scrollTo( 0 , Math.abs(resetTopPos) );
  }
};

/* LISTENERS
--------------------------------------------*/
// handler for when the bootstrap device size changes. ie. xs -> sm
var onDeviceSizeChange = function( newSize ){
  deviceSize = newSize;
  Kadenze.LeftNav.deviceChange( newSize );
  Kadenze.MainMenu.deviceChange( newSize );
};

var maxPageHeight = function() {
  return Math.max( document.body.scrollHeight, document.body.offsetHeight, document.documentElement.clientHeight, document.documentElement.scrollHeight, document.documentElement.offsetHeight );
};

var onPageResize = _.debounce( function() {
  // Determine Device Size
  evalDeviseSize();
  // Determine page height
  var pHeight = maxPageHeight();
}, 100 );

var evalDeviseSize = function(){
  var currentSize = getDeviceGridSize();
  if( deviceSize != currentSize ){
    onDeviceSizeChange( currentSize );
  }
};

export default {
  init: initializeLayout,
  update: function(){
    // if layout not initialized
    if( isInitialized == false ){
      return;
    }

    evalDeviseSize();
    onPageResize();
  },
  getDeviceSize : function( ) { return deviceSize; },
  isDeviceSize : function( alias ) {
    return isDeviceSize(alias);
  },
  disableMainContentScroll: function(){disableContentScroll();},
  restoreMainContentScroll: function(){restoreContentScroll();},
  maxPageHeight: maxPageHeight
};
