export default function($element, message, uploadURL, callbacks, curFileObj, options) {
  var $msg = $(message);
  var $clickableArea = ( $('.dz-clickable', $msg).length > 0 ) ? $('.dz-clickable:first', $msg) : $msg;
  var self = this;
  var $el = $($element);
  $el.addClass('dz-unit');
  $el.append($msg);

  var dzpreview = JST['templates/dz_img-preview']({
    remove: Kadenze.I18n.t('file_preview.remove_file')
  });

  if( !options ) {
    options = {};
  }

  var dzOptions = {
    url: uploadURL,
    method: options.method ? options.method : 'POST',
    headers: {
      'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content')
    },
    clickable: $clickableArea[0],
    thumbnailWidth: 300,
    thumbnailHeight: 168,
    previewTemplate: options.dzPreview || dzpreview,
    maxFiles: 1,
    maxFilesize: options.maxFilesize || 10, // MB
    autoProcessQueue: ( options.autoProcessQueue != null ) ? options.autoProcessQueue : true,
    acceptedFiles: options.acceptedFiles ? options.acceptedFiles : '.jpeg,.jpg,.png,.gif,.JPEG,.JPG,.PNG,.GIF',
    paramName: options.paramName ? options.paramName : 'file',
    useKilobytes: true, // custom option added to dropzone. Contacting dev to see if they'll add this feature
    init: function() {
      this.on('thumbnail', function(file, data) {
        if (callbacks.thumbnail) {
          callbacks.thumbnail(file, data);
        }
        if (this.options.cropOptions) {
          if (!file.cropped) {
            var fileType = '.'+file.type.split('/')[1];
            var acceptedFilesArray = this.options.acceptedFiles.split(/,\s*/);
            if(_.contains(acceptedFilesArray, fileType)){
              Kadenze.DropzoneUtils.showCropper(this, this.options.cropOptions);
            }
          }
        }
      });

      this.on('sending', function(file, xhr, formData) {
        if (callbacks.sending) {
          callbacks.sending(file, xhr, formData);
        }
      });

      this.on('success', function(file, response) {
        $('.dz-progress').hide();
        if (callbacks.success) {
          callbacks.success(file, response);
        }
        if(options.previewable) {
          Kadenze.DropzoneUtils.initImagePreview(this, file, response.path, this.options.dzName);
        }
      });

      this.on('error', function(file, errorMessage) {
        if (callbacks.error) {
          callbacks.error(file, errorMessage);
        }
      });

      this.on('complete', function(file) {
        $('.dz-progress').hide();
        if (callbacks.complete) {
          callbacks.complete(file);
        }
        $('.js-dz-show-on-complete').show();
      });

      this.on('addedfile', function(file, data) {
        console.log('ImageDropZone.on(\'addedfile\')' );
        if (callbacks.addedfile) {
          callbacks.addedfile(file);
        }
        if(this.options.previewable) {
          var path = file.path || file.url;
          Kadenze.DropzoneUtils.initImagePreview(this, file, path, this.options.dzName);
        }
      });

      this.on('removedfile', function(file) {
        if (callbacks.removedfile) {
          callbacks.removedfile(file);
        }
      });

      this.on('drop', function(event) {
        if (callbacks.drop) {
          callbacks.drop(event);
        }
      });

      this.on('maxfilesexceeded', function(file){
        this.removeAllFiles();
        this.addFile(file);
      });
    }
  };

  dzOptions = _.extend({}, dzOptions, options);

  var dz = new Dropzone('div#' + $el.attr('id'), dzOptions);

  // initializes tooltips within dropzone
  setTimeout(function(){
    Kadenze.WebApp.initKadenzeTooltips();
  }, 500);

  //TODO: SET THIS WITH JSON/Knockout
  if ( curFileObj && curFileObj.path ) {
    var fileObj = {
      name: curFileObj.name || curFileObj.path,
      size: curFileObj.size || 0,
      status: 'success',
      title: curFileObj.title || '',
      cropped: true,
      path: curFileObj.path
    };
    dz.emit('addedfile', fileObj);
    dz.emit('thumbnail', fileObj, curFileObj.path);
    dz.emit('complete', fileObj);
    dz.files.push( fileObj );
  }

  return dz;
}
