export default function(params) {
  var self = this;

  self.defaults = {
    $iframe: $(params.iframe),
    data: {
      enableFullscreen: false,
      enableNewTab: false,
      enableModal: false,
    }
  };

  self.config = _.extend({}, self.defaults, params);

  self.decorate = function()  {
    _.each(self.config.data, self.addDataAttributes);
    return self.config.$iframe;
  };

  self.addDataAttributes = function(value, key) {
    var friendlyName = 'data-' + s.dasherize(key);
    self.config.$iframe.attr(friendlyName, value);
  };

  return self;
}
