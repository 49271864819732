import Modal from './../components/modal';

export default function (params) {
  var self = this;

  self.defaults = {
    shareableObj: {},
    modal_title: 'Share',
    modal_description: null,
    emailShareCb: null,
    privateSharing: false,
    attrs: {
      title: 'title',
      description: 'description',
      url: 'share_url'
    }
  };

  self.$modal;
  self.config = _.extend({}, self.defaults, params);

  self.modal_title = ko.observable(self.config.modal_title);
  self.modal_description = ko.observable(self.config.modal_description);
  self.privateSharing = ko.observable(self.config.privateSharing);

  self.shareEmails = ko.observable();
  self.emailSharing = ko.observable(false);

  self.share_title = ko.observable(self.config.shareableObj[self.config.attrs.title]);

  self.share_desc = ko.observable(self.config.shareableObj[self.config.attrs.description]);

  self.share_url = ko.observable(self.config.shareableObj[self.config.attrs.url]);
  self.share_url_copied = ko.observable(false);

  self.init = function () {
    self.$modal = Modal.for('share');

    ko.applyBindingsWithValidation(
      self,
      self.$modal[0],
      Kadenze.Knockout.DEFAULT_VALIDATION_OPTIONS
    );

    self.$modal.on('shown.bs.modal', function (e) {
      Kadenze.Share.init();

      Kadenze.KdForms().update();

      var clipboard = new Clipboard('#js-copy-url');

      clipboard.on('success', function (e) {
        self.share_url_copied(true);
      });
    });

    self.$modal.on('hidden.bs.modal', function (e) {
      self.$modal.remove();
    });
  };

  self.show = function (item, event) {
    self.$modal.modal('show');
  };

  self.hide = function (item, event) {
    self.$modal.modal('hide');
  };

  self.showEmailScreen = function (item, event) {
    self.emailSharing(true);
  };

  self.hideEmailScreen = function (item, event) {
    self.emailSharing(false);
    self.shareEmails('');
  };

  self.sendShareEmail = function (item, event) {
    var client_errors = ko.validation.group([self.shareEmails], {deep: true});
    if (_.compact(client_errors()).length === 0) {
      var emails = _.compact(self.shareEmails().split(','));
      if (emails.length <= 5) {
        if (typeof self.config.emailShareCb === 'function') {
          self.config.emailShareCb.call(this, self.config.shareableObj, emails);
        } else {
          console.warn('No e-mail function specified in the configuration. No e-mails were sent :(');
        }
        self.hideEmailScreen(null, event);
        self.hide();
      } else {
        new Kadenze.Notice.error({message: 'Please limit the number of emails to 5'});
      }
    } else {
      client_errors.showAllMessages();
      new Kadenze.Notice.error({message: client_errors()[0]});
    }
  };

  self.areEmailsValid = function (emails) {
    if (_.isEmpty(emails)) {
      return false;
    } else {
      return _.every(_.compact(emails.split(',')), function (email) {
        return is.email(email);
      });
    }
  };

  self.init();

  return self;
}
