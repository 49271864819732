var self = Kadenze.SVGIcon || {};

// Gets width and height based on elements' parent
// Note, the parent must have width and height set
self.extractDimensions = function(element) {
  var $el = $(element).parent();

  return {
    w: $el.width(),
    h: $el.height()
  };
};

export default {

  init: function(node) {
    // Don't duplicate icons
    if ($(node).data('hasSVGIcon') || $(node).children('svg').length)
      return;

    const options = {
      element: node,
      viewBox: $(node).data('viewbox'),
      size: self.extractDimensions(node)
    };

    if($(node).data('svg-width'))
      options.size.w = $(node).data('svg-width');
    if($(node).data('svg-width'))
      options.size.h = $(node).data('svg-height');

    $(node).data('hasSVGIcon', true);

    return new SVGIconFactory(options);
  }
};

let SVGIconFactory = function(options) {
  var self = this;

  self.defaults = {
    element: null,
    viewBox: null,
    speed: 200,
    easing: mina.easeout,
    evtoggle: 'mouseover',
    size: {
      w: 25,
      h: 25
    }
  };

  self.config = _.extend(self.defaults, options);

  self.init = function() {
    //
    // http://tympanus.net/codrops/2013/11/05/animated-svg-icons-with-snap-svg/
    //
    if (self.config.element)
      self.icon = new svgIcon(
        self.config.element,
        Kadenze.SVGIconConfig,
        self.config
      );
  };

  self.init();

  return self;
}; // End Kadenze.SVGIconFactory
