import Quill from 'quill';

import hljs from 'highlight.js';

import rangy from 'rangy/lib/rangy-selectionsaverestore.js';
import { htmlEditButton } from 'quill-html-edit-button';

import horizontalRule from '!html-loader!quill/assets/icons/horizontal-rule.svg';
import fileUpload from '!html-loader!quill/assets/icons/embed.svg';

import QuillResize from 'quill-resize-module-fix';

import quillTable from 'quill-table';

import Emoji from "quill-emoji";

Quill.register("modules/emoji", Emoji);

window.Quill = Quill
Quill.register('modules/htmlEditButton', htmlEditButton);
Quill.register('modules/resize', QuillResize);

Quill.register(quillTable.TableCell);
Quill.register(quillTable.TableRow);
Quill.register(quillTable.Table);
Quill.register(quillTable.Contain);
Quill.register("modules/table", quillTable.TableModule);

var maxRows = 10;
var maxCols = 5;
var tableOptions = [];
for (var r = 1; r <= maxRows; r++) {
  for (var c = 1; c <= maxCols; c++) {
    tableOptions.push("newtable_" + r + "_" + c);
  }
}

const Block = Quill.import('blots/block');
Block.tagName = 'DIV';
Quill.register(Block, true);

const Embed = Quill.import('blots/block/embed');

class Hr extends Embed {
  static create(value) {
    const node = super.create(value);
    node.setAttribute('class', 'ql-hr-tag');
    return node;
  }
}

Hr.blotName = 'hr';
Hr.className = 'quill-hr';
Hr.tagName = 'hr';

Quill.register('formats/hr', Hr);

class KadQuill {
  constructor(element, options) {
    this.init = (node, _value, selector, attrs) => {
      //
      // Legacy support for knockout-based editor
      // Vue editor passes the element and options to the constructor
      //
      node = node || element
      selector = selector || element
      attrs = attrs || options

      hljs.configure({
        languages: [
          'javascript', 'ruby', 'python',
          'cpp', 'css', 'haml', 'erb',
          'html', 'json', 'scss',
          'shell', 'yaml', 'plaintext'
        ]
      });

      const toolbar = this.getToolbar(attrs.toolbarType)

      const {
        key,
        maxLength,
        autoSave,
        required,
        placeholder,
      } = attrs

      const uploaderClass = `.${attrs.uploaderClass}`;

      let response;
      let fileName;

      const customHrHandler = () => {
        const range = quill.getSelection();

        if (range)
          quill.insertEmbed(range.index, 'hr', 'null');
      };

      const toolbarOptions = {
        container: toolbar,
        handlers: {
          'hr': value => {
            if (value)
              customHrHandler();
          },

          'omega': () => {
            console.log('omega is clicked');
          },

          'file-upload': value => {
            if (value) {
              document.querySelectorAll(uploaderClass).forEach(el => {
                el.value = null
                el.click();
              })
            }
          }
        }
      };

      //
      // Below: legacy support for knockout-based editors
      // still relying on css class
      // Otherwise, quill.vue passed element to constructor
      //
      const quillSelector = element || `.${selector}`

      const quill = new Quill(quillSelector, {
        theme: 'snow',

        syntax: true,

        modules: {
          toolbar: toolbarOptions,
          htmlEditButton: {},
          resize: {},
          table: true,
          clipboard: {
            matchVisual: false
          },
          "emoji-toolbar": true,
          "emoji-textarea": true,
          "emoji-shortname": true,
        },
        placeholder,

        bounds: document.body
      });

      const parentEl = quill.container.parentElement

      document.querySelectorAll(uploaderClass).forEach(el => {
        el.addEventListener('change', () => {
          const formData = new FormData();

          const file = parentEl.querySelector(uploaderClass).files[0];

          if (file) {
            fileName = file.name;
            formData.append('file', file);

            const xhr = new XMLHttpRequest();

            setTimeout(() => {
              xhr.open('POST', '/images/upload', true);

              xhr.setRequestHeader(
                'X-CSRF-Token',
                document.querySelector('meta[name="csrf-token"]')
                  .getAttribute('content')
              );

              xhr.setRequestHeader('Accept', 'application/json');

              xhr.onload = () => {
                response = jQuery.parseJSON(xhr.responseText).path;

                if (response) {
                  quill.root.innerHTML += `<a href=${response}>${fileName}</a>`;
                  response = null;
                  fileName = null;
                }
              };

              xhr.send(formData);
            }, 500);
          }
        })
      })

      const quillHr = parentEl.querySelector('.ql-hr')

      if (quillHr)
        quillHr.innerHTML = horizontalRule

      if (attrs.uploaderClass && toolbar.indexOf('file-upload') != -1)
        parentEl.querySelector('.ql-file-upload').innerHTML = fileUpload;

      //
      // this is required because multiple initialization of
      // html button takes place in single toolbar
      //

      const quillHtmlElement = parentEl.querySelector('.ql-html-popupContainer')

      if (parentEl.querySelectorAll('.ql-toolbar').length)
        quill
          .container
          .parentElement
          .querySelectorAll('.ql-toolbar')
          .forEach(block => {
            const htmlEditButtons = block
              .querySelectorAll('[title="Show HTML source"]');

            if (htmlEditButtons.length > 1)
              htmlEditButtons[htmlEditButtons.length - 1].remove();
          });


      const htmlButton = parentEl.querySelector('[title="Show HTML source"]');

      htmlButton.addEventListener('click', () => {
        document.querySelector('.ql-html-buttonCancel').classList.add('btn--del', 'btn--outline', 'btn--xs');
        document.querySelector('.ql-html-buttonGroup').childNodes[1].classList.add('btn--prim', 'btn--xs');
      });


      const customButton = parentEl.querySelector('.ql-omega');

      customButton.addEventListener('click', () => {
        parentEl.classList.toggle('ql-fullscreen-tag');
        document.querySelector('body').classList.toggle('display-none');
      });

      quill.setHTML = html => quill.root.innerHTML = html

      quill.addErrorCSSOnRequired = () => {
        if (required && quill.root.textContent == '') {
          node.querySelectorAll('.ql-editor').forEach(
            el2 => el2.classList.add('ql-missing-field-error')
          )

          document.querySelectorAll('.quill-required-error')
            .forEach(el => el.classList.remove('hide'))
        } else {
          node.querySelectorAll('.ql-editor').forEach(
            el2 => el2.classList.remove('ql-missing-field-error')
          )

          document.querySelectorAll('.quill-required-error')
            .forEach(el => el.classList.add('hide'))
        }
      };

      quill.highlightCodeBlock = () => {
        document.querySelectorAll('pre').forEach(block => {
          hljs.highlightBlock(block);
        });
      };

      quill.root.querySelectorAll('pre').forEach(ele => {
        ele.classList.add('ql-syntax');
      });

      document.querySelectorAll('pre').forEach(block => {
        hljs.highlightBlock(block);
      });

      quill.on('text-change', (delta, oldDelta, source) => {
        quill.addErrorCSSOnRequired()

        if (maxLength && quill.getLength() > maxLength)
          quill.deleteText(maxLength, quill.getLength());

        if (autoSave)
          autoSave.changeCallback()
      });

      let toolbarTooltips = {
        'font': 'Select a font',
        'size': 'Select a font size',
        'header': 'Select the text style',
        'bold': 'Bold (ctrl+B)',
        'italic': 'Italic (ctrl+I)',
        'underline': 'Underline (ctrl+U)',
        'strike': 'Strikethrough',
        'color' : 'Select a text color',
        'background': 'Select a background color',
        'script': {
          'sub' : 'Subscript',
          'super': 'Superscript'
        },
        'list': {
          'ordered':'Numbered list',
          'bullet': 'Bulleted list'
        },
        'indent': {
          '-1': 'Decrease indent',
          '+1':  'Increase indent'
        },
        'direction': {
          'rtl': 'Text direction (right to left | left to right)',
          'ltr': 'Text direction (left ro right | right to left)'
        },
        'align': 'Text alignment',
        'link': 'Insert a link',
        'image': 'Insert an image (ctrl+O)',
        'hr': 'Insert an heading row',
        'omega': 'Fullscreen',
        'code-block': 'Code Block',
        'file-upload': 'Upload a File',
        'blockquote': 'Add the Blockquote',
        'video': 'Upload a Video'
      };


      let showTooltip = (which,el) => {
        if (which=='button'){
          var tool = el.className.replace('ql-', '');
        }
        else if (which=='span'){
          var tool = el.className.replace('ql-','');
          tool=tool.substr(0,tool.indexOf(' '));
        }

        if (tool){
          //if element has value attribute.. handling is different
          //buttons without value
          if (el.value ==''){
            if (toolbarTooltips[tool])
              el.setAttribute('title',toolbarTooltips[tool]);
          }

          //buttons with value
          else if (typeof el.value !=='undefined' && toolbarTooltips[tool] !== undefined ){
            if (toolbarTooltips[tool][el.value])
              el.setAttribute('title',toolbarTooltips[tool][el.value]);
          }
          //default
          else
            el.setAttribute('title',toolbarTooltips[tool]);
        }
      };

      let toolbarElement = document.querySelector('.ql-toolbar');

      if (toolbarElement) {
        let matchesButtons = toolbarElement.querySelectorAll('button');
        for (let el of matchesButtons) {
          showTooltip('button',el);
        }

        //for submenus inside
        let matchesSpans = toolbarElement.querySelectorAll('.ql-toolbar > span > span');
        for (let el of matchesSpans) {
          showTooltip('span',el);
        }
      }


      quill.on('selection-change', (range, oldRange, source) => {
        quill.highlightCodeBlock();

        if (autoSave) {
          if (!range) {
            if (key === 'notes') {
              autoSave.blurCallback();
            } else {
              autoSave.blurCallback(key, true);
            }
          }
        }
      });

      quill.root.addEventListener('blur', () => quill.highlightCodeBlock())

      return quill
    }

    this.getToolbar = type => {
      return this[`${type}Toolbar`] || this.withoutFuToolbar
    }

    this.alertToolbar = ['bold', 'italic', 'strike', 'link', 'hr', 'omega','emoji',]

    this.minimalToolbar = [
      'bold', 'italic', 'strike', 'link', 'code-block',
      { 'header': [1, 2, 3, 4, 5, false] }, 'hr',
      { 'color': [] }, { 'background': [] }, 'omega','emoji',
    ]

    this.withoutImageToolbar = [
      'bold', 'italic', 'underline', 'strike',
      { 'list': 'ordered'}, { 'list': 'bullet' },
      'link', 'code-block',
      { 'indent': '-1'}, { 'indent': '+1' }, 'hr',

      { 'align': [] },
      { 'header': [1, 2, 3, 4, 5, false] },

      { 'color': [] }, { 'background': [] }, 'omega',{ 'table': tableOptions },
      'emoji',
    ]

    this.quizToolbar = [
      'bold', 'italic', 'underline', 'strike',
      { 'list': 'ordered'}, { 'list': 'bullet' },
      { 'script': 'sub'}, { 'script': 'super' },
      'link', 'code-block', 'blockquote', 'image', 'video', 'file-upload',
      { 'indent': '-1'}, { 'indent': '+1' }, 'hr',

      { 'align': [] },
      { 'header': [1, 2, 3, 4, 5, false] },

      { 'color': [] }, { 'background': [] }, 'omega',{ 'table': tableOptions },
      'emoji',
    ]

    this.basicToolbar = [
      'bold', 'italic', 'underline', 'strike',
      { 'list': 'ordered'}, { 'list': 'bullet' },
      { 'script': 'sub'}, { 'script': 'super' },
      'link', 'code-block', 'blockquote', 'image','video', 'file-upload',
      { 'indent': '-1'}, { 'indent': '+1' }, 'hr',

      { 'align': [] },
      { 'header': [1, 2, 3, 4, 5, false] },

      { 'color': [] }, { 'background': [] }, 'omega',{ 'table': tableOptions },
      'emoji',
    ]

    //
    // fu means file upload
    //
    this.withoutFuToolbar = [
      'bold', 'italic', 'underline', 'strike',
      { 'list': 'ordered'}, { 'list': 'bullet' },
      'link', 'code-block', 'image',
      { 'indent': '-1'}, { 'indent': '+1' }, 'hr',

      { 'align': [] },
      { 'header': [1, 2, 3, 4, 5, false] },

      { 'color': [] }, { 'background': [] }, 'omega',{ 'table': tableOptions },
      'emoji',
    ]

    this.advanceToolbar = [
      'bold', 'italic', 'underline', 'strike',
      { 'list': 'ordered'}, { 'list': 'bullet' },
      { 'script': 'sub'}, { 'script': 'super' },
      'link', 'blockquote', 'code-block', 'image', 'video', 'file-upload',
      { 'direction': 'rtl' }, 'hr',
      { 'align': [] },
      { 'indent': '-1'}, { 'indent': '+1' },

      { 'header': [1, 2, 3, 4, 5, false] },

      { 'color': [] }, { 'background': [] }, 'clean',
      'omega', { 'font': [] } ,{ 'table': tableOptions },'emoji',
    ]
  }
}

export default KadQuill;
