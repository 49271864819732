export default {
  applyBindingsWithTextInput: function(funcCallback, $scrollTarget){
    $scrollTarget = $scrollTarget || $('#main-pjax-container');
    var $page_number = $('.page_number'),
      total_pages = parseInt($('.total_pages').text()),
      current_page = parseInt($('.current_page').text()),
      changePage = function(pageNumber){
        if (parseInt(pageNumber)) {
          var destination_page = (pageNumber <= total_pages) ? pageNumber : total_pages;
          var link = _.find($('.pagination a'), function (link) { return $(link).attr('href').indexOf('page') > -1; }) ||
            _.find($('.pagination a'), function (link) { return $(link).attr('href'); });
          var url = $(link).attr('href');
          url = url.replace(/(page=)[^\&]+/, '$1' + destination_page);
          funcCallback(url);
          Kadenze.UIUtil.scrollTo($scrollTarget, 0.5);
        } else {
          $page_number.val(current_page);
        }
      };

    $page_number.keyup(function(e){
      if(e.keyCode == 13){
        changePage($(this).val());
      }
    });
    $page_number.blur(function(e){
      changePage($(this).val());
    });

    $('.pagination a').off('click');
    $('.pagination a').on('click', function(e) {
      e.preventDefault();
      var url = $(this).attr('href');
      if (url != '#') {
        funcCallback(url);
        Kadenze.UIUtil.scrollTo($scrollTarget, 0.5);
      }
      return false;
    });
  }
};
