export default function(data, element, options) {
  var self = this;

  self.options = {
    anonymous: false
  };

  self.element = null;
  self.discussion = null;

  const __construct = function(data, element, opts) {
    console.log('GenericFileViewModel constructor called with data:');
    console.log(data);

    for (var key in data) {
      self[key] = data[key];
    }

    self.options = _.extend({}, self.options, opts);
    self.element = element;
    self.replies = ko.observableArray();
    self.repliesCount = ko.observable(0);
    self.couponCode = ko.observable();
    self.copied = ko.observable(false);

  }(data, element, options);

  self.render = function() {
    console.log('rendering DOM for GenericFileViewModel');
    var templ = self.options.template || JST[self.options.templatePath || 'templates/filebox']({
      gridClass: self.gridClass(),
      couponable: self.meta?.couponable,
      streamable: self.meta?.streamable,
      playable: self.meta?.playable || self.playableLink(),
      queueable: self.meta?.queueable,
      downloadable: self.meta?.downloadable,
      runnable: self.meta?.runnable,
      previewable: self.meta?.previewable,
      linkable: self.meta?.linkable,
      infoable: self.meta?.infoable,
      commentable: self.meta?.commentable,
      file_type: self.file_type,
      transcode_status: self.transcode_status,
      transcode_error_message: self.transcode_error_message
    });

    var $templ = $(templ);
    $(self.element).replaceWith($templ);

    ko.applyBindings(self, $templ[0]);
  };

  self.favorite = function() {
    console.log('favoriting not yet implemented');
  };

  self.play = function(item, event) {
    if (_.isObject(item.blocked_modal)) {
      new Kadenze.Notice.info(item.blocked_modal);
      return false;
    }
    console.log('Play:', self );
    if (self.type === 'Embedded Video') {
      self.showInfo();
    }
    else {
      Kadenze.MediaFileActions.play(self);
    }
  };

  self.run = function() {
    window.open(self.runnable_path);
  };

  self.queue = function() {
    Kadenze.MediaFileActions.queue( self );
  };

  self.iconClass = function() {
    return self.iconclass || Kadenze.FILE_ICONS_MAP[self.meta?.type];
  };

  self.placeholderImage = function() {
    return Kadenze.FileTypeMapping.getPlaceholderImage(self.meta?.type);
  };

  self.formattedSize = function() {
    return Kadenze.Util.bytesToSize(self.size);
  };

  self.gridClass = function() {
    var count = 0;
    var meta = self.meta;
    if (meta) {
      if (meta.streamable) {
        count += 1;
      }
      if (meta.queueable) {
        count += 1;
      }
      if (meta.downloadable) {
        count += 1;
      }
      if (meta.runnable) {
        count += 1;
      }
      if (meta.previewable) {
        count += 1;
      }
      if (meta.linkable) {
        count += 1;
      }
      if (meta.infoable) {
        count += 1;
      }
      if (meta.couponable) {
        count += 1;
      }
    }
    return 'grid-' + count;
  };

  self.passThruUnlessBlocked = function(item, event) {
    if (_.isObject(item.blocked_modal)) {
      new Kadenze.Notice.info(item.blocked_modal);
      event.stopPropagation();
      return false;
    } else {
      return true;
    }
  };

  self.downloadLink = function(item, event) {
    return self.download_path;
  };

  self.playableLink = function() {
    return Kadenze.IframeMediaPlayer.isValidUrl(self.url);
  };

  self.showInfo = function(item, event) {
    if (_.isObject(item.blocked_modal)) {
      new Kadenze.Notice.info(item.blocked_modal);
      return false;
    }
    var $infoModal = $('#resource_info_modal');
    var vm = ko.dataFor($infoModal[0]);
    vm.selectedResource(self);
    $infoModal.modal('show');
  };

  self.thumbUrl = ko.computed(function() {
    return self.file && (self.file.thumb_url || (self.file.thumb && self.file.thumb.url));
  });

  self.preview = function(item, event) {
    if (_.isObject(item.blocked_modal)) {
      new Kadenze.Notice.info(item.blocked_modal);
      return false;
    }
    self.hideResourceInfoModal();
    Kadenze.MediaFileActions.preview(self);
  };

  self.$el = function() {
    return $('[data-resource-id="' + self.id + '"]');
  };

  self.generateCode = function() {
    var $el = self.$el();
    var urlParams = {
      resource_id: self.id,
      course_id: self.course_id
    };
    var url = Kadenze.Routes.course_resource_coupon_redemptions_path(urlParams);
    var method = 'POST';
    var $modal = $el.find('#js-coupon-redemption-modal');

    Kadenze.UIUtil.makeRequest(url, method, {}, $el,
      function(xhr, settings) { //BeforeSave
        Kadenze.UIUtil.showLoadingIndicator($modal);
      },
      function(data, textStatus, xhr) { //Success
        if (data.coupon_code) {
          self.couponCode(data.coupon_code);
          $el.find('.resource__coupon_code').select();
        } else {
          $modal.modal('hide');
          Kadenze.UIUtil.showNotice($el, Kadenze.I18n.t('coupons.could_not_retrieve'), 3000);
        }
      },
      function(xhr, textStatus, errorThrown) { //Error
        $modal.modal('hide');
        $el.find('#js-premium-required-for-coupon-modal').modal('show');
      },
      function(xhr, textStatus) { //Complete
        Kadenze.UIUtil.hideLoadingIndicator($modal);
      }
    );
  };

  self.showCouponForm = function() {
    self.hideResourceInfoModal();
    self.$el().find('#js-coupon-form').modal('show');
  };

  self.getDiscount = function(){
    self.hideResourceInfoModal();
    self.$el().find('#js-coupon-redemption-modal').modal('show');
  };

  self.goPremium = function() {
    self.hideResourceInfoModal();
    self.$el().find('#js-premium-required-for-coupon-modal').modal('show');
  };

  self.showServiceError = function() {
    self.hideResourceInfoModal();
    self.$el().find('#js-coupon-service-error-modal').modal('show');
  };

  self.hideResourceInfoModal = function() {
    var $resourceInfoModal = $('#resource_info_modal');
    $resourceInfoModal.modal('hide');
  };

  self.updateUserState = function(resource) {
    const request = new Kadenze.AjaxRequest({
      type: 'POST',
      url: Kadenze.Routes.user_states_path(),
      data: { user_state: { stateable_id: resource.id, stateable_type: 'Resource', data: JSON.stringify({last_viewed_at: moment().format(Kadenze.I18n.t('date.formats.default'))}) }}
    });
  };

  self.showResourceInfoModal = function(item, event) {
    if (_.isObject(item.blocked_modal)) {
      new Kadenze.Notice.info(item.blocked_modal);
      return false;
    }
    var $resourceInfoModal = $('#resource_info_modal');
    var vm = ko.dataFor($resourceInfoModal[0]);
    vm.selectedResource(self);
    $resourceInfoModal.modal('show');
    self.updateUserState(vm.selectedResource());
  };

  self.showResourceShareModal = function (item, event) {
    if (_.isObject(item.blocked_modal)) {
      new Kadenze.Notice.info(item.blocked_modal);
      return false;
    }
    self.hideResourceInfoModal();
    var params = {
      modal_title: 'Share this Resource',
      modal_description: 'Only individuals enrolled in the course will have access to this resource.',
      privateSharing: true,
      shareableObj: { share_url: item.path }
    };
    var modal = new Kadenze.ShareModal(params);
    modal.show();
  };
}
