
// adopted from / inspired by Materialize ----------------------------------------------------------------------------- //
// https://github.com/Dogfalo/materialize/blob/master/js/forms.js ----------------------------------------------------- //

export default function() {

  var self = this;
  self.INPUT_SELECTOR = '' +
      '.kd-form__text-input input[type=text],' +
      '.kd-form__text-input input[type=password],' +
      '.kd-form__text-input input[type=email],' +
      '.kd-form__text-input input[type=url],' +
      '.kd-form__text-input input[type=tel],' +
      '.kd-form__text-input input[type=number],' +
      '.kd-form__text-input input[type=search],' +
      '.kd-form__datepicker input';
  self.TEXTAREA_SELECTOR = '.kd-textarea';

  self.initTextInput = function(element) {
    if ($(element).attr('placeholder') !== undefined) {
      $(element).parent().addClass('has-placeholder');
    }

    $(element).off('change.kd_form_text_fields')
      .on('change.kd_form_text_fields', function () {
        if($(this).val().length !== 0 || $(this).attr('placeholder') !== undefined) {
          $(this).parent().addClass('has-focus');
        }
      });

    $(element).off('focus.kd_form_text_fields')
      .on('focus.kd_form_text_fields', function () {
        $(this).parent().addClass('has-focus');
      });

    $(element).off('blur.kd_form_text_fields')
      .on('blur.kd_form_text_fields', function () {
        var $inputElement = $(this);

        if ($inputElement.val().length === 0 && $inputElement[0].validity.badInput !== true) {
          $inputElement.parent().removeClass('has-focus');
        }
      });

    // Override label click to avoid label naming convention issues
    // This is a temporary hack that should be revisited when we've fully moved to a vue/webpack world
    $(element).siblings('label').first().on('click.kd_form_text_fields', function(evt) {
      $(element).focus();
      evt.stopPropagation();
      evt.preventDefault();
    });

    $(document).ready(function() {
      self.updateTextInput(element);
    });
  };

  self.initTextArea = function(element) {
    self.initTextInput(element);
    var $el = $(element);


    var minRows = $el.attr('rows') ? parseInt($el.attr('rows')) : 1;
    var lineHeight = null;
    var node = element;

    //// search for hidden div if not there append it to body
    //var hiddenDiv = $('.hiddendiv').first();
    //if (!hiddenDiv.length) {
    //  hiddenDiv = $('<div class="hiddendiv common"></div>');
    //  $('body').append(hiddenDiv);
    //}
    // resize textarea if there is content;
    if ($el.val().length) {
      growTextarea();
    }

    $el.on('input', growTextarea);
    $el.off('keyup.kd_form_textarea keydown.kd_form_textarea autoresize.kd_form_textarea')
      .on('keyup.kd_form_textarea keydown.kd_form_textarea autoresize.kd_form_textarea', growTextarea );

    function growTextarea() {
      $el
        .attr('rows', 1)
        .css('height', 'auto')
        .addClass('no-flex');

      var height = getHeight();

      if (!lineHeight) {
        // offsetHeight includes padding which can throw off our value
        var originalPadding = $el[0].style.padding || '';
        lineHeight = $el.css('padding', 0).prop('offsetHeight');
        $el[0].style.padding = originalPadding;
        lineHeight = (lineHeight==0) ? 20 : lineHeight;
      }
      if (minRows && lineHeight) {
        height = Math.max(height, lineHeight * minRows);
      }
      if (lineHeight) {
        $el.attr('rows', Math.round(height / lineHeight));
      }
      $el
        .css('height', height + 'px')
        .removeClass('no-flex');
    }

    function getHeight() {
      var offsetHeight = node.offsetHeight;
      var line = node.scrollHeight - offsetHeight;
      return offsetHeight + Math.max(line, 0);
    }

    growTextarea();
  };

  self.updateTextInput = function(element, force) {
    if (force || $(element).val().length > 0 || element.autofocus || $(element).attr('placeholder') !== undefined || $(element)[0].validity.badInput === true) {
      $(element).parent().addClass('has-focus');
    }
    else {
      $(element).parent().removeClass('has-focus');
    }
  };

  self.updateAllTextFields = function() {
    $(self.INPUT_SELECTOR).each(function(index, element) {
      self.updateTextInput(element);
    });
  };

  self.initRadioCheckboxes = function(){
    var radio_checkbox = 'input.kd-form__radio[type=radio], input.kd-form__checkbox[type=checkbox], input.kd-form__flipswitch-cb[type=checkbox]';
    $(document).off('keyup.radio_checkbox').on('keyup.radio_checkbox', radio_checkbox, function(e) {
      // TAB, check if tabbing to radio or checkbox.
      if (e.which === 9) {
        var $this = $(this);
        $this.addClass('tabbed');
        $this.one('blur', function(e) {
          $this.removeClass('tabbed');
        });
        return;
      }
    });
  };

  return {
    init: function(){
      // Add active if input element has been pre-populated on document ready
      $(document).ready(function() {
        self.updateAllTextFields();
        self.initRadioCheckboxes();
      });

      $(self.INPUT_SELECTOR).each(function(index, element) {
        self.initTextInput(element);
      });

      $(self.TEXTAREA_SELECTOR).each(function (index, element) {
        self.initTextArea(element);
      });

    }, // End init
    update : function(){
      self.updateAllTextFields();
    },
    initField : function(element) {
      switch(element.type) {
      case 'text':
      case 'password':
      case 'email':
      case 'url':
      case 'tel':
      case 'number':
      case 'search':
        self.initTextInput(element);
        break;
      case 'textarea':
        self.initTextArea(element);
        break;
      }
    },
    updateField : function(element, force) {
      if(element && (element.type === 'text' || element.type === 'textarea' || element.type === 'number')) {
        self.updateTextInput(element, force);
      }
    }
  }; // End return

} // End KdForms
