let CodeEditor = {};

CodeEditor.editor = function(params) {
  var self = this;

  self.defaults = {
    $template: $(JST['templates/code-editor']()),
    $node: $(params.node),
    curMode: 'text',
    autoFocus: false,
    aceOptions: {
      useSoftTabs: true,
      tabSize: 1
    }
  };

  self.aceSyntaxMapping = {
    chuck: 'c_cpp'
  };

  self.prismSyntaxMapping = {
    chuck: 'cpp',
    c_cpp: 'cpp'
  };

  self.config = _.extend({}, self.defaults, params);

  self.init = function() {
    var el = self.findNode('.js-code-editor')[0];

    $(self.config.node).append(self.config.$template);

    self.editor = ace.edit(el);
    self.editor.setOptions(self.config.aceOptions);

    if(self.config.autoFocus) {
      self.editor.focus();
    }

    self.config.$node.data('codeEditor', self);

    self.delegateEvents();
  };

  self.delegateEvents = function() {
    var $modeSelect = self.findNode('[name=\'code-editor-mode\']'),
      $themeSelect = self.findNode('[name=\'code-editor-theme\']');

    $modeSelect.on('change', self.setMode);
    $themeSelect.on('change', self.setTheme);
  };

  self.getValue = function() {
    var val = self.editor.session.getValue();
    if (self.getMode() == 'html') {
      val = Kadenze.Util.htmlEscape(val);
    }
    return val;
  };

  self.setValue = function(val) {
    self.editor.session.setValue(val);
  };

  self.setMode = function(event) {
    self.config.curMode = self.prismSyntaxMapping[event.target.value] || event.target.value;
    self.setSyntax(event.target.value);
  };

  self.getMode = function() {
    return self.config.curMode;
  };

  self.setSyntax = function(syntax) {
    var mode = self.aceSyntaxMapping[syntax] || syntax;

    self.editor.getSession().setMode('ace/mode/' + mode);
    self.findNode('[name=\'code-editor-mode\']')[0].value = syntax;
  };

  self.setTheme = function(event) {
    self.editor.setTheme('ace/theme/' + event.target.value);
  };

  self.destroy = function() {
    self.editor.destroy();
  };

  // Utils
  self.findNode = function(selector) {
    return self.config.$template.find(selector);
  };

  self.prismClass = function() {
    return 'language-' + self.config.curMode;
  };

  self.init();

  return self;
};

export default {
  init: function(node, params) {
    console.log('Initializing CodeEditor');

    var options = _.extend({}, { node: node }, params);

    new CodeEditor.editor(options);
  }
};
