//TODO: if/when necessary, add base class here (for shared attributes/methods across iframe players)

//supported events
//  - HTML5 Media events: ended, pause, playing, timeupdate
//    (see https://developer.mozilla.org/en-US/docs/Web/Guide/Events/Media_events)
//  - additional events: show, hide, stop

//Vimeo constructor
var VimeoPlayer = function ($iframe_wrapper, media_src_url, user_state) {
  //private data
  var _self = this;
  var _vimeoPlayerReady = false;
  var _autoPlayOnReady = false;
  var _$video_el = null;
  var _$iframe_el = null;
  var _completion_callback = null;
  var _currentTime = 0.0;
  var _ended = false;

  //public data
  this.$iframe_wrapper = $iframe_wrapper;
  this.media_src_url = media_src_url;
  this.user_state = user_state || {};

  //private methods
  function extractVideoID(vurl) {
    var vurl = vurl.trim();
    return vurl.substring(vurl.lastIndexOf('/') + 1);
  }

  // Helper method for sending messages to the player
  var post = function (action, value) {
    var data = {
      method: action
    };

    if (value) {
      data.value = value;
    }

    if (_$iframe_el) {
      var player = _$iframe_el[0].contentWindow;
      var message = JSON.stringify(data);
      // var post_url = window.location.protocol + '//player.vimeo.com/video/' + extractVideoID(_self.media_src_url);
      var post_url = 'https://player.vimeo.com/video/' + extractVideoID(_self.media_src_url);
      player.postMessage(message, post_url); //use '*' for any post_url
    }
  };

  //handlers for events from vimeo player
  var onMessageReceived = function (e) {
    try {
      var data = JSON.parse(e.originalEvent.data);
      //jquery munges 'data' of postmessages; see: http://stackoverflow.com/questions/9904490/jquery-doesnt-support-postmessage-event

      switch (data.event) {
      case 'ready':
        onReady();
        break;
      case 'finish':
        _self.triggerEvent('ended');
        onFinish();
        break;
      case 'pause':
        _self.triggerEvent('pause');
        break;
      case 'play':
        _self.triggerEvent('playing');
        break;
      case 'playProgress':
        _currentTime = data.data.seconds; //hack, since vimeo can get time only on a callback
        _ended = data.data.duration - _currentTime < 2;
        _self.triggerEvent('timeupdate', { seconds: _currentTime });
        break;
      case 'seek':
        _currentTime = data.data.seconds;
        _self.triggerEvent('seeked', {seconds: _currentTime});
        break;
      default:
        break;
      }
    } catch (error) {
      console.log('Error while getting data from vimeo.');
    }
  };

  var onReady = function () {
    _vimeoPlayerReady = true;
    post('addEventListener', 'finish');
    post('addEventListener', 'pause');
    post('addEventListener', 'play');
    post('addEventListener', 'playProgress');
    post('addEventListener', 'seek');

    if (!_.isEmpty(_self.user_state) && !_self.user_state.didReachEnd) {
      _self.currentTime(_self.user_state.end_time);
    }

    if (_autoPlayOnReady)
      post('play');
  };

  var onFinish = function (e) {
    if (_completion_callback)
      _completion_callback();
  };

  //public methods
  _.extend(this, Kadenze.EventSource); //add event handling capability

  this.show = function ($animation_options) {
    //start listening for messages from the player
    $(window).on('message', onMessageReceived);

    if (!(_$video_el && _$iframe_el)) {
      //load the video into the iframe
      var media_src = '//player.vimeo.com/video/' + extractVideoID(this.media_src_url) + '?api=1';

      _$video_el = $(JST['templates/iframe-video-element']());
      _$video_el.css({'display': 'none'});
      _$iframe_el = _$video_el.find('iframe');
      _$iframe_el.attr('src', media_src);

      //insert it into the document
      this.$iframe_wrapper.append(_$video_el);
    }

    //show it with animation:
    _$video_el.show($animation_options);

    this.triggerEvent('show');
    return this;
  };

  this.play = function (autoplay, completion_callback) {
    _completion_callback = completion_callback;

    if (autoplay) //don't wait for user to press play
    {
      if (!_vimeoPlayerReady) //queue it up if the player is not ready yet
        _autoPlayOnReady = true;
      else
        post('play');
    }

    return this;
  };

  this.pause = function () {
    post('pause');

    return this;
  };

  this.stop = function () {
    this.pause();
    //post('seekTo', '0.0');

    this.triggerEvent('stop', {currentTime: this.currentTime(), ended: this.ended()});
    return this;
  };

  this.hide = function ($animation_options) {
    //hide iframe with animation:
    if (_$video_el)
      _$video_el.hide($animation_options);

    this.triggerEvent('hide');
    return this;
  };

  //gets (no argument) or sets playhead time
  this.currentTime = function (seconds) {
    if (seconds)
      post('seekTo', seconds);
    else
      return _currentTime;
  };

  this.ended = function () {
    return _ended;
  };

  this.playbackRate = function () {
    return 1.0; //vimeo does not support this
  };

  this.updateUserState = function (t) {
    _self.user_state = t;
  };

  this.changeSource = function (src) {
    _self.media_src_url = src;
    // if(_yt_player && _YT_player_ready) {
    //   // loadVideoByUrl only takes a specific url format, thus using id
    //   vidId = youtubeParser(src);
    //   _yt_player.loadVideoById(vidId);
    // }
  };
};

var YouTubePlayer = (function ($iframe_wrapper, media_src_url, user_state) {
  //shared across instances
  var __YT_api_loaded = false;
  var __YT_api_ready = false;
  var __last_player_id = 0;

  var ytApiLoad = function () {
    console.log('ytApiLoad');

    //Load YouTube api into the dom
    window.onYouTubeIframeAPIReady = onYtApiReady;

    var tag = document.createElement('script');
    tag.src = document.location.protocol + '//www.youtube.com/iframe_api';
    tag.id = 'youtube_api';
    var firstScriptTag = document.getElementsByTagName('script')[0];
    firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

    __YT_api_loaded = true;
  };

  var onYtApiReady = function () {
    console.log('onYtApiReady');
    __YT_api_ready = true;
  };

  //constructor for player instances
  function YouTubePlayer($iframe_wrapper, media_src_url, user_state, show_automatically) {
    //private instance data
    var _yt_player = null;
    var _YT_player_ready = false;
    var _YT_player_load_failed = false;
    var _video_player_id;

    var _$video_el = null;
    var _$iframe_el = null;
    var _$animation_options = null;
    var _showOnReady = false;
    var _showAutomatically = _.isUndefined(show_automatically) ? true : show_automatically;
    var _autoPlayOnReady = false;
    var _completion_callback = null;
    var _timeupdateTimer = null;
    var _self = this;

    this.$iframe_wrapper = $iframe_wrapper;
    this.media_src_url = media_src_url;
    this.user_state = user_state || {};

    //first one in the door starts it up:
    if (!__YT_api_loaded)
      ytApiLoad();

    //private methods
    function extractVideoID(vurl) {
      var vurl = vurl.trim();
      return _.last(vurl.match(/(?:\W)([\w-]{9,})(?:\W|$)/));
    }

    var onPlayerReady = function () {
      //console.log("onPlayerReady for " + _video_player_id);
      console.log('Player load succeeded for ' + _video_player_id);

      _YT_player_ready = true;
      _YT_player_load_failed = false;

      //_$video_el.css({"display": "none"});
      //_$video_el.css({"visibility": "visible"});

      if (_showOnReady) {
        if (!_.isEmpty(_self.user_state) && !_self.user_state.didReachEnd) {
          //Had to use cueVideoById instead or currentTime/seekTo to avoid auto playback
          //https://code.google.com/p/gdata-issues/issues/detail?id=2916
          var video_id = extractVideoID(_self.media_src_url);
          _yt_player.cueVideoById(video_id, _self.user_state.end_time);
        }
        _$video_el.show(_$animation_options);
        _self.triggerEvent('show');
        _showOnReady = false;
      }

      if (_autoPlayOnReady) {
        _yt_player.playVideo();
        _autoPlayOnReady = false;
      }
    };

    var onPlayerStateChange = function (event) {
      if (!YT)
        return;

      switch (event.data) {
      case YT.PlayerState.ENDED:
        _self.triggerEvent('ended');

        clearInterval(_timeupdateTimer);
        _timeupdateTimer = null;

        if (_completion_callback) {
          //console.log("onPlayerStateChange: calling _completion_callback for " + _self.media_src_url);
          _completion_callback();
        }

        break;
      case YT.PlayerState.PLAYING:
        _self.triggerEvent('playing');
        if (!_timeupdateTimer)
          _timeupdateTimer = setInterval(function () {
            _self.triggerEvent('timeupdate', {seconds: _yt_player.getCurrentTime()});
          }, 1000);

        break;
      case YT.PlayerState.PAUSED:
        _self.triggerEvent('pause');
        clearInterval(_timeupdateTimer);
        _timeupdateTimer = null;
        break;
      default:
        break;
      }
    };

    var onPlayerError = function (error) {
      console.log('YouTube player error: ' + error.data);
    };

    //public methods
    _.extend(this, Kadenze.EventSource); //add event handling capability

    this.show = function ($animation_options) {
      (function poll() {
        //make sure the api is loaded
        if (!__YT_api_ready) {
          setTimeout(poll, 100);
        }
        else //get on with it
        {
          if (!_yt_player) //load a new player
          {
            //load video into a new iframe
            if (!_$iframe_el) {
              _$video_el = $(JST[Kadenze.iframeTemplatePath]());
              //_$video_el.css({"visibility": "hidden"});
              _$iframe_el = _$video_el.find('iframe');
              _video_player_id = 'yt_player_' + __last_player_id++;
              _$iframe_el.attr('id', _video_player_id);
              var media_src = document.location.protocol + '//www.youtube.com/embed/' + extractVideoID(_self.media_src_url) + '?enablejsapi=1' + '&origin=' + location.origin + '&rel=0';
              _$iframe_el.attr('src', media_src);
              _self.$iframe_wrapper.append(_$video_el);
            }

            //instantiate a player for it
            window.YTConfig = {
              'host': document.location.protocol + '//www.youtube.com'
            };

            _yt_player = new YT.Player(_video_player_id, {
              events: {
                'onReady': onPlayerReady,
                'onStateChange': onPlayerStateChange,
                'onError': onPlayerError
              }
            });

            setTimeout(function () {
              if (!_YT_player_ready) {
                console.log('Player load timeout for ' + _video_player_id);
                _YT_player_load_failed = true;
              }
            }, 2000);
          }

          _$animation_options = $animation_options;

          //show it:
          if (!_YT_player_ready && !_YT_player_load_failed && _showAutomatically) { //queue it
            _showOnReady = true;
          } else {
            _self.triggerEvent('show');
            _$video_el.show(_$animation_options);
          }
        }
      })();

      return this;
    };

    this.play = function (autoplay, completion_callback) {
      _completion_callback = completion_callback;

      if (autoplay) //don't wait for user to press play
      {
        if (!_YT_player_ready) //queue it up if the player is not ready yet
          _autoPlayOnReady = true;
        else
          _yt_player.playVideo();
      }

      return this;
    };

    this.pause = function () {
      if (_yt_player && _YT_player_ready && _yt_player.getPlayerState() === YT.PlayerState.PLAYING)
        _yt_player.pauseVideo();

      return this;
    };

    this.stop = function () {
      if (_yt_player && _YT_player_ready) {
        _yt_player.stopVideo();
        _yt_player.clearVideo();

        this.triggerEvent('stop', {currentTime: this.currentTime(), ended: this.ended()});
      }
      return this;
    };

    this.hide = function ($animation_options) {
      //hide:
      if (_$video_el)
        _$video_el.hide($animation_options);

      this.triggerEvent('hide');
      return this;
    };

    //gets (no argument) or sets playhead time
    this.currentTime = function (seconds) {
      if (_yt_player && _YT_player_ready) {
        if (seconds)
          _yt_player.seekTo(seconds);
        else
          return _yt_player.getCurrentTime();
      }

      return 0;
    };

    this.ended = function () {
      if (_yt_player && _YT_player_ready) {
        return Math.abs(_yt_player.getDuration() - _yt_player.getCurrentTime()) < 1;
      }

      return false;
    };

    this.playbackRate = function () {
      if (_yt_player && _YT_player_ready)
        return _yt_player.getPlaybackRate();
    };

    this.updateUserState = function (t) {
      _self.user_state = t;
    };

    this.changeSource = function (src) {
      _self.media_src_url = src;
    };
  }

  var youtubeParser = function (url) {
    var regExp = /^.*(youtu\.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
    var match = url.match(regExp);
    if (match && match[2].length == 11) {
      return match[2];
    } else {
      console.log('error parsing youtube url');
    }
  };

  return YouTubePlayer;
})();

//factory method to identify and return the appropriate media player based on the url
//passed in.
var IframeMediaPlayer = function ($iframe_wrapper, media_src_url, user_state, show_automatically) {
  //instantiate correct media player
  var url = IframeMediaPlayer.extractUrl(media_src_url.trim()); //clean up url (just in case)

  if (url && url.match(/vimeo\.com/i)) {
    //mix in the player's methods
    _.extend(this, VimeoPlayer.prototype);
    return VimeoPlayer.call(this, $iframe_wrapper, url, user_state, show_automatically);
  }
  else if (url && url.match(/(youtube\.com|youtu\.be)/i)) {
    //mix in the player's methods
    _.extend(this, YouTubePlayer.prototype);
    return YouTubePlayer.call(this, $iframe_wrapper, url, user_state, show_automatically);
  }
  else
    return {};
};

//class methods:
IframeMediaPlayer.extractUrl = function (ytv_url) {
  //Strip whitespace and any trailing slash
  var url = ytv_url.replace(/^\s+|\s+$/, '');
  if (url.charAt(url.length - 1) === '/')
    url = url.slice(0, -1);

  if (!IframeMediaPlayer.isValidUrl(url))
    return null;

  // Specifiably handle vimeo / youtube
  if (url.match(/(youtube)/i) !== null || url.match(/(vimeo)/i) !== null) {
    url = IframeMediaPlayer.parseURL(url);
  }

  ////if non-canonical vimeo url, fetch the video id from vimeo.com
  if ((url.match(/(vimeo)/i) !== null) && !url.match(/^(https*:\/\/vimeo\.com\/)[0-9]+$/i)) {
    //see if vimeo can make sense of it
    //need a blocking call (uses a timeout so won't
    //bring everything to a grinding halt)
    $.ajax(
      {
        url: 'https://vimeo.com/api/oembed.json?url=' + encodeURI(url),
        async: false,
        timeout: 5000,
        dataType: 'json',
        success: function (data, textStatus, jqXHR) {
          url = data.provider_url + data.video_id;
        },
        error: function () {
          url = null;
        }
      }
    );
  }

  return url;
};

IframeMediaPlayer.isValidUrl = function (ytv_url) {
  var isUrl = false,
    isIframe = false,
    isVimeoOrYoutubeValid = false;

  if (!_.isEmpty(ytv_url)) {
    //Regex by Diego Perini from: http://mathiasbynens.be/demo/url-regex
    isUrl = (ytv_url.match(/^(?:(?:https?|ftp):\/\/)(?:\S+(?::\S*)?@)?(?:(?!10(?:\.\d{1,3}){3})(?!127(?:\.‌​\d{1,3}){3})(?!169\.254(?:\.\d{1,3}){2})(?!192\.168(?:\.\d{1,3}){2})(?!172\.(?:1[‌​6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1‌​,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00‌​a1-\uffff0-9]+-?)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]+-?)*[a-z\u‌​00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/[^\s]*)?$/i) != null);

    //allow iframe embeds
    isIframe = IframeMediaPlayer.isIframe(ytv_url);

    //general check for domain:
    isVimeoOrYoutubeValid = (ytv_url.match(/(vimeo|youtube|youtu.be)/i) !== null);

    //if it's an unshortened youtube video, make sure 'v=' is present
    //isVimeoOrYoutubeValid = (ytv_url.match(/youtube/i) !== null) ? (ytv_url.match(/v=/i) !== null) : isVimeoOrYoutubeValid;

    //if it's vimeo or shortened youtube url, ensure no query parameters
    //isVimeoOrYoutubeValid = (ytv_url.match(/(vimeo|youtube|youtu.be)/i) !== null) ? (ytv_url.match(/[?&]/i) === null) : isVimeoOrYoutubeValid;
  }

  return isUrl && isVimeoOrYoutubeValid || isIframe;
};

IframeMediaPlayer.isIframe = function (string) {
  return string.match(/(<iframe[\s\S]+<\/iframe>)/g) !== null;
};

IframeMediaPlayer.parseURL = function (string) {
  var parsed = string;

  // If string contains an iframe, get url from the src attribute
  if (IframeMediaPlayer.isIframe(string)) {
    parsed = string.match(/(?:<iframe.*?src\s*=\s*["']([^"']+))/)[1];
  }

  return parsed;
};

export default IframeMediaPlayer;
