export default {
  init() {
    this.isReady = false
    this.userState = null

    if (!this.addEventListener) _.extend(this, Kadenze.EventSource)

    this.get(() => {
        this.isReady = true
        this.triggerEvent('ready')
      },
      { initializing: true }
    );
  },

  set(data) {
    if (!this.isReady) {
      this.setCallbackOnEvent('ready', this.set, data)
      return
    }

    let { user_state } = data

    if (!user_state)
      user_state = { data }

    Kadenze.Ajax.post({
      routeName: 'user_states',
      data: { user_state },
      done: (d, status, xhr) => {
        this.userState = d['user_state']['meta_data'];
        this.triggerEvent('reload');
      }
    });
  },

  get(doneFn = () => {}, options = {}) {
    if (!options.initializing && !this.isReady) {
      this.setCallbackOnEvent('ready', this.get, doneFn)
      return
    }

    if (this.userState) {
      doneFn(this.userState)
      return
    }

    Kadenze.Ajax.get({
      routeName: 'user_states',
      done: (data, status, xhr) => {
        this.userState = data['user_state']['meta_data'];
        doneFn(this.userState)
      },
    })
  },

  onReload(callback) {
    this.setCallbackOnEvent('reload', callback)
  },

  setCallbackOnEvent(event, callback, args) {
    if (!this.addEventListener) _.extend(this, Kadenze.EventSource)

    this.addEventListener(event, () => {
      if (typeof callback === 'function' && args) {
        callback.bind(this, args).call()
      } else if (typeof callback === 'function') {
        callback(this.userState || {});
      }
    });
  },
};
