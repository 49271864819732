export default function(params) {
  var self = this;

  self.defaults = {
    $iframe: $(params.iframe),
    aspectRatio: '16by9',
  };

  self.config = _.extend({}, self.defaults, params);

  self.decorate = function()  {
    if(!self.canDecorate()) return self.config.$iframe;
    return self.config.$iframe.wrap(self.responsiveContainer()).parent();
  };

  self.canDecorate = function() {
    return !self.config.$iframe.parent().hasClass('embed-responsive');
  };

  self.responsiveContainer = function() {
    return $('<div />', { class: 'embed-responsive embed-responsive-' + self.config.aspectRatio });
  };

  return self;
}
