export default function($el, messageObj, opts, callbacks) {
  $el.addClass('dz-singlefile dz-unit');

  var maxFileError = false;
  var $message = $(JST['templates/dropzone-generic']({
    maxFilesize: opts.maxFilesize,
    title: messageObj.title,
    hideUpperTitle: (opts.hideUpperTitle) ? opts.hideUpperTitle : false,
    acceptedFiles: 'image/*, video/*, application/*, text/*, audio/* ',
    dropFilesTitle: Kadenze.I18n.t('drop_file_editor.drop_file_title'),
    dragAndDrop: Kadenze.I18n.t('drop_file_editor.drag_and_drop'),
    browseFiles: Kadenze.I18n.t('drop_file_editor.browse_files'),
    dimentionLabel: Kadenze.I18n.t('drop_file_editor.dimensions_label'),
    maxFilesizeLabel: Kadenze.I18n.t('drop_file_editor.max_file_size'),
    fileTypesLabel: Kadenze.I18n.t('drop_file_editor.file_types'),
    optionalLable: Kadenze.I18n.t('drop_file_editor.optional'),
  }));

  $el.append($message);

  var $btn = $('.js-dz-btn', $message);

  // set up dropzone options
  var dzOptions = _.extend({}, Kadenze.DropzoneDefaults, {
    url: Kadenze.Routes.attachments_path(),
    addRemoveLinks: true,
    maxFiles: 10,
    autoProcessQueue: true,
    headers: {
      'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content')
    },
    previewTemplate: '<div style="display:none"></div>',
    paramName: 'attachment[file]',
    useKilobytes: true, // custom option added to dropzone. Contacting dev to see if they'll add this feature

    init: function() {
      console.log('media library dropzone init');

      if (callbacks && typeof callbacks.init === 'function') {
        callbacks.init.apply(this, arguments);
      }

      this.on('addedfile', function(file) {
        console.log(this, 'file added');
        if (callbacks && typeof callbacks.addedfile === 'function') {
          callbacks.addedfile.apply(this, arguments);
        }
      });

      this.on('sending', function(file, xhr, formData) {
        if (callbacks && typeof callbacks.sending === 'function') {
          callbacks.sending.apply(this, arguments);
        }
      });

      this.on('success', function(file, response) {
        console.log(this, 'file success');
        if (callbacks && typeof callbacks.success === 'function') {
          callbacks.success.apply(this, arguments);
        }
      });

      this.on('removedfile', function(file) {
        console.log(this, 'file removed');
        if (callbacks && typeof callbacks.removedfile === 'function') {
          callbacks.removedfile.apply(this, arguments);
        }
      });

      // this.on("maxfilesexceeded", function(file){
      //   this.removeAllFiles();
      //   this.addFile(file);
      // });
      this.on('maxfilesexceeded', function(file) {
        console.log(this, 'max files exceeded');
        if (!maxFileError) {
          var fileNum = opts.maxFiles || dzOptions.maxFiles;
          new Kadenze.Notice.attention({
            message: 'You can only upload ' + fileNum + ' files at a time'
          });
        }
        maxFileError = true;
        this.removeFile(file);

        setTimeout(function() { maxFileError = false; }, 10000);
      });
    }
  });

  // create the dropzone object
  return new Dropzone('div#' + $el.attr('id'), _.extend({}, dzOptions, opts));
}
