export default function(params) {
  var self = this;

  self.defaults = {
    style: {}
  };

  self.config = _.extend({}, self.defaults, params);
  self.emitter = new Emitter();
  self.presented = false;

  self.init = function () {
    return Promise.all([
      import('cornerstone-core'),
      import('cornerstone-tools'),
      import('cornerstone-wado-image-loader'),
    ])
      .then(([cornerstone, cornerstoneTools, cornerstoneWADOImageLoader]) => {
        self.cornerstone = cornerstone;
        self.cornerstoneTools = cornerstoneTools;
        self.cornerstoneWADOImageLoader = cornerstoneWADOImageLoader;

        self.cornerstoneWADOImageLoader.external.cornerstone = self.cornerstone;
        self.cornerstoneWADOImageLoader.external.$ = $;

        self.cornerstoneTools.external.cornerstone = self.cornerstone;

        var config = {
          webWorkerPath : '/cornerstoneWADOImageLoaderWebWorker.min.js',
          taskConfiguration: {
            'decodeTask' : {
              codecsPath: '/cornerstoneWADOImageLoaderCodecs.min.js'
            }
          }
        };
        try {
          self.cornerstoneWADOImageLoader.webWorkerManager.initialize(config);
        } catch(err) {
          console.log(err);
        }
      });
  };
  
  self.present = function(provideViewer = null) {
    if(self.raiseWarnings() || self.presented) return;
    self.init().then(() => {
      if (_.isFunction(provideViewer)) {
        provideViewer();
      } else {
        self.appendViewer();
      }
      setTimeout(function() { self.loadAndViewImage(); }, 50);
    });
  };

  self.appendViewer = function() {
    self.$viewer = $('<div/>', { class: 'image-preview'});
    self.$viewer.css(self.config.style);
    self.setSize();

    self.config.$container.append(self.$viewer);
  };

  self.setSize = function () {
    if (!self.config.style['height']) {
      self.$viewer.css('height', self.config.$container.outerHeight());
      self.$viewer.css('width', self.config.$container.outerWidth());
    }
  };

  self.loadAndViewImage = function () {
    try {
      let imageId = 'wadouri:' + self.config.src;

      self.cornerstone.enable(self.$viewer[0]);

      self.cornerstone.loadAndCacheImage(imageId).then((image) => {
        var viewport = self.cornerstone.getDefaultViewportForImage(self.$viewer[0], image);
        self.cornerstone.displayImage(self.$viewer[0], image, viewport);

        $(window).on('resize', function() {
          self.setSize();
          self.cornerstone.resize(self.$viewer[0]);
        });

        if(self.presented === false) {
          self.cornerstoneTools.mouseInput.enable(self.$viewer[0]);
          self.cornerstoneTools.mouseWheelInput.enable(self.$viewer[0]);
          self.cornerstoneTools.wwwc.activate(self.$viewer[0], 1); // ww/wc is the default tool for left mouse button
          self.cornerstoneTools.pan.activate(self.$viewer[0], 2); // pan is the default tool for middle mouse button
          self.cornerstoneTools.zoom.activate(self.$viewer[0], 4); // zoom is the default tool for right mouse button
          self.cornerstoneTools.zoomWheel.activate(self.$viewer[0]); // zoom is the default tool for middle mouse wheel

          self.presented = true;
          self.emitter.emit('presented');
        }
      });
    } catch(err) {
      console.warn(err);
    }
  };

  self.destroy = function() {
    self.$viewer.remove();
    self.emitter.emit('destroy');
  };

  self.hide = function() {
    self.$viewer.hide();
    self.emitter.emit('hide');
    self.config.$container.css({display: '', 'justify-content': ''});
  };

  self.show = function() {
    self.$viewer.show();
    self.config.$container.css({display: 'flex', 'justify-content': 'center'});
    self.emitter.emit('show');
  };

  self.raiseWarnings = function() {
    if(!self.config.src) {
      console.warn('DICOM Media Renderer: Must provide image src: { src: "my-image-src" }');
      return true;
    } else {
      return false;
    }
  };

  return self;
}

