export default {
  pjax: function(opts) {
    var defaults = {
      timeout: 2000,
      container: '#main-pjax-container'
    };

    var options = _.extend(defaults, opts);

    $.pjax({
      url: options.url,
      container: options.container,
      timeout: options.timeout
    });
  }
};
