const camelize = str => str.replace(/(\-\w)/g, m => m[1].toUpperCase())

const buildKey = file => {
  return file.charAt(0).toUpperCase() + camelize(file).slice(1).split('.')[0];
}

const importAll = r => {
  r.keys().forEach(key => {
    const fileName = key.split('/').pop();
    Kadenze[buildKey(fileName)] = r(key).default;
  });
}

importAll(require.context('libs/globals', false, /\.js$/));
