//Hook up OO methods to Object prototype (for cleaner syntax)
topiarist.install();

//Add some string helpers to the String prototype
String.prototype.capitalize_first = function() {
  return this.charAt(0).toUpperCase() + this.slice(1);
};

String.prototype.capitalize_all = function() {
  return this.replace(/(?:^|\s)\S/g, function(a) { return a.toUpperCase(); });
};

String.prototype.trimToLength = function(m) {
  return (this.length > m)
    ? jQuery.trim(this).substring(0, m).split(' ').slice(0, -1).join(' ') + '...'
    : this.toString();
};

//Usage: "I'm {age} years old!".supplant({ age: 29 })
String.prototype.supplant = function (o) {
  return this.replace(/{([^{}]*)}/g,
    function (a, b) {
      var r = o[b];
      return typeof r === 'string' || typeof r === 'number' ? r : a;
    }
  );
};
//Usage: "point".pluralize(2)
String.prototype.pluralize = function (count, plural) {
  if (plural == null)
    plural = this + 's';

  return (count > 1) ? plural : this;
};

Array.prototype.next = function(current) {
  if((this.length - 1) <= current)
    return this[0];
  else
    return this[current + 1];
};

Array.prototype.sum = function (prop) {
  var total = 0;
  for ( var i = 0, _len = this.length; i < _len; i++ ) {
    var val = this[i][prop];
    total += (typeof val == 'function') ? val() : val;
  }
  return total;
};

//
// just like _.has function but works on nested objects.
// call with either 'object_prop.child' or ['object_prop', 'child', ....]
// this is mixed into the _. object.
//
_.mixin({
  deepHas: function(obj, keys) {
    var callee = function(obj, keys) {
      // bail early
      if ((keys.length === 0) || (!_.isObject(obj))) {
        return false;
      } else if (keys.length === 1) {
        return _.first(keys) in obj;
      } else {
        // recurse remaining list
        return callee(obj[_.first(keys)], _.rest(keys));
      }
    };

    return callee(obj, _.isArray(keys) ? keys : keys.split('.'));
  }
});

/* Just like _.extend but will _.union arrays instead of overriding them.
 * Example input:
 *
 *  var obj1 = { someArray: [1,2,3], test1: "TEST1" },
 *      obj2 = { someArray: [4,5,6, test2: "TEST2" };
 *
 *  _.unionExtend(obj1, obj2);
 *
 * Example output:
 *
 *  { someArray: [1,2,3,4,5,6], test1: "TEST1", test2: "TEST2" }
*/
_.mixin({
  unionExtend: function(obj) {
    var slice = Array.prototype.slice;

    _.each(slice.call(arguments, 1), function(source) {
      for(var prop in source) {
        if(_.isArray(obj[prop])) {
          obj[prop] = _.union(obj[prop], source[prop]);
        } else {
          obj[prop] = source[prop];
        }
      }
    });

    return obj;
  }
});

/* Takes an array of objects and returns a single object
 * Example input:
 *
 * var array = [{ one: 1 }, { two: 2 }, { three: 3 }];
 *
 * _.zipObjects(array);
 *
 * Example output:
 *
 * { one: 1, two: 2, three: 3 }
 *
 */

_.mixin({
  zipObjects: function(array) {
    return _.extend.apply(null, array);
  }
});

//custom jquery validation code
/*$.validator.addMethod('fileExtensionList', function(value, element) {
        return this.optional(element) || /^\s*(\.[a-z0-9]+,\s*)*(\.[a-z0-9]+\s?)$/.test(value)
    },
    "File types must be a comma separated list of file extensions"
)*/

$.fn.exists = function () {
  return this.length !== 0;
};

$.fn.hasScrollBar = function() {
  return this.get(0).scrollHeight > this.height();
};

export default {
  dig() {
    return this.objFromString(...arguments)
  },

  /**
  * Retrieve nested item from object/array
  * @param {Object|Array} object
  * @param {String} stringPath dot separated
  * @param {*} defaultValue default return if no result found
  * @returns {*}
  */

  //
  // http://stackoverflow.com/questions/6491463/accessing-nested-javascript-objects-with-string-key
  //
  objFromString (object, stringPath, defaultValue) {
    const seekingPath = stringPath.split('.')

    let diggingObj = object

    for (let i = 0; i < seekingPath.length; i++) {
      if (typeof diggingObj !== 'object' || diggingObj == null) {
                                            //
                                            // double-equals is useful:
                                            // null == undefined #=> true
                                            // null === undefined #=> false
                                            // also, typeof null #=> 'object'
                                            //
        return defaultValue;
      } else {
        diggingObj = diggingObj[seekingPath[i]];
      }
    }

    if (diggingObj == null || diggingObj === '') {
        //
        // double-equals is useful:
        // null == undefined # => true
        //
      return defaultValue;
    } else {
      return diggingObj;
    }
  },

  addDaysToDate: function(date, days) {
    var res = new Date(date);
    res.setDate(date.getDate() + days);
    return res;
  },

  formatDate: function(date, formatStr) {
    formatStr = formatStr || Kadenze.I18n.t('date.formats.js_default');
    return moment(date).format(formatStr);
  },

  formatTime: function(date, formatStr) {
    formatStr = formatStr || Kadenze.I18n.t('date.formats.js_default_with_zone');
    return moment(date).format(formatStr);
  },

  // from http://css-tricks.com/snippets/javascript/get-url-variables/
  getQueryVariable: function(variable) {
    var query = window.location.search.substring(1);
    var vars = query.split('&');
    for (var i=0; i<vars.length; i++) {
      var pair = vars[i].split('=');
      if (pair[0] == variable) {
        return pair[1];
      }
    }
    return(false);
  },
  getOrdinal: function (n) {
    var s = ['th', 'st', 'nd', 'rd'],
      v = n % 100;
    return n + (s[(v - 20) % 10] || s[v] || s[0]);
  },
  getCourseIdFromPath: function() {
    var arr = window.location.pathname.split(/\//);
    return arr[arr.indexOf('courses') + 1];
  },

  //Removes empty and duplicate values from an array
  cleanupArray: function(array) {
    array = $.grep(array,function(n){ return(n); });
    return $.grep(array, function(el, index) {
      return index == $.inArray(el, array);
    });
  },

  extendClass: function(baseCls, extensions) {
    extensions = _.extend(extensions, {
      parent: baseCls.prototype,
      super: function(method) {
        if(!this.parent[method]) throw 'Your parent class does not implement: ' + method;
        var args = _.compact(_.map(arguments, function(arg) { if(arg !== method) return arg; }));
        this.parent[method].apply(this, args);
      }
    });

    return _.extend({}, Object.create(baseCls.prototype), extensions);
  },

  /*
  Based on:
  - http://tobyho.com/2010/11/22/javascript-constructors-and/
  - http://krasimirtsonev.com/blog/article/object-oriented-programming-oop-in-javascript-extending-Inheritance-classes
  Usage Example:
     > function Mammal(){ ... }
     > Mammal.prototype.breathe = function(){ ...do some breathing... }
     > var Cat = Kadenze.Util.extendObj(Mammal, function() { ... });
     > Cat.breathe();
  */
  extendObj: function (parentObj, childObj) {
    childObj.prototype = new parentObj(); // Cat.prototype = new Mammal()
    childObj.prototype.constructor = childObj; // Cat.prototype.constructor = Cat
    return childObj;
  },

  snakeCase: function(pascalCase) {
    return pascalCase.replace(/([a-z])([A-Z])/g, '$1_$2').toLowerCase();
  },

  camelCaseToString: function(camelCase) {
    return camelCase.split(/(?=[A-Z])/).join(' ');
  },

  median: function(values, skipAvg) {
    values.sort( function(a,b) {return a - b;} );
    var half = Math.floor(values.length/2);
    if (values.length % 2 || skipAvg) {
      return values[half];
    } else {
      return (values[half-1] + values[half]) / 2.0;
    }
  },

  wrap_string: function(string) {
    //return string.replace(/([\d\!\?\.\#\$\%\:\&]+)/g, "<span class='sym'>$1</span>");
    return string.replace(/([^A-z \u00C0-\u00FF]+)/g, '<span class=\'sym\'>$1</span>');
  },

  // helper for handling single request parameters
  getParameterByName: function(name, url) {
    var query_str = url && url.split('?')[1] || (location.search ? location.search.slice(1) : '');
    var query_arr = query_str.split('&');

    var qsObj =  _.chain(query_arr)
      .map(function(item) {
        var p = item.split('=');

        return [p[0], decodeURI(p[1])];
      })
      .object()
      .omit(_.isEmpty)
      .toJSON();

    if(qsObj.hasOwnProperty(name)){
      return qsObj[name];
    } else{
      return null;
    }
  },

  // Map all URL params to an object, accepts url (eg: location.search)
  getURLParams: function(url) {
    return _.chain(url ? url.slice(1).split('&') : '')
      .map(function(item) {
        var p = item.split('=');

        return [p[0], decodeURI(p[1])];
      })
      .object()
      .omit(_.isEmpty)
      .toJSON();
  },

  // modified from http://stackoverflow.com/questions/486896/adding-a-parameter-to-the-url-with-javascript
  insertURLParam: function(url, key, value) {
    key = encodeURI(key);
    value = encodeURI(value);

    var params = url.split('?')[1],
        pairs = [];

    if (params && params.length) {
      pairs = params.split('&');
    }

    var i = pairs.length,
        x;

    while(i--) {
      x = pairs[i].split('=');

      if (x[0] === key) {
        x[1] = value;
        pairs[i] = x.join('=');
        break;
      }
    }

    if (i < 0) {
      pairs[pairs.length] = [key,value].join('=');
    }

    return url.split('?')[0] + '?' + pairs.join('&');
  },

  deleteURLParam: function(url, key) {
    key = encodeURI(key);
    var params = url.split('?')[1];
    var kvp = [];
    if (params && params.length > 0) {
      kvp = params.split('&');
    }
    var i = kvp.length;
    var x;
    while(i--) {
      x = kvp[i].split('=');
      if (x[0] === key) {
        kvp.splice(i, 0);
        break;
      }
    }

    return url.split('?')[0] + '?' + kvp.join('&');
  },

  cleanEditorContent: function(editor) {
    if(!editor.document)
      return;
    // Add attributes to all links
    var links = editor.document.getElementsByTag('a').$;
    $.each(links, function(_idx, _link) {
      $lnk = $(_link);
      $lnk.attr('data-skip-pjax', true);
      $lnk.attr('target', '_blank');
    });
  },

  UUID: function(randomRange) {
    // Very simply 'UUID' approach
    if(typeof randomRange == 'undefined') {
      randomRange = 100;
    }

    return Date.now() + '-' + Math.floor(Math.random()*randomRange);
  },

  generateUUID: function() {
    // RFC4122 version 4 compliant UUID:
    // http://stackoverflow.com/questions/105034/create-guid-uuid-in-javascript/105074#105074
    var d = new Date().getTime();
    if(window.performance && typeof window.performance.now === 'function'){
      d += performance.now(); //use high-precision timer if available
    }
    var uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
      var r = (d + Math.random()*16)%16 | 0;
      d = Math.floor(d/16);
      return (c=='x' ? r : (r&0x3|0x8)).toString(16);
    });
    return uuid;
  },

  offsetRight: function(element) {
    return Math.floor($(window).width() - ($(element).offset().left + $(element).outerWidth()));
  },

  // Will make JS modules visible on screen
  // helpful to determine what JS module
  // is being used
  toggleModuleDebug: function() {
    $('html').toggleClass('kdnze-debug');
  },

  // Params: {country: country}
  fetchLocationData: function(params, callback) {
    $.getJSON(Kadenze.Routes.locations_path(params)).pipe(function () {
      callback.apply(this, arguments);
    });
  },

  /*
  ** Kadenze.Util.getCSRFQueryString
  **
  **  Gets CSRF token and returns a string to be used as a query string
  */

  getCSRFQueryString: function() {
    var CSRFToken = $('meta[name=csrf-token]').prop('content'),
      CSRFParam = $('meta[name=csrf-param]').prop('content'),
      queryString;

    if (CSRFParam && CSRFToken) {
      queryString = queryString || CSRFParam + '=' + encodeURIComponent(CSRFToken);

      return queryString;
    }
  },

  htmlEscape: function(str) {
    return String(str)
      .replace(/&/g, '&amp;')
      .replace(/"/g, '&quot;')
      .replace(/'/g, '&#39;')
      .replace(/</g, '&lt;')
      .replace(/>/g, '&gt;');
  },

  htmlUnescape: function(value){
    return String(value)
      .replace(/&quot;/g, '"')
      .replace(/&#39;/g, '\'')
      .replace(/&lt;/g, '<')
      .replace(/&gt;/g, '>')
      .replace(/&amp;/g, '&');
  },

  convertUndefinedToNull: function(obj, keys, recursive) {
    for (let key in obj) {
      if (keys && keys.length > 0) {
        if (!_.contains(keys, key)) {
          continue;
        }
      }

      if (typeof obj[key] === 'undefined') {
        obj[key] = null;
      }
      else if (recursive && typeof obj[key] == 'object') {
        obj[key] = Kadenze.Util.convertUndefinedToNull(obj[key], keys, recursive);
      }

    }
    return obj;
  },

  toDataURL: function (url, callback) {
    var xhr = new XMLHttpRequest();
    xhr.onload = function() {
      callback(xhr.response);
    };
    xhr.open('GET', url);
    xhr.responseType = 'blob';
    xhr.send();
  },

  dataURItoBlob: function(dataURI) {
    var byteString = atob(dataURI.split(',')[1]);
    var ab = new ArrayBuffer(byteString.length);
    var ia = new Uint8Array(ab);
    for (var i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ab], { type: 'image/jpeg' });
  },

  //
  // http://stackoverflow.com/questions/15900485/correct-way-to-convert-size-in-bytes-to-kb-mb-gb-in-javascript
  //
  bytesToSize: function(bytes) {
    var k = 1000;
    var sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
    if (bytes === 0) return '0 Bytes';
    var i = parseInt(Math.floor(Math.log(bytes) / Math.log(k)),10);
    return (bytes / Math.pow(k, i)).toPrecision(3) + ' ' + sizes[i];
  },

  sortArray: function(unsortedArray, sortProperty, sortDirection) {
    var sortedArray = _.sortBy(unsortedArray,function(obj){
      if(sortProperty) {
        return obj[sortProperty];
      } else {
        return obj;
      }
    });
    if (sortDirection === 'descending') {
      return sortedArray.reverse();
    } else {
      return sortedArray;
    }
  },

  getMomentDateFromObservable: function(observable) {
    return this.getMomentDate(observable());
  },

  getMomentDate: dateString => {
    const date = moment(
      dateString && dateString.replace(/\s\w{3,4}$/, ''),
      'MM/DD/YYYY hh:mm A'
    );

    if (!date.isValid()) {
      return moment(dateString);
    } else {
      return date;
    }
  }
};
