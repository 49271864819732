/*
 * Kadenze.AjaxRequest
 *
 * Initializes an ajax request with defaults specific to Kadenze
 *
 * It is encouraged to use the promise syntax for callbacks:
 *
 *      var request = new Kadenze.AjaxRequest(myParams);
 *
 *      request.done(mySuccess);
 *      request.fail(myError);
 *      request.always(myAlways);
 *      request.then(mySuccess, myError, myProgress); see: http://api.jquery.com/deferred.then/
 *
 * The nested callback syntax is also supported:
 *
 *      new Kadenze.AjaxRequest({
 *            beforeSend: myBeforeSend
 *            success: mySuccess,
 *            error: myError,
 *            complete: myComplete
 *            onTimeout: myTimeout
 *          });
 *
 * Handling timeouts:
 *
 * For convenience you can pass in an onTimeout callback that will be called
 * when the error callback returns a text status of 'timeout'.
 *
 *      new Kadenze.AjaxRequest({
 *            onTimeout: myTimeout
 *          });
 *
 * To Handle timeouts manually Your error / fail function will also get three arguments (xhr, texstatus, message)
 * if textstatus === "timeout" then we can assume the request timed out.
 *
 *      var request = new Kadenze.AjaxRequest(myParams);
 *
 *      request.fail(function(xhr, textstatus, message) {
 *        if(textstatus === "timeout") {
 *          // Do something to the UI
 *        }
 *      });
 *
 * @params: Object
 *  Anything that $.ajax() accepts see: http://api.jquery.com/jquery.ajax/
 *
 * @returns:
 *  jQuery.Deferred() see: http://api.jquery.com/jQuery.Deferred/
*/

export default function(params) {
  var self = this;

  self.userParams = params || {};

  self.defaults = {
    type: 'POST',
    url: '',
    data: {},
    dataType: 'json',
    dataAsJSON: true, // Set to false if passing serialized form data
    contentType: 'application/json', //Why? http://goo.gl/YNwSUD
    headers: { },
    beforeSend: function() {},
    success: function() {},
    error: function() {},
    complete: function() {},
    timeout: 1000 * 29
  };

  self.config = _.extend(self.defaults, self.userParams);

  self.init = function() {
    self.setCSRFToken();
    self.prepData(self.config.data);
    self.setDefaultCallbacks();
  };

  self.beforeSend = function(xhr, settings) {
    if(self.config.loadingIndicator)
      self.showLoader()

    if(self.config.progressBar)
      self.showProgressBar()

    if(typeof self.userParams.beforeSend === 'function')
      self.userParams.beforeSend(xhr, settings)
  }

  self.complete = function(xhr, textStatus) {
    if(self.config.loadingIndicator)
      self.hideLoader()

    if(self.config.progressBar)
      self.hideProgressBar()

    if(typeof self.userParams.complete === 'function')
      self.userParams.complete(xhr, textStatus)
  }

  self.error = function(xhr, textStatus, errorThrown) {
    if(textStatus === 'timeout' && typeof self.userParams.onTimeout === 'function')
      self.userParams.onTimeout(xhr, textStatus, errorThrown)

    if(typeof self.userParams.error === 'function')
      self.userParams.error(xhr, textStatus, errorThrown)
  };

  self.setDefaultCallbacks = function() {
    self.config.beforeSend = self.beforeSend;
    self.config.complete = self.complete;
    self.config.error = self.error;
  };

  self.setCSRFToken = function() {
    var token = $('meta[name=\'csrf-token\']').prop('content');
    self.config.headers['X-CSRF-Token'] = token;
  };

  self.prepData = function(data) {
    if(self.config.dataAsJSON) {
      self.stringifyData(self.config.data);
    } else {
      self.config.contentType = 'application/x-www-form-urlencoded';
      self.config.data = data;
    }
  };

  self.stringifyData = function(data) {
    self.config.data = JSON.stringify(data);
  };

  self.showLoader = function() {
    var opts = self.config.loadingIndicator;

    Kadenze.UIUtil.showLoadingIndicator(
      opts.container,
      opts.cb,
      opts.positionTop,
      opts.message
    );
  };

  self.hideLoader = function() {
    var container = self.config.loadingIndicator.container;
    Kadenze.UIUtil.hideLoadingIndicator(container);
  };

  this.showProgressBar = () => {
    const opts = this.config.progressBar;
    this.mp = new Mprogress(opts);
  };

  self.hideProgressBar = function() {
    self.mp.end();
  };

  self.init();

  return $.ajax(self.config);
}
