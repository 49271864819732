export default {
  FACEBOOK_SDK_URL: '//connect.facebook.net/en_US/all.js',
  FACEBOOK_SHARE_URL: 'https://www.facebook.com/sharer/sharer.php',
  TWITTER_SHARE_URL: 'https://twitter.com/intent/tweet',
  GOOGLE_SHARE_URL: 'https://plus.google.com/share',
  TUMBLR_SHARE_URL: 'http://www.tumblr.com/share/link',
  PINTEREST_SHARE_URL: 'http://pinterest.com/pin/create/button',
  facebookAppID: null,
  facebookShareViaAPI: false,

  init: function(settings) {
    console.log('Initializing share.js');

    if(settings) {
      this.facebookAppID = settings.facebookAppID;
      this.facebookShareViaAPI = settings.facebookShareViaAPI;
    }
    if (!($('#fb-root').length > 0)) {
      $('body').append('<div id=\'fb-root\'></div>');
    }
    this.initFacebook();
    this.initPinterest();
    this.initGooglePlus();
    this.initTumblr();
    return this.initTwitter();
  },
  initFacebook: function() {
    var _this = this;
    if (this.facebookShareViaAPI) {
      if (typeof FB !== 'undefined' && FB !== null) {
        return this.initFacebookAPIInteractions();
      } else {
        return $.getScript(this.FACEBOOK_SDK_URL, function() {
          return window.fbAsyncInit = function() {
            FB.init({
              appId: _this.facebookAppID,
              status: true,
              xfbml: true
            });
            return _this.initFacebookAPIInteractions();
          };
        });
      }
    } else {
      return $(document).off('click.shareFB').on('click.shareFB', '[data-share-facebook]', function(e) {
        var $this, left, link, top, url;
        e.preventDefault();
        $this = $(e.currentTarget);
        //left = (screen.width / 2) - 400.;
        //top = (screen.height / 2) - 200.;
        link = $this.data('share-link');
        url = '' + _this.FACEBOOK_SHARE_URL + '?u=' + link;
        return window.open(url, '_blank');
      //  'width=800,height=400,top=#{top},left=#{left}'
      });
    }
  },
  initFacebookAPIInteractions: function() {
    return $(document).off('click.shareFB').on('click.shareFB', '[data-share-facebook]', function(e) {
      var $this, caption, description, link, name, obj, picture;
      e.preventDefault();
      $this = $(e.currentTarget);
      picture = $this.data('share-picture');
      description = $this.data('share-description');
      link = $this.data('share-link');
      name = $this.data('share-name');
      caption = $this.data('share-caption');
      obj = {
        method: 'feed',
        link: link,
        picture: picture,
        name: name,
        caption: caption,
        description: description
      };
      return FB.ui(obj, function(response) {});
    });
  },
  initTwitter: function() {
    var _this = this;
    return $(document).off('click.shareTW').on('click.shareTW', '[data-share-twitter]', function(e) {
      var $this, left, link, text, top, url;
      e.preventDefault();
      $this = $(e.currentTarget);
      //left = (screen.width / 2) - 400.;
      //top = (screen.height / 2) - 200.;
      text = $this.data('share-description');
      link = $this.data('share-link');
      url = '' + _this.TWITTER_SHARE_URL + '?text=' + text + '&url=' + link;
      return window.open(url, '_blank');
    });
  },
  initGooglePlus: function() {
    var _this = this;
    return $(document).off('click.shareGP').on('click.shareGP', '[data-share-googleplus]', function(e) {
      var $this, left, link, text, top, url;
      e.preventDefault();
      $this = $(e.currentTarget);
      //left = (screen.width / 2) - 400.;
      //top = (screen.height / 2) - 200.;
      link = $this.data('share-link');
      url = '' + _this.GOOGLE_SHARE_URL + '?url=' + link;
      return window.open(url, 'Google+ Share', '_blank');
    });
  },
  initTumblr: function() {
    var _this = this;
    return $(document).off('click.shareTU').on('click.shareTU', '[data-share-tumblr]', function(e) {
      var $this, left, link, text, top, url;
      e.preventDefault();
      $this = $(e.currentTarget);
      //left = (screen.width / 2) - 400.;
      //top = (screen.height / 2) - 200.;
      link = $this.data('share-link');
      url = '' + _this.TUMBLR_SHARE_URL + '?url=' + link;
      return window.open(url, '_blank');
    });
  },
  initPinterest: function() {
    var _this = this;
    return $(document).off('click.sharePN').on('click.sharePN', '[data-share-pinterest]', function(e) {
      var $this, description, left, link, picture, top, url;
      e.preventDefault();
      $this = $(e.currentTarget);
      //left = (screen.width / 2) - 400.;
      //top = (screen.height / 2) - 200.;
      description = $this.data('share-description');
      link = $this.data('share-link');
      picture = $this.data('share-picture');
      url = '' + _this.PINTEREST_SHARE_URL + '?description=' + description + '&url=' + link + '&media=' + picture;
      return window.open(url, '_blank');
    });
  }
};
