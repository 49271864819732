import FullscreenEvent from './fullscreen-event';
import TabEvent from './new-tab-event';
import ModalEvent from './modal-event';

export default function(params) {
  var self = this;

  self.defaults = {
    $iframe: $(params.iframe),
    $actionContainer: $('<p/>', { class: 'mt1' }),
    enableFullscreen: false,
    enableNewTab: false,
    enableModal: false,
    modifierClasses: 'js-iframe-event-decorated',
  };

  self.config = _.extend({}, self.defaults, params);

  self.decorate = function() {
    if(!self.isOnlyChild()) {
      self.wrap();
    } else {
      self.addModifierClasses();
    }

    if(self.config.enableFullscreen) {
      var fullscreenAction = new FullscreenEvent(self.config);
      self.buildAction(fullscreenAction.friendlyName, fullscreenAction.handler);
    }

    if(self.config.enableNewTab) {
      var newTabEvent = new TabEvent(self.config);
      self.buildAction(newTabEvent.friendlyName, newTabEvent.handler);
    }

    if(self.config.enableModal) {
      var modalEvent = new ModalEvent(self.config);
      self.buildAction(modalEvent.friendlyName, modalEvent.handler);
    }

    self.config.$iframe.parent().after(self.config.$actionContainer);

    // returns the iframe and $actioncontainer in one set
    return self.config.$iframe.parent().add(self.config.$iframe.parent().next());
  };

  self.buildAction = function(actionText, callback) {
    var uuid = 'iframe-action-' + Kadenze.Util.UUID(100);
    var $a = $('<a />', { id: uuid, text: actionText, href: 'javascript:;' });
    $a.css({ marginRight: '10px' });
    $a.attr('data-skip-pjax', true);
    $('body').on('click.eventDecorator', '#' + uuid, callback);
    self.config.$actionContainer.append($a);
  };

  self.wrap = function() {
    return self.config.$iframe.wrap(self.wrapper());
  };

  self.wrapper = function() {
    return $('<div/>', { class: self.config.modifierClasses });
  };

  self.addModifierClasses = function() {
    return self.config.$iframe.parent().addClass(self.config.modifierClasses);
  };

  self.isOnlyChild = function() {
    return self.config.$iframe.parent().children().length == 1 ? true : false;
  };

  return self;
}

