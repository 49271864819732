import requireModule from 'src/require-module'

require("src/load-globals");

requireModule("user/registration");
requireModule("user/account_settings");

requireModule('common/top_nav');
requireModule('common/course/public_info');
requireModule('common/course/syllabus');
requireModule('common/course/block_syllabus');
requireModule('common/course/type');

requireModule('common/carousel');

requireModule('common/program/show');
requireModule('common/program/listing');
requireModule('common/course/list');
requireModule('common/course/my_courses');
requireModule('common/course/welcome');
requireModule('common/course/review');
requireModule('common/program/review');

requireModule('kadenze_public/pricing_and_membership');
requireModule('kadenze_public/faqs');
requireModule('kadenze_public/press');
requireModule('kadenze_public/homepage');
requireModule('kadenze_public/partner/show');
requireModule('kadenze_public/prospective_student');
requireModule('kadenze_public/hero/slider');
requireModule('kadenze_public/hero/video');
requireModule('kadenze_public/transition_scene');

requireModule('common/error/show');
requireModule('common/site_alert');

requireModule('common/parallax');

requireModule('user/confirmation_required');
requireModule('user/enrollment_info');

requireModule('common/notification_center');
requireModule('common/search/results');

requireModule('common/placeholder');
