import Constants from '../layout_helpers/constants';
import ContentBlocker from '../layout_helpers/content-blocker';
// THIS IS THE COURSE NAV & ADMIN NAV
var navState = Constants.LEFT_NAV_EXPANDED, navVisible;
var self, $self, $navToggle, $currentSection, $lnMainContent, $footer;
var desktopIconArrow, mobileIcon;

self = {};
self.constants = {
  MOBILE : 'mobile',
  DESKTOP : 'desktop'
};

var navWidthLarge = 195;
var navWidthSmall = 72;
var deviceType;

var initialize = function() {
  //console.log( 'Kadenze.LeftNav Initialized' );
  deviceType = null;
  $self = $('#nav-side-left');
  $lnMainContent = $('#main-container');

  // setup icons
  desktopIconArrow = Snap.select('#leftnav-dsk__arrow');
  mobileIcon = Snap.select('#icon__nav-mobile_toggle');

  checkNavStateCookie();

  if( Kadenze.Layout.getDeviceSize() === Constants.DEVICE_XS ) {
    setMobileState();
  } else {
    setDesktopState();
  }

  // EVENTLISTER FOR TABLET+DESKTOP LEFT NAV TOGGLE IN TOP NAV
  $navToggle = $('#js__left-nav_toggle');
  $navToggle.on('click', function(){
    // CHECK IF MOBILE
    if( Kadenze.Layout.getDeviceSize() === Constants.DEVICE_XS ) {
      mobileToggle();
    } else {
      desktopToggle( true );
    }
  });

  resetListeners();
  openActiveSection();

};

/* STATE FUNCTIONS
 ------------------------------------------*/

var isNavVisible = function() {
  return ( $('#nav-side-left').length > 0 );
};

var checkNavStateCookie = function() {
  var cookieValue = $.cookie('nav_preference');
  if( !cookieValue ) {
    setNavState( navState );
  } else {
    navState = cookieValue;
    if( cookieValue === Constants.LEFT_NAV_EXPANDED ){
      $('body').removeClass('left-nav__isCollapased');
      animateDesktopIconOpen(false);
      animateMobileIconClose(false);
    } else {
      animateDesktopIconClose(false);
      animateMobileIconClose(false);
    }
  }
};

var setNavState = function( state, setCookie ) {
  navState = state;
  if( state === Constants.LEFT_NAV_COLLAPSED ) {
    $('body').addClass('left-nav__isCollapased');
    $('#quick_search_input').attr('placeholder', '');
  } else {
    $('body').removeClass('left-nav__isCollapased');
    $('#quick_search_input').attr('placeholder', Kadenze.I18n.t('admin.quick_find'));
  }

  if( setCookie ) {
    $.cookie('nav_preference', state, { expires: 365, path: '/' });
  }
};

/* SET LISTENERS
 ------------------------------------------*/

var resetListeners = function() {
  // remove all listeners (just in case)
  $('.sidenav__submenu_btn').off('click.leftnav');
  $('.js-sidenav__drop-toggle').off( 'click.leftnav');
  $('.js-sidenav__menu-no-submenu').off('click.leftnav');
  $('.js-sidenav__drop-toggle .sidenav__menu_title').on( 'click.leftnav');
  $('#nav-side-left').off( 'mouseenter.leftnav focusin.leftnav', '.js-sidenav__menu-no-submenu, .js-sidenav__drop-toggle');
  $('.sidenav__submenu_btn').off('focus.leftnav');


  // CLICK EVENT FOR SUBMENU BUTTONS
  $('.sidenav__submenu_btn').on('click.leftnav', function(){
    if( Kadenze.Layout.getDeviceSize() === Constants.DEVICE_XS ) {
      mobileClose(true);
      openActiveSection( $(this) );
    } else {
      openActiveSection( $(this) );
    }
  });

  //// EVENT FOR ROOT LEVEL BUTTON WITH SUBMENUS
  $('.js-sidenav__drop-toggle').on( 'click.leftnav', function (e) {
    // If left nav expanded, toggle submenu
    if( navState === Constants.LEFT_NAV_EXPANDED ) {
      e.preventDefault();
      console.log( '.js-sidenav__drop-toggle click.leftnav' );
      var $menu = $(this).parent();
      toggleSubmenu($menu,false);

    }
  });

  // CLICK EVENT FOR MENU BUTTON WITHOUT SUBNAV
  $('.js-sidenav__menu-no-submenu').on('click.leftnav', function(){
    if( Kadenze.Layout.getDeviceSize() === Constants.DEVICE_XS ) {
      mobileClose(true);
    } else {
      openActiveSection( $(this) );
    }
  });

  // Add MouseEnter and FocusIn events
  $('#nav-side-left').on('mouseenter.leftnav focusin.leftnav', '.js-sidenav__menu-no-submenu, .js-sidenav__drop-toggle', function(e){
    if( navState === Constants.LEFT_NAV_COLLAPSED ) {
      e.preventDefault();
      var $item = $(this).parent();
      if( !$item.hasClass('open') ){
        toggleSubmenu($item);
      }
    }
  });
  // Focus event for submenu button to open menu if menu is closed
  $('.sidenav__submenu_btn').on('focus.leftnav', function(e){
    e.preventDefault();
    var $item = $(this).closest('.js-sidenav__submenu').parent();
    if( !$item.hasClass('open') ){
      toggleSubmenu($item);
    }
  });

};

/* DESKTOP SPECIFIC
------------------------------------------*/

var animateDesktopIconClose = function( isAnimated ) {
  //console.log('arrowLeft', isAnimated );
  var speed = (isAnimated)?500:0;
  var pointsAnime = [24.4,17,39.6,32.1,24.4,47];

  desktopIconArrow && desktopIconArrow.animate( { 'points': pointsAnime }, speed);

};

var animateDesktopIconOpen = function( isAnimated ) {
  //console.log('arrowRight', isAnimated );
  var speed = (isAnimated)?500:0;
  var pointsAnime = [39.6,17,24.4,32.1,39.6,47];

  desktopIconArrow && desktopIconArrow.animate( { 'points': pointsAnime }, speed);

};

var setDesktopState = function( animate ){
  //console.log( "-- setDesktopState --" );
  navVisible = true;
  checkNavStateCookie();
  Kadenze.Layout.restoreMainContentScroll();
  TweenLite.set( $self, { left: 0 } );

  if( navState === Constants.LEFT_NAV_EXPANDED ) {
    desktopOpen( animate );
  } else {
    desktopClose( animate );
  }
};

var desktopToggle = function( animate ) {
  if( navState === Constants.LEFT_NAV_COLLAPSED ) {
    desktopOpen( animate );
  } else {
    desktopClose( animate );
  }
};

var desktopOpen = function( animate ) {
  //console.log("LeftNav.desktopOpen");
  animateDesktopIconOpen( animate );
  setNavState( Constants.LEFT_NAV_EXPANDED, true );
  resetListeners();
  var speed = (animate)? 0.5: 0;

  if( $currentSection ){
    var parentMenu = $currentSection.closest('.sidenav__menu_li');
    TweenLite.delayedCall( speed*0.4, openSubMenu, [parentMenu, animate] );
  }

  var t1 = new TimelineLite();
  t1.to( $self, speed, { width: navWidthLarge } );

  // resets all titles to correct display
  var $menuChevron = $self.find('.sidenav__chevron');
  var $menuTitle = $self.find('.sidenav__menu_title');
  $menuTitle.removeAttr('style').removeClass('sr-only');
  t1.fromTo( $menuTitle, (speed/2), {opacity:0}, {opacity:1 }, '-='+(speed*0.25) );

  if( $menuChevron )
    t1.fromTo( $menuChevron, (speed/2), {opacity:0}, {opacity:1 } , '-='+(speed*0.25) );

  $('#js__left-nav_toggle').attr('aria-expanded', 'true');
};

var desktopClose = function( animate ) {

  animateDesktopIconClose( animate );

  setNavState( Constants.LEFT_NAV_COLLAPSED, true );
  resetListeners();
  closeAllSubMenus();

  var speed = (animate)? 0.5: 0;
  // animate nav and main area
  var t1 = new TimelineLite();
  t1.to( $self, speed, { width: navWidthSmall } );
  // resets all titles to correct display
  var $menuTitle = $self.find('.sidenav__menu_title');
  $menuTitle.addClass('sr-only');
  var $menuChevron = $self.find('.sidenav__chevron');
  $menuChevron.css( { 'opacity': 0 } );

  $('#js__left-nav_toggle').attr('aria-expanded', 'false');
};

/* MOBILE SPECIFIC
------------------------------------------*/

var animateMobileIconOpen = function( isAnimated ) {
  if (!mobileIcon)
    return;

  //console.log('animateMobileIconOpen', isAnimated );
  var line1 = mobileIcon.select('#leftnav-mobile-line1 line');
  var line2 = mobileIcon.select('#leftnav-mobile-line2 line');
  var line3 = mobileIcon.select('#leftnav-mobile-line3 line');

  var speed = (isAnimated)?500:0;

  var myRotation = 0;
  TweenLite.to( $('#icon__nav-mobile_toggle'), speed/1000, {rotation:myRotation});

  line1.animate( { 'x1': 7, 'y1': 32, 'x2': 22.1, 'y2': 16.9 }, speed);
  line2.animate( { 'x1': 7, 'y1': 32, 'x2': 57, 'y2': 32 }, speed);
  line3.animate( { 'x1': 7, 'y1': 32, 'x2': 22.1, 'y2': 47 }, speed);
};

var animateMobileIconClose = function( isAnimated ) {
  if (!mobileIcon)
    return;

  //console.log('animateMobileIconClose', isAnimated );
  var line1 = mobileIcon.select('#leftnav-mobile-line1 line');
  var line2 = mobileIcon.select('#leftnav-mobile-line2 line');
  var line3 = mobileIcon.select('#leftnav-mobile-line3 line');

  var speed = (isAnimated)?500:0;

  var myRotation = -180;
  TweenLite.to( $('#icon__nav-mobile_toggle'), speed/1000, {rotation:myRotation});

  line1.animate( { 'x1': 7, 'y1': 17, 'x2': 57, 'y2': 17 }, speed);
  line2.animate( { 'x1': 7, 'y1': 32, 'x2': 57, 'y2': 32 }, speed);
  line3.animate( { 'x1': 7, 'y1': 47, 'x2': 57, 'y2': 47 }, speed);
};

var setMobileState = function(){
  //console.log( "-- setMobileState --" );
  navVisible = false;
  setNavState( Constants.LEFT_NAV_EXPANDED, false );

  var $menuChevron = $self.find('.sidenav__chevron');
  var $menuTitle = $self.find('.sidenav__menu_title');
  $menuTitle.removeAttr('style');
  TweenLite.set( $menuTitle, {opacity:1 } );

  if( $menuChevron ) {
    TweenLite.set( $menuChevron, {opacity:1 } );
  }
  mobileClose( false );
  resetListeners();
};

var mobileToggle = function() {
  if( navVisible == false ) {
    mobileOpen( true );
    Kadenze.Layout.disableMainContentScroll();
  } else {
    mobileClose( true );
    Kadenze.Layout.restoreMainContentScroll();
  }
};

var mobileOpen = function(isAnimated) {
  animateMobileIconOpen(isAnimated);
  ContentBlocker.show({'opacity':'.6', 'position':'fixed'}, function () { mobileClose(true); });

  navVisible = true;
  var speed = (isAnimated)? 0.5: 0;
  openActiveSection( $currentSection, false);
  $('.top-nav--header').css({'position':'fixed'});

  TweenLite.to( $self, speed, { left: 0, width: navWidthLarge } );
  $('.sidenav__menu_title').removeClass('sr-only');
};

var mobileClose = function( isAnimated ) {
  Kadenze.Layout.restoreMainContentScroll();
  ContentBlocker.hide();

  animateMobileIconClose(isAnimated);

  navVisible = false;
  var speed = (isAnimated)? 0.5: 0;
  openActiveSection( $currentSection, false);
  $('.top-nav--header').css({'position':'fixed'});

  TweenLite.to( $self, speed, {
    left: -navWidthLarge,
    width: navWidthLarge,
    onComplete: function(){
      $('.sidenav__menu_title').addClass('sr-only');
    }
  });
  TweenLite.to( $lnMainContent, speed, { css: { paddingLeft: 0 } } );
};


/* SUB MENU FUNCTIONS
-------------------------------------*/

// TOGGLES SUBMENUS OPEN AND CLOSED
var toggleSubmenu = function( menu, closeOnFocusOut ) {
  var autoClose = (typeof closeOnFocusOut === 'undefined') ? true : closeOnFocusOut;

  var $menu = $( menu );
  $menu.unbind('mouseleave.leftnav');
  $menu.unbind('focusout.leftnav');
  $menu.toggleClass('open');

  if( $menu.hasClass('open') ) {
    if(autoClose) {
      $menu.addClass('autoclose');
    }
    openSubMenu( $menu, true );
  } else {
    closeSubMenu( $menu, ( navState == Constants.LEFT_NAV_EXPANDED ) );
  }
};

var openSubMenu = function( $target, isAnimated ) {
  //console.log("openSubMenu")
  var speed = ( isAnimated )? 0.2: 0,
    delay = 0, $title;

  $('.js-sidenav__drop-toggle .sidenav__menu_title', $target).off( 'click.leftnav');

  $target.addClass('open');

  if( navState == Constants.LEFT_NAV_COLLAPSED ) {
    $title = $target.find('.sidenav__menu_title');
    $title.removeClass('sr-only').css('display','block');

    $target.on('mouseleave.leftnav', function(){
      if( $target.hasClass('open')){
        toggleSubmenu($target);
      }
    });
  }

  if( $target.hasClass('open autoclose')){
    $target.on('focusout.leftnav', function(){
      if( $target.hasClass('open') ) {
        setTimeout(function() {
          var hasFocus = !! ($target.find(':focus').length > 0);
          if (! hasFocus ) {
            toggleSubmenu($target);
          }
        }, 10);
      }
    });
  }

  var $submenu = $target.find('.js-sidenav__submenu');
  $submenu.removeClass('sr-only').css('display','block');

  var t1 = new TimelineLite( {
    onComplete: function() {
      // if you click on the submenu title, goes to the url of the first submenu item in the list
      $('.js-sidenav__drop-toggle .sidenav__menu_title', $target).on( 'click.leftnav', function (e) {
        if (navState === Constants.LEFT_NAV_COLLAPSED) {
          var $parent = $(this).closest('.js-sidenav__menu_item');
          var $child = $('.js-sidenav__submenu_item a', $parent).first();
          openActiveSection($child);
          $child.click();
        }
      });
      resizeMainContentArea();
    }
  });

  if($title) {
    $title.removeClass('sr-only').css('display','block');
    delay = 0.1;
    t1.fromTo( $title, 0.2, { left:42, opacity:0 }, { left:navWidthSmall, opacity:1 } );
  }

  t1.from( $submenu, speed, { height:0, opacity:0 }, '-='+delay );
};

var resizeMainContentArea = function() {
  if( navState == Constants.LEFT_NAV_EXPANDED ) {
    Kadenze.Layout.update();
  }
};

var closeSubMenu = function( $target, isAnimated ) {
  var speed = ( isAnimated )? 0.1: 0;
  var delay = 0;

  var $submenu = $target.find('.js-sidenav__submenu');

  TweenLite.to( $submenu, speed, {
    height:0,
    opacity:0,
    delay:delay,
    onComplete: function(){
      $submenu.css( {'height': 'auto', 'opacity': 1} ).addClass('sr-only');
      resizeMainContentArea();
    }
  });

  if( navState == Constants.LEFT_NAV_COLLAPSED ) {
    $target.find('.sidenav__menu_title').css({ 'opacity':1, 'left': 42 }).addClass('sr-only');
  }

  $target.removeClass('open');
  $target.removeClass('autoclose');
};

var closeAllSubMenus = function() {
  $('.sidenav__menu_li').each( function(){
    closeSubMenu($(this), false);
  });
};

var openActiveSection = function( $btn, isAnimated ){
  if( !$btn ) {
    $btn = $('a[href="' + window.location.pathname + '"]', '#dashboard-menu');
  }

  resetMenusAndStates();

  var parentMenu = $btn.closest('.sidenav__menu_li');
  $currentSection = $btn;

  $currentSection.parent().addClass('active');
  parentMenu.addClass('active');

  if( navState == Constants.LEFT_NAV_EXPANDED ) {
    openSubMenu(parentMenu, isAnimated);
  }
};

var resetMenusAndStates = function() {
  $('.sidenav__menu_li').each( function(){
    $(this).removeClass('active');
  });

  $('.sidenav__submenu_li').each(function(){
    $(this).removeClass('active');
  });

  closeAllSubMenus();
};


/* PUBLIC
 -----------------------------------*/

export default {
  init: initialize,
  deviceChange: function( deviceSize ){
    if( !isNavVisible() || ($self == null) )
      return;

    var newDevice = ( deviceSize === Constants.DEVICE_XS )? self.constants.MOBILE : self.constants.DESKTOP ;
    if( newDevice != deviceType ) {
      deviceType = newDevice;
      if( deviceType == self.constants.MOBILE ) {
        setMobileState();
      } else {
        setDesktopState();
      }
    }
  },
  update : function() {
    if( !isNavVisible() || ($self == null) ) {
      openActiveSection();
    }
  },
  hide: function(isAnimated) {
    if( !isNavVisible() || ($self == null) )
      return;

    if (Kadenze.Layout.getDeviceSize() == Constants.DEVICE_XS) {
      mobileClose(isAnimated);
    }
  },
  resizeHeight: function( ) {
    if( !isNavVisible() || ($self == null) )
      return;
    var trueSelfHeight = $self.height('auto').outerHeight();
    var pageHeight = Kadenze.Layout.maxPageHeight();
    if(pageHeight >  trueSelfHeight ) {
      var lNavPad = parseInt( $self.css('padding-top').replace('px', '') ) + parseInt( $self.css('padding-bottom').replace('px', '') );
      $self.height( pageHeight-lNavPad );
    }
  },
  desktopExpand: function() {
    desktopOpen(true);
  },
  desktopCollapse: function() {
    desktopClose(true);
  }
};
