var getRef = function(){
  console.log('getting reference');

  var $self = $('.content-blocker');

  if( $self.length === 0 ) {
    $self = $( document.createElement('div') );
    $self.addClass('content-blocker');
    $('.bodywrapper').append( $self );
  }

  return $self;
};

var set = function( cssPropObj, onClickFunc ) {
  var $blocker = getRef();
  $blocker.css(cssPropObj);
  $blocker.off('click.blockerClick');

  if(typeof onClickFunc == 'function') {
    $blocker.on('click.blockerClick', onClickFunc );
  }
};

export default {
  show: function(cssPropObj, onClickFunc) {
    var defaults = {
      'display':'block',
      'position':'fixed',
      'left':'0px',
      'top':'0px',
      'right': '0px',
      'bottom': '0px',
      'opacity':'1'
    };
    var mergedObj = _.defaults(cssPropObj, defaults);
    set( mergedObj, onClickFunc );
  },
  hide: function() {
    set({'display':'none' });
  },
  getInstance: function(){
    return getRef();
  }
};

