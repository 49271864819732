export default function($element, $message, opts, callbacks, sortable, curFile) {
  const defaults = Object.assign({}, Kadenze.DropzoneDefaults, {
    url: Kadenze.Routes.attachments_path(),
    addRemoveLinks: false,
    dictRemoveFile: '',
    autoProcessQueue: false,
    maxFiles: 30,
    maxFilesize: 100,
    sortItems: '.tr',
    sortHandle: '.action-btn--move',
    sortNumber: '.in-table__order-num',

    previewTemplate: opts.previewTemplate ||
      I18n.tt(JST['templates/dropzone-generic-media-preview']({
        previewable: opts.previewable,
        playable: opts.playable
      })),

    videoPreviewTemplate: I18n.tt(JST['templates/dropzone-resource-preview']()),

    headers: {
      'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content')
    },

    init() {
      this.on('thumbnail', (file, data) => {
        if (callbacks.thumbnail)
          callbacks.thumbnail(file, data);

        if (this.options.cropOptions && !file.cropped) {
          const fileType = '.' + file.type.split('/')[1];
          const acceptedFilesArray = this.options.acceptedFiles.split(/,\s*/);

          if (acceptedFilesArray.includes(fileType)){
            if(opts.container){
              opts.container.modal('hide')
            }
            Kadenze.DropzoneUtils.showCropper(this, this.options.cropOptions);
          }
        }
      });

      if (callbacks.sending)
        this.on('sending',
          (file, xhr, formData) => callbacks.sending(file, xhr, formData));

      if (callbacks.sendingmultiple)
        this.on('sendingmultiple',
          (file, xhr, formData) => callbacks.sendingmultiple(file, xhr, formData));

      this.on('success', (file, response) => {
        $(file.previewTemplate).find('.dz-progress').hide();

        if (callbacks.success)
          callbacks.success(file, response);

        if (opts.previewable && file.type.match(/image/i))
          Kadenze.DropzoneUtils.initImagePreview(this, file, response.files[0].url, opts.dzName);

        if (opts.playable && file.type.match(/audio/i))
          Kadenze.DropzoneUtils.initAudioPreview(this, file, response.files[0], opts.dzName);

        if (sortable) {
          $(dzOptions.previewsContainer).sortable({
            items: dzOptions.sortItems,
            handle: dzOptions.sortHandle,

            helper: (e, ui) => {
              ui.children().each((index, el) => $(el).width($(el).width()))
              return ui;
            },

            update: (e, ui) => {
              updateOrderNumber($(e));

              if (callbacks.sortableUpdate)
                callbacks.sortableUpdate(e, ui);
            },
          });
        }
      });

      if (callbacks.successmultiple)
        this.on('successmultiple',
          (file, response) => callbacks.successmultiple(file, response));

      if (callbacks.uploadprogress)
        this.on('uploadprogress',
          (file, progress, bytesSent) => callbacks.uploadprogress(file, progress, bytesSent));

      if (callbacks.totaluploadprogress)
        this.on('totaluploadprogress',
          progress => callbacks.totaluploadprogress(progress));

      if (callbacks.error)
        this.on('error', callbacks.error)

      this.on('complete', file => {
        $(file.previewTemplate).find('.dz-progress').hide();

        if (callbacks.complete)
          callbacks.complete(file);

        $('.js-dz-show-on-complete').show();
      });

      if (callbacks.completemultiple)
        this.on('completemultiple', file => callbacks.completemultiple(file));

      this.on('canceled', file => {
        $('.dz-img__progress_holder', $el).hide();

        if (callbacks.canceled)
          callbacks.canceled(file);
      });

      if (callbacks.drop)
        this.on('drop', file => callbacks.drop(file));

      if (callbacks.maxfilesexceeded)
        this.on('maxfilesexceeded', file => callbacks.maxfilesexceeded(file));

      this.on('addedfile', (file, data) => {
        setupPreviewState(file);

        if (sortable)
          updateOrderNumber($(dzOptions.previewsContainer));

        if (callbacks.addedfile)
          callbacks.addedfile(file);
      });

      this.on('removedfile', file => {
        if (sortable)
          updateOrderNumber($(dzOptions.previewsContainer));

        if (callbacks.removedfile)
          callbacks.removedfile(file);
      });
    }
  });

  const $el = $($element);

  $el.addClass('dz-unit');
  $el.append($message);

  const id = $el.attr('id');

  const dzOptions = Object.assign({}, defaults, opts);

  this.dropzone = new Dropzone(`#${id}`, dzOptions);

  const updateOrderNumber = $container => {
    $container.find(dzOptions.sortItems).each(el => {
      $(el).find(dzOptions.sortNumber).text($(el).index() + 1);
    });
  };

  const setupPreviewState = file => {
    $(file.previewTemplate).addClass('dz-preview-type--' + file.type.split('/')[0]);
  };

  if (curFile && curFile.path) {
    const fileObj = {
      name: curFile.name || '',
      size: curFile.size || 0,
      title: curFile.title || 0,
      status: 'success',
      type: curFile.type || ''
    };

    let thumbPath;

    if(curFile.type === 'image'){
      thumbPath = curFile.path;
    } else {
      thumbPath = '';
    }

    this.dropzone.emit('addedfile', fileObj);
    this.dropzone.emit('thumbnail', fileObj, thumbPath);
    this.dropzone.emit('complete', fileObj);

    this.dropzone.files.push(fileObj);
  }

  return this.dropzone;
}
